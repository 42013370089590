import { limit, redirectPage } from '../../shared/utils';
import Vue from 'vue/dist/vue.esm';

import TurbolinksAdapter from 'vue-turbolinks';

Vue.use(TurbolinksAdapter);

export const initShowCashAccountingHistory = (cashAccountingHistoryId) => {
  if (!document.getElementById('show-cash-accounting-history')) return;

  const app = new Vue({
    el: '#show-cash-accounting-history',
    data: {
      search: '',
      subscriptions: [],
      subscriptionsResume: {},
      currentPage: 1,
      totalCount: 0,
      totalFilteredCount: 0,
      totalPages: 0,
      perPage: 20,
      pages: [],
      searchDebounceTimer: null,
      loading: false,
    },
    methods: {
      limit,
      redirectPage,

      init() {
        this.loadSubscriptions();
        this.setDefaultSubscriptionsResumeValues();
      },

      loadSubscriptions() {
        this.loading = true;
        this.subscriptions = [];

        const params = {
          local_id: localId,
          page: this.current_page,
          per_page: this.per_page,
          history_id: cashAccountingHistoryId,
          search: this.search,
        }

        const api = Routes.sales_dashboard_list_subscriptions_to_cash_accounting_history_json_path(params)
        fetch(api, { cache: 'no-cache' })
          .then(response => response.json())
          .then(data => {
            this.subscriptions = (data.subscriptions || []).map(this.formatSubscription);
            this.subscriptionsResume = this.subscriptions.reduce(this.formatResumeValues, this.setDefaultSubscriptionsResumeValues());
            this.setPaginationParams(data);
            this.loading = false;
          })
          .catch(error => {
            this.loading = false;
            this.setDefaultSubscriptionsResumeValues();
          });
      },

      formatResumeValues(accumulator, current) {
        accumulator.totalDependants += current.dependantsCount
        accumulator.totalSubs += 1;
        accumulator.totalValue = (Number(accumulator.totalValue || 0) + Number(current.value)).toFixed(2);

        switch (current.paymentMethod) {
          case 'credit_card':
            accumulator.totalCredit = (Number(accumulator.totalCredit || 0) + Number(current.value)).toFixed(2);
            break;
          case 'debit_card':
            accumulator.totalDebit = (Number(accumulator.totalDebit || 0) + Number(current.value)).toFixed(2);
            break;
          case 'cash':
            accumulator.totalCash = (Number(accumulator.totalCash || 0) + Number(current.value)).toFixed(2);
            break;
        }

        return accumulator;
      },

      setPaginationParams(data) {
        this.currentPage = data.page;
        this.totalPount = data.total_count;
        this.totalFilteredCount = data.total_filtered_count;
        this.totalPages = data.total_pages;
        this.perPage = data.per_page;
        this.pages = this.mountArrayPages(this.total_pages);
      },

      formatSubscription(sub) {
        const href = Routes.sales_dashboard_customer_path(sub.user.id);

        return {
          id: sub.id,
          dependantsCount: sub.dependants_count,
          sellerName: sub.seller.name,
          userName: sub.user.name,
          paymentMethodTranslated: this.translatePaymentMethod(sub.payment_method),
          paymentMethod: sub.payment_method,
          value: sub.plan_value_by_legacy_plan_type,
          href
        }
      },

      translatePaymentMethod(paymentMethod) {
        let method = 'N/A';

        switch (paymentMethod) {
          case 'credit_card':
            method = "Crédito";
            break;
          case 'cash':
            method = "Dinheiro";
            break;
          case 'debit_card':
            method = "Débito";
            break;
          case 'sms':
            method = "SMS";
            break;
        }

        return method;
      },

      onSearch() {
        this.loading = true;
        this.currentPage = 1;
        this.subscriptions = [];

        clearTimeout(this.searchDebounceTimer);
        this.searchDebounceTimer = setTimeout(() => {
          this.searchDebounceTimer = null;
          this.loadSubscriptions();
        }, 1000);
      },

      mountArrayPages(totalPage) {
        const pages = [];
        for (let i = 1; i <= totalPage; i++) {
          pages.push(i);
        }
        return pages;
      },

      showPage(page) {
        this.current_page = page;
        this.loadSubscriptions();
      },

      setDefaultSubscriptionsResumeValues() {
        this.subscriptionsResume = {
          totalDebit: 0.0,
          totalCredit: 0.0,
          totalCash: 0.0,
          totalValue: 0.0,
          totalDependants: 0,
          totalSubs: 0,
        }

        return this.subscriptionsResume;
      },

      openModal() {
        document.getElementById('confirm-report-modal').style.display = "flex";

        const modalElement = document.getElementById('confirm-report-modal');
        window.addEventListener('click', event => {
          if (event.target == modalElement) {
            this.closeModal();
          }
        });
      },

      closeModal() {
        document.getElementById('confirm-report-modal').style.display = "none";
      }
    }
  });

  app.init();
}