import { redirectPage, limit, formatDate } from '../../shared/utils';
import Vue from 'vue/dist/vue.esm';

import TurbolinksAdapter from 'vue-turbolinks';

Vue.use(TurbolinksAdapter);

export const initCashAccountingHistoryList = (localId) => {
  if (!document.getElementById('cash-accounting-history-list')) return;

  const app = new Vue({
    el: '#cash-accounting-history-list',
    data: {
      search: '',
      histories: [], 
      current_page: 1,
      total_count: 0,
      total_filtered_count: 0,
      total_pages: 0,
      per_page: 20,
      pages: [],
    },
    methods: {
      limit,
      redirectPage,

      init() {
        this.loadCashAccountingHistories();
      },

      loadCashAccountingHistories() {
        const params = {
          local_id: localId,
          page: this.current_page,
          per_page: this.per_page,
        }

        const api = Routes.sales_dashboard_list_cash_accounting_history_json_path(params);
        fetch(api, { cache: 'no-cache' })
          .then(response => response.json())
          .then(data => {
            this.histories = (data.cash_accounting_histories || []).map(this.formatCashAccounting);
            this.current_page = data.page
            this.total_count = data.total_count
            this.total_filtered_count = data.total_filtered_count
            this.total_pages = data.total_pages
            this.per_page = data.per_page
            this.pages = this.mountArrayPages(this.total_pages)
          });
      },

      formatCashAccounting(cashAccounting) {
        const href = Routes.sales_dashboard_cash_accounting_history_path(cashAccounting.id);

        return {
          id: cashAccounting.id,
          referenceDate: formatDate(cashAccounting.reference_date),
          localName: cashAccounting.local.name,
          totalUsers: cashAccounting.subscriptions_count,
          totalDependants: cashAccounting.dependants_count,
          totalValue: cashAccounting.total_value,
          totalCash: cashAccounting.total_cash,
          totalDebit: cashAccounting.total_debit,
          totalCredit: cashAccounting.total_credit,
          href
        }
      },

      showPage(page) {
        this.current_page = page
        this.loadCashAccountingHistories()
      },

      mountArrayPages(totalPage) {
        const pages = []
        for (let i = 1; i <= totalPage; i++) {
          pages.push(i)
        }
        return pages
      }
    }
  });

  app.init();
}