export const initFileDropArea = () => {
  var fileInput = $(".file-input");
  var droparea = $(".file-drop-area");
  var finishButton = $("#finishRegistration");
  var contractForm = $("#contractForm");

  fileInput.on("dragenter focus click", function () {
    droparea.addClass("is-active");
  });

  fileInput.on("dragleave blur drop", function () {
    droparea.removeClass("is-active");
  });

  fileInput.on("change", function () {
    var textContainer = $(".file-msg");
    var fileName = $(this).val().split("\\").pop() || 'ou arraste aqui';
    textContainer.html(fileName);
  });

  finishButton.on('click', function (e) {
    e.preventDefault()
    contractForm.trigger('submit')
  })
};
