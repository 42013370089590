import { redirectPage } from '../../shared/utils'
import Vue from 'vue/dist/vue.esm'

const initSellerGoalGroupsTable = () => {
  if (!document.getElementById('seller-goal-groups-list-sales-dashboard')) return

  const app = new Vue({
    el: '#seller-goal-groups-list-sales-dashboard',
    data: {
      goalGroups: [],
      search: '',
      loading: false,
      infoMessage: '',
      goalGroupPath: Routes.sales_dashboard_seller_goal_group_path
    },
    methods: {
      loadGoalGroups() {
        this.loading = true
        fetch(
          Routes.sales_dashboard_goal_groups_json_path(),
          { cache: 'no-cache' }
        )
        .then(response => response.json())
        .then(data => {
          this.loading = false,
          this.infoMessage = ''
          this.goalGroups = data.goal_groups.map(this.formatGoalGroup)
    
          if (!this.goalGroups.length) {
            this.infoMessage = 'Nenhuma meta encontrada.'
          }
        })
        .catch(error => {
          this.infoMessage =
            'Erro ao listar as metas. Por favor tente novamente!'
        })
      },

      redirectPage,

      translatedGroupType(groupType) {
        switch (groupType) {
          case 'call_center':
            return 'Call center'
          case 'local':
            return 'Unidade'
          case 'regional':
            return 'Regional'
          case 'manager':
            return 'Gerente'
          default:
            return groupType
        }
      },

      formatGoalGroup(goalGroup) {
        const href = this.goalGroupPath(goalGroup.id)
        
        return {
          id: goalGroup.id,
          groupType: this.translatedGroupType(goalGroup.group_type),
          defaultExpectedUsersCount: goalGroup.default_expected_users_count,
          defaultExpectedDependantsCount: goalGroup.default_expected_dependants_count,
          href
        }
      },
    }
  })

  app.loadGoalGroups()
}

window.pages = window.pages || {}
window.pages.sales_dashboard = window.pages.sales_dashboard || {}
window.pages.sales_dashboard.sellerGoalGroups = {
  initSellerGoalGroupsTable
}
