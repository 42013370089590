import {
  formMasks,
  maskPhones,
  formatDateTime,
  initZipCodeAutocomplete,
  setToUpperCaseByElementId,
  mountArrayPages
} from '../shared/utils';
import Vue from 'vue/dist/vue.esm'

const initSearch = () => {
  $('#tableSearch').on('keyup', function() {
    var value = $(this)
      .val()
      .toLowerCase()
      .normalize('NFKD')
      .replace(/[^\w]/g, '')

      $('#indexTable tbody.searchable').filter(function() {
      const searchableLine = $(this).children().first();

      $(this).toggle(
        searchableLine
          .text()
          .toLowerCase()
          .normalize('NFKD')
          .replace(/[^\w]/g, '')
          .indexOf(value) > -1
      )
    })
  })
}

const initPartnersSearch = () => {
  if (!document.getElementById('init-partners-search')) return;

  const app = new Vue({
    el: '#init-partners-search',
    data: {
      partners: [],
      search: '',
      current_page: 1,
      total_count: 0,
      total_pages: 0,
      pages: [],
      msgInfoListPartners: '',
      msgInfoListPartnerBranches: '',
      debounceTimer: null,
    },
    computed: { },
    methods: {
      loadPartners() {
        fetch(`/administrativo/partners/json?page=${this.current_page}&search=${this.search}`)
          .then(response => response.json())
          .then(data => {
            this.msgInfoListPartners = ''
            const partners = data.partners.map((partner) => ({
              ...partner,
              isOpen: false,
              partner_branches: []
            }));
            this.partners = partners;
            this.current_page = data.page;
            this.total_count = data.total_count;
            this.total_pages = data.total_pages;
            this.pages = mountArrayPages(this.total_pages);

            if (!this.partners.length) {
              this.msgInfoListPartners = 'Nenhum parceiro encontrado.';
            }
          }).catch(_error => {
            this.msgInfoListPartners = 'Erro ao procurar os parceiros. Por favor tente novamente!';
          });
      },

      loadPartnerBranches(partner_id) {
        this.msgInfoListPartnerBranches = '';
        fetch(`/administrativo/partners/${partner_id}/partner_branches/json`)
          .then(response => response.json())
          .then(data => {
            const mappedPartners = this.partners.map((partner) => {
              if (partner.id !== partner_id) return partner;

              return {
                ...partner,
                partner_branches: data.partner_branches
              };
            });

            this.partners = mappedPartners;

            if (!data.partner_branches.length) {
              this.msgInfoListPartnerBranches = 'Nenhuma filial encontrada para o parceiro.';
            }
          }).catch(_error => {
            this.msgInfoListPartnerBranches = 'Erro ao procurar as filiais. Por favor tente novamente!';
          });
      },

      openPartnerBranches(partner) {
        partner.isOpen = !partner.isOpen;

        if (partner.isOpen && !partner.partner_branches.length) {
          this.loadPartnerBranches(partner.id);
        }
      },

      showPage(page) {
        this.current_page = page;
        this.loadPartners();
      },

      onSearch() {
        this.msgInfoListPartners = 'Carregando...'
        this.current_page = 1;
        this.partners = [];

        clearTimeout(this.debounceTimer)
        this.debounceTimer = setTimeout(() => {
          this.debounceTimer = null
          this.loadPartners();
        }, 1000)
      },
    }
  });

  app.loadPartners();
}

const initPartnerBranchesSearch = (partnerId) => {
  if (!document.getElementById('init-partner-branches-search')) return;

  const app = new Vue({
    el: '#init-partner-branches-search',
    data: {
      partnerId: partnerId,
      partnerBranches: [],
      search: '',
      current_page: 1,
      total_count: 0,
      total_pages: 0,
      pages: [],
      msgInfoListPartnerBranches: '',
      debounceTimer: null,
    },
    computed: { },
    methods: {
      loadPartnerBranches() {
        this.msgInfoListPartnerBranches = '';
        fetch(`/administrativo/partners/${this.partnerId}/partner_branches_json?page=${this.current_page}&search=${this.search}`)
          .then(response => response.json())
          .then(data => {
            this.partnerBranches = data.partner_branches;
            this.current_page = data.page;
            this.total_count = data.total_count;
            this.total_pages = data.total_pages;
            this.pages = mountArrayPages(this.total_pages);
            if (!data.partner_branches.length) {
              this.msgInfoListPartnerBranches = 'Nenhuma filial encontrada para o parceiro.';
            }
          }).catch(_error => {
            this.msgInfoListPartnerBranches = 'Erro ao procurar as filiais. Por favor tente novamente!';
          });
      },

      showPage(page) {
        this.current_page = page;
        this.loadPartnerBranches();
      },

      onSearch() {
        this.msgInfoListPartnerBranches = 'Carregando...'
        this.current_page = 1;
        this.partnerBranches = [];

        clearTimeout(this.debounceTimer)
        this.debounceTimer = setTimeout(() => {
          this.debounceTimer = null
          this.loadPartnerBranches();
        }, 1000)
      },
    }
  });

  app.loadPartnerBranches();
}

const initListPartnersBatch = () => {
  if (!document.getElementById('init-list-partners-batch')) return;

  const app = new Vue({
    el: '#init-list-partners-batch',
    data: {
      notifications: [],
      showPath: Routes.admin_dashboard_show_notification_path
    },
    computed: {},
    methods: {
      init() {
        this.loadNotifications();
        setInterval(this.loadNotifications, 60000);
      },

      loadNotifications() {
        fetch(Routes.partners_imports_json_path())
          .then(response => response.json())
          .then(data => {
            this.notifications = data.notifications.map(this.formatNotification);
          });
      },

      formatStatus(status) {
        switch (status.toLowerCase()) {
          case 'success':
            return 'Sucesso';
          case 'error':
            return 'Com erros';
          case 'notice':
            return 'Atenção';
          case 'warning':
            return 'Atenção';
          case 'processing':
            return 'Processando';
          default:
            return status;
        }
      },

      formatNotification(notification) {
        const { admin } = notification;

        return {
          id: notification.id,
          adminName: admin.name,
          status: notification.status,
          statusFormated: this.formatStatus(notification.status),
          description: notification.description,
          createdAtFormated: formatDateTime(notification.created_at),
          createdAt: notification.created_at,
        };
      },
    }
  });

  app.init();
}

window.pages = window.pages || {}
window.pages.partners = {
  initSearch,
  formMasks,
  maskPhones,
  initZipCodeAutocomplete,
  initPartnersSearch,
  setToUpperCaseByElementId,
  initListPartnersBatch,
  initPartnerBranchesSearch
}
