/*
File generated by js-routes 1.4.9
Based on Rails 6.0.3.2 routes of CartoesAlianca::Application
 */

(function() {
  var DeprecatedGlobbingBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, UriEncoderSegmentRegex, Utils, result, root,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  root = typeof exports !== "undefined" && exports !== null ? exports : this;

  ParameterMissing = function(message, fileName, lineNumber) {
    var instance;
    instance = new Error(message, fileName, lineNumber);
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    } else {
      instance.__proto__ = this.__proto__;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ParameterMissing);
    }
    return instance;
  };

  ParameterMissing.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(ParameterMissing, Error);
  } else {
    ParameterMissing.__proto__ = Error;
  }

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  DeprecatedGlobbingBehavior = false;

  SpecialOptionsKey = "_options";

  UriEncoderSegmentRegex = /[^a-zA-Z0-9\-\._~!\$&'\(\)\*\+,;=:@]/g;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: {},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          delete parameters[left];
          if (value != null) {
            return this.encode_segment(this.path_identifier(value));
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    encode_segment: function(segment) {
      return segment.replace(UriEncoderSegmentRegex, function(str) {
        return encodeURIComponent(str);
      });
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      value = parameters[left];
      delete parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      value = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      if (DeprecatedGlobbingBehavior) {
        return this.path_identifier(value);
      } else {
        return encodeURI(this.path_identifier(value));
      }
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (root.jQuery && (root.jQuery.type != null)) {
        return root.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace ? namespace.split(".") : [];
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// acceptance_term => /termo-de-aceite(.:format)
  // function(options)
  acceptance_term_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"termo-de-aceite",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// admin_dashboard => /administrativo(.:format)
  // function(options)
  admin_dashboard_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// admin_dashboard_admin => /administrativo/admins/:id(.:format)
  // function(id, options)
  admin_dashboard_admin_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"admins",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_dashboard_admin_reactivate => /administrativo/admins/:admin_id/reativar(.:format)
  // function(admin_id, options)
  admin_dashboard_admin_reactivate_path: Utils.route([["admin_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"admins",false],[2,[7,"/",false],[2,[3,"admin_id",false],[2,[7,"/",false],[2,[6,"reativar",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_dashboard_admins => /administrativo/admins(.:format)
  // function(options)
  admin_dashboard_admins_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"admins",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_dashboard_change_subscriber => /administrativo/alteracao-assinante(.:format)
  // function(options)
  admin_dashboard_change_subscriber_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"alteracao-assinante",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_dashboard_change_subscriber_to_dependent => /administrativo/alteracao-assinante-para-dependente(.:format)
  // function(options)
  admin_dashboard_change_subscriber_to_dependent_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"alteracao-assinante-para-dependente",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_dashboard_custom_image => /administrativo/custom_images/:id(.:format)
  // function(id, options)
  admin_dashboard_custom_image_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"custom_images",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_dashboard_custom_images => /administrativo/custom_images(.:format)
  // function(options)
  admin_dashboard_custom_images_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"custom_images",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_dashboard_export_dependant => /administrativo/export-dependant/:id(.:format)
  // function(id, options)
  admin_dashboard_export_dependant_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"export-dependant",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_dashboard_export_user => /administrativo/export-user/:id(.:format)
  // function(id, options)
  admin_dashboard_export_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"export-user",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_dashboard_leads => /administrativo/leads(.:format)
  // function(options)
  admin_dashboard_leads_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"leads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_dashboard_lgpd_report => /administrativo/relatorio-lgpd(.:format)
  // function(options)
  admin_dashboard_lgpd_report_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"relatorio-lgpd",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_dashboard_lgpd_report_users_json => /administrativo/lgpd-users-json(.:format)
  // function(options)
  admin_dashboard_lgpd_report_users_json_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"lgpd-users-json",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_dashboard_pending_integrations => /administrativo/integracoes-pendentes(.:format)
  // function(options)
  admin_dashboard_pending_integrations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"integracoes-pendentes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_dashboard_pending_protheus => /administrativo/pendente-protheus(.:format)
  // function(options)
  admin_dashboard_pending_protheus_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"pendente-protheus",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_dashboard_report => /administrativo/reports/:id(.:format)
  // function(id, options)
  admin_dashboard_report_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_dashboard_reports => /administrativo/reports(.:format)
  // function(options)
  admin_dashboard_reports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_dashboard_role => /administrativo/roles/:id(.:format)
  // function(id, options)
  admin_dashboard_role_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"roles",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_dashboard_role_add_admin => /administrativo/roles/:role_id/add_admin/:id(.:format)
  // function(role_id, id, options)
  admin_dashboard_role_add_admin_path: Utils.route([["role_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"roles",false],[2,[7,"/",false],[2,[3,"role_id",false],[2,[7,"/",false],[2,[6,"add_admin",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_dashboard_role_list_admins => /administrativo/roles/:role_id/list_admins(.:format)
  // function(role_id, options)
  admin_dashboard_role_list_admins_path: Utils.route([["role_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"roles",false],[2,[7,"/",false],[2,[3,"role_id",false],[2,[7,"/",false],[2,[6,"list_admins",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_dashboard_role_remove_admin => /administrativo/roles/:role_id/remove_admin/:id(.:format)
  // function(role_id, id, options)
  admin_dashboard_role_remove_admin_path: Utils.route([["role_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"roles",false],[2,[7,"/",false],[2,[3,"role_id",false],[2,[7,"/",false],[2,[6,"remove_admin",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// admin_dashboard_roles => /administrativo/roles(.:format)
  // function(options)
  admin_dashboard_roles_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"roles",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_dashboard_show_dependant => /administrativo/dependant-lgpd/:id(.:format)
  // function(id, options)
  admin_dashboard_show_dependant_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"dependant-lgpd",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_dashboard_show_dependant_modifications => /administrativo/dependant-modifications-lgpd/:id(.:format)
  // function(id, options)
  admin_dashboard_show_dependant_modifications_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"dependant-modifications-lgpd",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_dashboard_show_notification => /administrativo/notificacoes/:id(.:format)
  // function(id, options)
  admin_dashboard_show_notification_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"notificacoes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_dashboard_show_user => /administrativo/user-lgpd/:id(.:format)
  // function(id, options)
  admin_dashboard_show_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"user-lgpd",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_dashboard_show_user_modifications => /administrativo/user-modifications-lgpd/:id(.:format)
  // function(id, options)
  admin_dashboard_show_user_modifications_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"user-modifications-lgpd",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_dashboard_synchronize_customer_integration => /administrativo/sincronizar-integracoes/:id(.:format)
  // function(id, options)
  admin_dashboard_synchronize_customer_integration_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"sincronizar-integracoes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_dashboard_synchronize_sale => /administrativo/sincronizar-venda/:id(.:format)
  // function(id, options)
  admin_dashboard_synchronize_sale_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"sincronizar-venda",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_dashboard_synchronize_user => /administrativo/sincronizar-cadastro/:id(.:format)
  // function(id, options)
  admin_dashboard_synchronize_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"sincronizar-cadastro",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_password => /admins/password(.:format)
  // function(options)
  admin_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admins",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_session => /admins/sign_in(.:format)
  // function(options)
  admin_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admins",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_unlock => /admins/unlock(.:format)
  // function(options)
  admin_unlock_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admins",false],[2,[7,"/",false],[2,[6,"unlock",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_custom_images_login_carousel => /api/custom_images/login_carousel(.:format)
  // function(options)
  api_custom_images_login_carousel_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"custom_images",false],[2,[7,"/",false],[2,[6,"login_carousel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_hr_account => /api/hr-account(.:format)
  // function(options)
  api_hr_account_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"hr-account",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_hr_account_auth => /api/hr-account/auth(.:format)
  // function(options)
  api_hr_account_auth_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"hr-account",false],[2,[7,"/",false],[2,[6,"auth",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_hr_account_companies => /api/hr-account/companies(.:format)
  // function(options)
  api_hr_account_companies_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"hr-account",false],[2,[7,"/",false],[2,[6,"companies",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_hr_account_company_bill => /api/hr-account/companies/:id/bills/:bill_id(.:format)
  // function(id, bill_id, options)
  api_hr_account_company_bill_path: Utils.route([["id",true],["bill_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"hr-account",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"bills",false],[2,[7,"/",false],[2,[3,"bill_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// api_hr_account_company_bills => /api/hr-account/companies/:id/bills(.:format)
  // function(id, options)
  api_hr_account_company_bills_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"hr-account",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"bills",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_hr_account_company_user => /api/hr-account/companies/:id/users/:user_id(.:format)
  // function(id, user_id, options)
  api_hr_account_company_user_path: Utils.route([["id",true],["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"hr-account",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// api_hr_account_company_users => /api/hr-account/companies/:id/users(.:format)
  // function(id, options)
  api_hr_account_company_users_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"hr-account",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_hr_account_company_users_count => /api/hr-account/companies/:id/users/count(.:format)
  // function(id, options)
  api_hr_account_company_users_count_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"hr-account",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"count",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// api_hr_account_contacts => /api/hr-account/companies/:id/contacts(.:format)
  // function(id, options)
  api_hr_account_contacts_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"hr-account",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"contacts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_hr_account_create_contact => /api/hr-account/companies/:id/contacts(.:format)
  // function(id, options)
  api_hr_account_create_contact_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"hr-account",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"contacts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_hr_account_create_user => /api/hr-account/companies/:id/users(.:format)
  // function(id, options)
  api_hr_account_create_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"hr-account",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_hr_account_create_user_address => /api/hr-account/companies/:id/users/:user_id/address(.:format)
  // function(id, user_id, options)
  api_hr_account_create_user_address_path: Utils.route([["id",true],["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"hr-account",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"address",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// api_hr_account_deactivate_users => /api/hr-account/companies/:id/users/:ids(.:format)
  // function(id, ids, options)
  api_hr_account_deactivate_users_path: Utils.route([["id",true],["ids",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"hr-account",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"ids",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// api_hr_account_delete_contact => /api/hr-account/companies/:id/contacts/:contact_id(.:format)
  // function(id, contact_id, options)
  api_hr_account_delete_contact_path: Utils.route([["id",true],["contact_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"hr-account",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"contact_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// api_hr_account_dependant => /api/hr-account/companies/:id/users/:user_id/dependants/:dependant_id(.:format)
  // function(id, user_id, dependant_id, options)
  api_hr_account_dependant_path: Utils.route([["id",true],["user_id",true],["dependant_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"hr-account",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"dependants",false],[2,[7,"/",false],[2,[3,"dependant_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// api_hr_account_download_errors_report => /api/hr-account/companies/:id/notification/:notification_id/errors-report(.:format)
  // function(id, notification_id, options)
  api_hr_account_download_errors_report_path: Utils.route([["id",true],["notification_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"hr-account",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"notification",false],[2,[7,"/",false],[2,[3,"notification_id",false],[2,[7,"/",false],[2,[6,"errors-report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// api_hr_account_download_layout_file => /api/hr-account/companies/download-layout-file(.:format)
  // function(options)
  api_hr_account_download_layout_file_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"hr-account",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[6,"download-layout-file",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_hr_account_download_users_report => /api/hr-account/companies/:id/users-report(.:format)
  // function(id, options)
  api_hr_account_download_users_report_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"hr-account",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"users-report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_hr_account_edit_user => /api/hr-account/companies/:id/users/:user_id(.:format)
  // function(id, user_id, options)
  api_hr_account_edit_user_path: Utils.route([["id",true],["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"hr-account",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// api_hr_account_edit_user_address => /api/hr-account/companies/:id/users/:user_id/address/:address_id(.:format)
  // function(id, user_id, address_id, options)
  api_hr_account_edit_user_address_path: Utils.route([["id",true],["user_id",true],["address_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"hr-account",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"address",false],[2,[7,"/",false],[2,[3,"address_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// api_hr_account_faqs => /api/hr-account/faqs(.:format)
  // function(options)
  api_hr_account_faqs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"hr-account",false],[2,[7,"/",false],[2,[6,"faqs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_hr_account_forgot_password => /api/hr-account/forgot-password(.:format)
  // function(options)
  api_hr_account_forgot_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"hr-account",false],[2,[7,"/",false],[2,[6,"forgot-password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_hr_account_import_notification => /api/hr-account/companies/:id/import-status/:notification_id(.:format)
  // function(id, notification_id, options)
  api_hr_account_import_notification_path: Utils.route([["id",true],["notification_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"hr-account",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"import-status",false],[2,[7,"/",false],[2,[3,"notification_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// api_hr_account_import_users => /api/hr-account/companies/:id/import-users(.:format)
  // function(id, options)
  api_hr_account_import_users_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"hr-account",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"import-users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_hr_account_last_import_notification => /api/hr-account/companies/:id/last-import-notification(.:format)
  // function(id, options)
  api_hr_account_last_import_notification_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"hr-account",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"last-import-notification",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_hr_account_notification => /api/hr-account/companies/:id/notifications/:notification_id(.:format)
  // function(id, notification_id, options)
  api_hr_account_notification_path: Utils.route([["id",true],["notification_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"hr-account",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"notifications",false],[2,[7,"/",false],[2,[3,"notification_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// api_hr_account_notifications => /api/hr-account/companies/:id/notifications(.:format)
  // function(id, options)
  api_hr_account_notifications_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"hr-account",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"notifications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_hr_account_photos => /api/hr-account/photos(.:format)
  // function(options)
  api_hr_account_photos_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"hr-account",false],[2,[7,"/",false],[2,[6,"photos",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_hr_account_refresh_token => /api/hr-account/refresh-token(.:format)
  // function(options)
  api_hr_account_refresh_token_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"hr-account",false],[2,[7,"/",false],[2,[6,"refresh-token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_hr_account_reset_password => /api/hr-account/reset-password(.:format)
  // function(options)
  api_hr_account_reset_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"hr-account",false],[2,[7,"/",false],[2,[6,"reset-password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_hr_account_update_contact => /api/hr-account/companies/:id/contacts/:contact_id(.:format)
  // function(id, contact_id, options)
  api_hr_account_update_contact_path: Utils.route([["id",true],["contact_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"hr-account",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"contacts",false],[2,[7,"/",false],[2,[3,"contact_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// api_hr_account_user_address => /api/hr-account/companies/:id/users/:user_id/address(.:format)
  // function(id, user_id, options)
  api_hr_account_user_address_path: Utils.route([["id",true],["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"hr-account",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"address",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// api_issuing_agencies => /api/issuing_agencies(.:format)
  // function(options)
  api_issuing_agencies_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"issuing_agencies",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_states => /api/states(.:format)
  // function(options)
  api_states_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"states",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_user => /api/users/auth(.:format)
  // function(options)
  api_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_user_create_customer => /api/users/create-customer-on-gateway(.:format)
  // function(options)
  api_user_create_customer_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"create-customer-on-gateway",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_user_create_health_info => /api/users/:id/create-user-health-info(.:format)
  // function(id, options)
  api_user_create_health_info_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"create-user-health-info",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_user_create_payment_profile => /api/users/payment-profiles(.:format)
  // function(options)
  api_user_create_payment_profile_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"payment-profiles",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_user_create_subscription_on_gateway => /api/users/create-subscription-on-gateway(.:format)
  // function(options)
  api_user_create_subscription_on_gateway_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"create-subscription-on-gateway",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_user_dependants_api => /api/users/dependants(.:format)
  // function(options)
  api_user_dependants_api_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"dependants",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_user_dependants_api_activate => /api/users/dependants/:id/active(.:format)
  // function(id, options)
  api_user_dependants_api_activate_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"dependants",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"active",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_user_dependants_api_create_dependant => /api/users/dependants(.:format)
  // function(options)
  api_user_dependants_api_create_dependant_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"dependants",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_user_dependants_api_create_dependant_address => /api/users/dependants/:id/address(.:format)
  // function(id, options)
  api_user_dependants_api_create_dependant_address_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"dependants",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"address",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_user_dependants_api_dependant_address => /api/users/dependants/:id/address(.:format)
  // function(id, options)
  api_user_dependants_api_dependant_address_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"dependants",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"address",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_user_dependants_api_find_dependant => /api/users/dependants/:id(.:format)
  // function(id, options)
  api_user_dependants_api_find_dependant_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"dependants",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_user_dependants_api_inactivate => /api/users/dependants/:id/inactive(.:format)
  // function(id, options)
  api_user_dependants_api_inactivate_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"dependants",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"inactive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_user_dependants_api_update_dependant => /api/users/dependants/:id(.:format)
  // function(id, options)
  api_user_dependants_api_update_dependant_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"dependants",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_user_dependants_api_update_dependant_address => /api/users/dependants/:id/address(.:format)
  // function(id, options)
  api_user_dependants_api_update_dependant_address_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"dependants",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"address",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_user_download_contract => /api/users/download-contract(.:format)
  // function(options)
  api_user_download_contract_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"download-contract",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_user_find_user => /api/users/find-user(.:format)
  // function(options)
  api_user_find_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"find-user",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_user_find_user_by_cpf => /api/users/find-user-by-cpf(.:format)
  // function(options)
  api_user_find_user_by_cpf_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"find-user-by-cpf",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_user_forgot_password => /api/users/auth/forgot-password(.:format)
  // function(options)
  api_user_forgot_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"forgot-password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_user_generate_magic_link_to_telemedicine => /api/users/generate-magic-link-to-telemedicine(.:format)
  // function(options)
  api_user_generate_magic_link_to_telemedicine_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"generate-magic-link-to-telemedicine",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_user_get_health_info => /api/users/:id/get-user-health-info(.:format)
  // function(id, options)
  api_user_get_health_info_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"get-user-health-info",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_user_invoices => /api/users/invoices(.:format)
  // function(options)
  api_user_invoices_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"invoices",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_user_list_plans => /api/users/list-plans(.:format)
  // function(options)
  api_user_list_plans_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"list-plans",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_user_notification => /api/users/notifications/:notification_id(.:format)
  // function(notification_id, options)
  api_user_notification_path: Utils.route([["notification_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"notifications",false],[2,[7,"/",false],[2,[3,"notification_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_user_notifications => /api/users/notifications(.:format)
  // function(options)
  api_user_notifications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"notifications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_user_partner_branches => /api/users/partner_branches(.:format)
  // function(options)
  api_user_partner_branches_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"partner_branches",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_user_partners => /api/users/partners(.:format)
  // function(options)
  api_user_partners_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"partners",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_user_payment_methods => /api/users/payment-methods(.:format)
  // function(options)
  api_user_payment_methods_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"payment-methods",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_user_refresh_token => /api/users/auth/refresh-token(.:format)
  // function(options)
  api_user_refresh_token_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"refresh-token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_user_reset_password => /api/users/auth/reset-password(.:format)
  // function(options)
  api_user_reset_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"reset-password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_user_update_address => /api/users/update-address(.:format)
  // function(options)
  api_user_update_address_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"update-address",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_user_update_data => /api/users/update-data(.:format)
  // function(options)
  api_user_update_data_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"update-data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_user_vouchers => /api/users/vouchers(.:format)
  // function(options)
  api_user_vouchers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"vouchers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// app => /app(.:format)
  // function(options)
  app_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"app",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// campaign => /administrativo/campaigns/:id(.:format)
  // function(id, options)
  campaign_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// campaign_inactive => /administrativo/campaigns/:campaign_id/inactive(.:format)
  // function(campaign_id, options)
  campaign_inactive_path: Utils.route([["campaign_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[3,"campaign_id",false],[2,[7,"/",false],[2,[6,"inactive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// campaigns => /administrativo/campaigns(.:format)
  // function(options)
  campaigns_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"campaigns",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// card_subscription_status => /checkout/status-da-compra(.:format)
  // function(options)
  card_subscription_status_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"checkout",false],[2,[7,"/",false],[2,[6,"status-da-compra",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// choose_plan => /checkout/planos(.:format)
  // function(options)
  choose_plan_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"checkout",false],[2,[7,"/",false],[2,[6,"planos",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// classification => /administrativo/classifications/:id(.:format)
  // function(id, options)
  classification_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"classifications",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// classification_categories => /administrativo/classifications/:classification_id/categories(.:format)
  // function(classification_id, options)
  classification_categories_path: Utils.route([["classification_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"classifications",false],[2,[7,"/",false],[2,[3,"classification_id",false],[2,[7,"/",false],[2,[6,"categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// classification_category => /administrativo/classifications/:classification_id/categories/:id(.:format)
  // function(classification_id, id, options)
  classification_category_path: Utils.route([["classification_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"classifications",false],[2,[7,"/",false],[2,[3,"classification_id",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// classification_category_sub_categories => /administrativo/classifications/:classification_id/categories/:category_id/sub_categories(.:format)
  // function(classification_id, category_id, options)
  classification_category_sub_categories_path: Utils.route([["classification_id",true],["category_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"classifications",false],[2,[7,"/",false],[2,[3,"classification_id",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"category_id",false],[2,[7,"/",false],[2,[6,"sub_categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// classification_category_sub_category => /administrativo/classifications/:classification_id/categories/:category_id/sub_categories/:id(.:format)
  // function(classification_id, category_id, id, options)
  classification_category_sub_category_path: Utils.route([["classification_id",true],["category_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"classifications",false],[2,[7,"/",false],[2,[3,"classification_id",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"category_id",false],[2,[7,"/",false],[2,[6,"sub_categories",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// classifications => /administrativo/classifications(.:format)
  // function(options)
  classifications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"classifications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// companies => /administrativo/companies(.:format)
  // function(options)
  companies_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"companies",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// company => /administrativo/companies/:id(.:format)
  // function(id, options)
  company_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// company_add_hr_account => /administrativo/companies/:company_id/add_hr_account(.:format)
  // function(company_id, options)
  company_add_hr_account_path: Utils.route([["company_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"company_id",false],[2,[7,"/",false],[2,[6,"add_hr_account",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// company_company_bill => /administrativo/companies/:company_id/company_bills/:id(.:format)
  // function(company_id, id, options)
  company_company_bill_path: Utils.route([["company_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"company_id",false],[2,[7,"/",false],[2,[6,"company_bills",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// company_company_bill_cancel => /administrativo/companies/:company_id/company_bills/:company_bill_id/cancel(.:format)
  // function(company_id, company_bill_id, options)
  company_company_bill_cancel_path: Utils.route([["company_id",true],["company_bill_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"company_id",false],[2,[7,"/",false],[2,[6,"company_bills",false],[2,[7,"/",false],[2,[3,"company_bill_id",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// company_company_bill_issue => /administrativo/companies/:company_id/company_bills/:company_bill_id/issue(.:format)
  // function(company_id, company_bill_id, options)
  company_company_bill_issue_path: Utils.route([["company_id",true],["company_bill_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"company_id",false],[2,[7,"/",false],[2,[6,"company_bills",false],[2,[7,"/",false],[2,[3,"company_bill_id",false],[2,[7,"/",false],[2,[6,"issue",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// company_company_bills => /administrativo/companies/:company_id/company_bills(.:format)
  // function(company_id, options)
  company_company_bills_path: Utils.route([["company_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"company_id",false],[2,[7,"/",false],[2,[6,"company_bills",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// company_import_history => /administrativo/companies/:company_id/import-history(.:format)
  // function(company_id, options)
  company_import_history_path: Utils.route([["company_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"company_id",false],[2,[7,"/",false],[2,[6,"import-history",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// company_unlink_hr_account => /administrativo/companies/:company_id/unlink_hr_account(.:format)
  // function(company_id, options)
  company_unlink_hr_account_path: Utils.route([["company_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"company_id",false],[2,[7,"/",false],[2,[6,"unlink_hr_account",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// company_user_activation => /empresarial/primeiro-acesso(.:format)
  // function(options)
  company_user_activation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"empresarial",false],[2,[7,"/",false],[2,[6,"primeiro-acesso",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// company_user_info => /empresarial/primeiro-acesso/confirmar-dados(.:format)
  // function(options)
  company_user_info_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"empresarial",false],[2,[7,"/",false],[2,[6,"primeiro-acesso",false],[2,[7,"/",false],[2,[6,"confirmar-dados",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// confirm_acceptance_term => /termo-de-aceite(.:format)
  // function(options)
  confirm_acceptance_term_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"termo-de-aceite",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// create_customer => /checkout/dados-pessoais(.:format)
  // function(options)
  create_customer_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"checkout",false],[2,[7,"/",false],[2,[6,"dados-pessoais",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// create_customer_address => /checkout/endereco(.:format)
  // function(options)
  create_customer_address_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"checkout",false],[2,[7,"/",false],[2,[6,"endereco",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// create_payment_profile => /checkout/pagamento(.:format)
  // function(options)
  create_payment_profile_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"checkout",false],[2,[7,"/",false],[2,[6,"pagamento",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// dados_pessoais => /checkout/dados-pessoais(.:format)
  // function(options)
  dados_pessoais_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"checkout",false],[2,[7,"/",false],[2,[6,"dados-pessoais",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// delayed_job_web => /delayed_job
  // function(options)
  delayed_job_web_path: Utils.route([], {}, [2,[7,"/",false],[6,"delayed_job",false]]),
// dependant => /administrativo/dependants/:id(.:format)
  // function(id, options)
  dependant_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"dependants",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// dependant_conexa => /area-do-dependente/conexa(.:format)
  // function(options)
  dependant_conexa_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-dependente",false],[2,[7,"/",false],[2,[6,"conexa",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// dependant_create_login => /administrativo/dependants/:dependant_id/criar-login(.:format)
  // function(dependant_id, options)
  dependant_create_login_path: Utils.route([["dependant_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"dependants",false],[2,[7,"/",false],[2,[3,"dependant_id",false],[2,[7,"/",false],[2,[6,"criar-login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// dependant_dashboard => /area-do-dependente(.:format)
  // function(options)
  dependant_dashboard_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-dependente",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// dependant_login_password => /dependant/password(.:format)
  // function(options)
  dependant_login_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dependant",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// dependant_login_session => /dependant/sign_in(.:format)
  // function(options)
  dependant_login_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dependant",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// dependant_login_unlock => /dependant/unlock(.:format)
  // function(options)
  dependant_login_unlock_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dependant",false],[2,[7,"/",false],[2,[6,"unlock",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// dependant_personal_data => /area-do-dependente/dados-pessoais(.:format)
  // function(options)
  dependant_personal_data_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-dependente",false],[2,[7,"/",false],[2,[6,"dados-pessoais",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// dependant_telemedicine => /area-do-dependente/telemedicine(.:format)
  // function(options)
  dependant_telemedicine_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-dependente",false],[2,[7,"/",false],[2,[6,"telemedicine",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// dependants => /administrativo/dependants(.:format)
  // function(options)
  dependants_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"dependants",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// destroy_admin_session => /admins/sign_out(.:format)
  // function(options)
  destroy_admin_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admins",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// destroy_dependant_login_session => /dependant/sign_out(.:format)
  // function(options)
  destroy_dependant_login_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dependant",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// destroy_hr_account_session => /rh/sign_out(.:format)
  // function(options)
  destroy_hr_account_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rh",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// destroy_user_session => /users/sign_out(.:format)
  // function(options)
  destroy_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// edit_admin_dashboard_admin => /administrativo/admins/:id/edit(.:format)
  // function(id, options)
  edit_admin_dashboard_admin_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"admins",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_dashboard_custom_image => /administrativo/custom_images/:id/edit(.:format)
  // function(id, options)
  edit_admin_dashboard_custom_image_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"custom_images",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_dashboard_role => /administrativo/roles/:id/edit(.:format)
  // function(id, options)
  edit_admin_dashboard_role_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"roles",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_password => /admins/password/edit(.:format)
  // function(options)
  edit_admin_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admins",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_classification => /administrativo/classifications/:id/edit(.:format)
  // function(id, options)
  edit_classification_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"classifications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_classification_category => /administrativo/classifications/:classification_id/categories/:id/edit(.:format)
  // function(classification_id, id, options)
  edit_classification_category_path: Utils.route([["classification_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"classifications",false],[2,[7,"/",false],[2,[3,"classification_id",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_classification_category_sub_category => /administrativo/classifications/:classification_id/categories/:category_id/sub_categories/:id/edit(.:format)
  // function(classification_id, category_id, id, options)
  edit_classification_category_sub_category_path: Utils.route([["classification_id",true],["category_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"classifications",false],[2,[7,"/",false],[2,[3,"classification_id",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"category_id",false],[2,[7,"/",false],[2,[6,"sub_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]]]),
// edit_company => /administrativo/companies/:id/edit(.:format)
  // function(id, options)
  edit_company_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_dependant => /administrativo/dependants/:id/edit(.:format)
  // function(id, options)
  edit_dependant_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"dependants",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_dependant_login_password => /dependant/password/edit(.:format)
  // function(options)
  edit_dependant_login_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dependant",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_dependant_password => /area-do-dependente/alterar-senha(.:format)
  // function(options)
  edit_dependant_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-dependente",false],[2,[7,"/",false],[2,[6,"alterar-senha",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// edit_dependant_personal_data => /area-do-dependente/dados-pessoais/editar(.:format)
  // function(options)
  edit_dependant_personal_data_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-dependente",false],[2,[7,"/",false],[2,[6,"dados-pessoais",false],[2,[7,"/",false],[2,[6,"editar",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_faq => /administrativo/faqs/:id/edit(.:format)
  // function(id, options)
  edit_faq_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"faqs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_hr_account => /administrativo/hr_accounts/:id/edit(.:format)
  // function(id, options)
  edit_hr_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"hr_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_hr_account_password => /rh/password/edit(.:format)
  // function(options)
  edit_hr_account_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rh",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_partner => /administrativo/partners/:id/edit(.:format)
  // function(id, options)
  edit_partner_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_partner_partner_branch => /administrativo/partners/:partner_id/partner_branches/:id/edit(.:format)
  // function(partner_id, id, options)
  edit_partner_partner_branch_path: Utils.route([["partner_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[3,"partner_id",false],[2,[7,"/",false],[2,[6,"partner_branches",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
  // function(id, options)
  edit_rails_conductor_inbound_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_sales_dashboard_cash_accounting => /area-do-vendedor/caixas/:id/edit(.:format)
  // function(id, options)
  edit_sales_dashboard_cash_accounting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"caixas",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_sales_dashboard_cash_accounting_history => /area-do-vendedor/historico-de-caixas/:id/edit(.:format)
  // function(id, options)
  edit_sales_dashboard_cash_accounting_history_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"historico-de-caixas",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_sales_dashboard_company => /area-do-vendedor/clientes/pessoa-juridica/:id/edit(.:format)
  // function(id, options)
  edit_sales_dashboard_company_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-juridica",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_sales_dashboard_dependant => /area-do-vendedor/clientes/dependentes/:id/edit(.:format)
  // function(id, options)
  edit_sales_dashboard_dependant_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"dependentes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_sales_dashboard_local => /area-do-vendedor/unidades/:id/edit(.:format)
  // function(id, options)
  edit_sales_dashboard_local_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"unidades",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_sales_dashboard_region => /area-do-vendedor/regionais/:id/edit(.:format)
  // function(id, options)
  edit_sales_dashboard_region_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"regionais",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_sales_dashboard_seller => /area-do-vendedor/vendedores/:id/edit(.:format)
  // function(id, options)
  edit_sales_dashboard_seller_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"vendedores",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_sales_dashboard_seller_goal_group => /area-do-vendedor/grupo-de-metas/:id/edit(.:format)
  // function(id, options)
  edit_sales_dashboard_seller_goal_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"grupo-de-metas",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_subscription => /administrativo/subscriptions/:id/edit(.:format)
  // function(id, options)
  edit_subscription_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"subscriptions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_user => /administrativo/users/:id/edit(.:format)
  // function(id, options)
  edit_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_user_password => /users/password/edit(.:format)
  // function(options)
  edit_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// endereco => /checkout/endereco(.:format)
  // function(options)
  endereco_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"checkout",false],[2,[7,"/",false],[2,[6,"endereco",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// enterprise_payment_webhooks_cancel_debit_note => /enterprise_payment_webhooks/cancel_debit_note(.:format)
  // function(options)
  enterprise_payment_webhooks_cancel_debit_note_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"enterprise_payment_webhooks",false],[2,[7,"/",false],[2,[6,"cancel_debit_note",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// enterprise_payment_webhooks_cancel_invoice => /enterprise_payment_webhooks/cancel_invoice(.:format)
  // function(options)
  enterprise_payment_webhooks_cancel_invoice_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"enterprise_payment_webhooks",false],[2,[7,"/",false],[2,[6,"cancel_invoice",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// enterprise_payment_webhooks_company => /enterprise_payment_webhooks/company(.:format)
  // function(options)
  enterprise_payment_webhooks_company_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"enterprise_payment_webhooks",false],[2,[7,"/",false],[2,[6,"company",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// enterprise_payment_webhooks_debit_note => /enterprise_payment_webhooks/debit_note(.:format)
  // function(options)
  enterprise_payment_webhooks_debit_note_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"enterprise_payment_webhooks",false],[2,[7,"/",false],[2,[6,"debit_note",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// enterprise_payment_webhooks_invoice => /enterprise_payment_webhooks/invoice(.:format)
  // function(options)
  enterprise_payment_webhooks_invoice_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"enterprise_payment_webhooks",false],[2,[7,"/",false],[2,[6,"invoice",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// enterprise_payment_webhooks_notification => /enterprise_payment_webhooks/notification(.:format)
  // function(options)
  enterprise_payment_webhooks_notification_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"enterprise_payment_webhooks",false],[2,[7,"/",false],[2,[6,"notification",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// exam_spots_page => /exames(.:format)
  // function(options)
  exam_spots_page_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"exames",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// fale_conosco => /fale-conosco(.:format)
  // function(options)
  fale_conosco_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"fale-conosco",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// faq => /administrativo/faqs/:id(.:format)
  // function(id, options)
  faq_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"faqs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// faq_page => /duvidas-frequentes(.:format)
  // function(options)
  faq_page_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"duvidas-frequentes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// faqs => /administrativo/faqs(.:format)
  // function(options)
  faqs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"faqs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// get_chosen_plan_session => /checkout/plano-escolhido(.:format)
  // function(options)
  get_chosen_plan_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"checkout",false],[2,[7,"/",false],[2,[6,"plano-escolhido",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// health_check => /health_check(.:format)
  // function(options)
  health_check_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"health_check",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// hr_account => /administrativo/hr_accounts/:id(.:format)
  // function(id, options)
  hr_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"hr_accounts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// hr_account_deactivate => /administrativo/hr_accounts/:hr_account_id/desativar(.:format)
  // function(hr_account_id, options)
  hr_account_deactivate_path: Utils.route([["hr_account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"hr_accounts",false],[2,[7,"/",false],[2,[3,"hr_account_id",false],[2,[7,"/",false],[2,[6,"desativar",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// hr_account_password => /rh/password(.:format)
  // function(options)
  hr_account_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rh",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// hr_account_reactivate => /administrativo/hr_accounts/:hr_account_id/reativar(.:format)
  // function(hr_account_id, options)
  hr_account_reactivate_path: Utils.route([["hr_account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"hr_accounts",false],[2,[7,"/",false],[2,[3,"hr_account_id",false],[2,[7,"/",false],[2,[6,"reativar",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// hr_account_remove_access_from_all_companies => /administrativo/hr_accounts/:hr_account_id/remover-empresas(.:format)
  // function(hr_account_id, options)
  hr_account_remove_access_from_all_companies_path: Utils.route([["hr_account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"hr_accounts",false],[2,[7,"/",false],[2,[3,"hr_account_id",false],[2,[7,"/",false],[2,[6,"remover-empresas",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// hr_account_session => /rh/sign_in(.:format)
  // function(options)
  hr_account_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rh",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// hr_account_unlock => /rh/unlock(.:format)
  // function(options)
  hr_account_unlock_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rh",false],[2,[7,"/",false],[2,[6,"unlock",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// hr_accounts => /administrativo/hr_accounts(.:format)
  // function(options)
  hr_accounts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"hr_accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_admin_dashboard_admin => /administrativo/admins/new(.:format)
  // function(options)
  new_admin_dashboard_admin_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"admins",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_dashboard_custom_image => /administrativo/custom_images/new(.:format)
  // function(options)
  new_admin_dashboard_custom_image_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"custom_images",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_dashboard_role => /administrativo/roles/new(.:format)
  // function(options)
  new_admin_dashboard_role_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"roles",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_password => /admins/password/new(.:format)
  // function(options)
  new_admin_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admins",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_session => /admins/sign_in(.:format)
  // function(options)
  new_admin_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admins",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_admin_unlock => /admins/unlock/new(.:format)
  // function(options)
  new_admin_unlock_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admins",false],[2,[7,"/",false],[2,[6,"unlock",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_campaign => /administrativo/campaigns/new(.:format)
  // function(options)
  new_campaign_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_classification => /administrativo/classifications/new(.:format)
  // function(options)
  new_classification_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"classifications",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_classification_category => /administrativo/classifications/:classification_id/categories/new(.:format)
  // function(classification_id, options)
  new_classification_category_path: Utils.route([["classification_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"classifications",false],[2,[7,"/",false],[2,[3,"classification_id",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_classification_category_sub_category => /administrativo/classifications/:classification_id/categories/:category_id/sub_categories/new(.:format)
  // function(classification_id, category_id, options)
  new_classification_category_sub_category_path: Utils.route([["classification_id",true],["category_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"classifications",false],[2,[7,"/",false],[2,[3,"classification_id",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"category_id",false],[2,[7,"/",false],[2,[6,"sub_categories",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// new_company => /administrativo/companies/new(.:format)
  // function(options)
  new_company_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_customer => /checkout/dados-pessoais(.:format)
  // function(options)
  new_customer_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"checkout",false],[2,[7,"/",false],[2,[6,"dados-pessoais",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_customer_address => /checkout/endereco(.:format)
  // function(options)
  new_customer_address_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"checkout",false],[2,[7,"/",false],[2,[6,"endereco",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_dependant => /administrativo/dependants/new(.:format)
  // function(options)
  new_dependant_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"dependants",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_dependant_login_password => /dependant/password/new(.:format)
  // function(options)
  new_dependant_login_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dependant",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_dependant_login_session => /dependant/sign_in(.:format)
  // function(options)
  new_dependant_login_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dependant",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_dependant_login_unlock => /dependant/unlock/new(.:format)
  // function(options)
  new_dependant_login_unlock_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dependant",false],[2,[7,"/",false],[2,[6,"unlock",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_faq => /administrativo/faqs/new(.:format)
  // function(options)
  new_faq_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"faqs",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_hr_account => /administrativo/hr_accounts/new(.:format)
  // function(options)
  new_hr_account_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"hr_accounts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_hr_account_password => /rh/password/new(.:format)
  // function(options)
  new_hr_account_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rh",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_hr_account_session => /rh/sign_in(.:format)
  // function(options)
  new_hr_account_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rh",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_hr_account_unlock => /rh/unlock/new(.:format)
  // function(options)
  new_hr_account_unlock_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rh",false],[2,[7,"/",false],[2,[6,"unlock",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_lead => /fale-conosco(.:format)
  // function(options)
  new_lead_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"fale-conosco",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// new_partner => /administrativo/partners/new(.:format)
  // function(options)
  new_partner_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_partner_partner_branch => /administrativo/partners/:partner_id/partner_branches/new(.:format)
  // function(partner_id, options)
  new_partner_partner_branch_path: Utils.route([["partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[3,"partner_id",false],[2,[7,"/",false],[2,[6,"partner_branches",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_payment_profile => /checkout/pagamento(.:format)
  // function(options)
  new_payment_profile_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"checkout",false],[2,[7,"/",false],[2,[6,"pagamento",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/new(.:format)
  // function(options)
  new_rails_conductor_inbound_email_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_sales_dashboard_cash_accounting => /area-do-vendedor/caixas/new(.:format)
  // function(options)
  new_sales_dashboard_cash_accounting_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"caixas",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_sales_dashboard_cash_accounting_history => /area-do-vendedor/historico-de-caixas/new(.:format)
  // function(options)
  new_sales_dashboard_cash_accounting_history_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"historico-de-caixas",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_sales_dashboard_company => /area-do-vendedor/clientes/pessoa-juridica/new(.:format)
  // function(options)
  new_sales_dashboard_company_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-juridica",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_sales_dashboard_dependant => /area-do-vendedor/clientes/dependentes/new(.:format)
  // function(options)
  new_sales_dashboard_dependant_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"dependentes",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_sales_dashboard_local => /area-do-vendedor/unidades/new(.:format)
  // function(options)
  new_sales_dashboard_local_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"unidades",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_sales_dashboard_region => /area-do-vendedor/regionais/new(.:format)
  // function(options)
  new_sales_dashboard_region_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"regionais",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_sales_dashboard_seller => /area-do-vendedor/vendedores/new(.:format)
  // function(options)
  new_sales_dashboard_seller_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"vendedores",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_sales_dashboard_seller_goal_group => /area-do-vendedor/grupo-de-metas/new(.:format)
  // function(options)
  new_sales_dashboard_seller_goal_group_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"grupo-de-metas",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_subscription => /administrativo/subscriptions/new(.:format)
  // function(options)
  new_subscription_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"subscriptions",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user => /administrativo/users/new(.:format)
  // function(options)
  new_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_password => /users/password/new(.:format)
  // function(options)
  new_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_session => /users/sign_in(.:format)
  // function(options)
  new_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user_unlock => /users/unlock/new(.:format)
  // function(options)
  new_user_unlock_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"unlock",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// partner => /administrativo/partners/:id(.:format)
  // function(id, options)
  partner_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// partner_categories_json => /partner_categories(.:format)
  // function(options)
  partner_categories_json_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"partner_categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// partner_cities_json => /partner_cities(.:format)
  // function(options)
  partner_cities_json_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"partner_cities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// partner_filtering_session => /partner_filtering_session(.:format)
  // function(options)
  partner_filtering_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"partner_filtering_session",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// partner_partner_branch => /administrativo/partners/:partner_id/partner_branches/:id(.:format)
  // function(partner_id, id, options)
  partner_partner_branch_path: Utils.route([["partner_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[3,"partner_id",false],[2,[7,"/",false],[2,[6,"partner_branches",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// partner_partner_branches => /administrativo/partners/:partner_id/partner_branches(.:format)
  // function(partner_id, options)
  partner_partner_branches_path: Utils.route([["partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[3,"partner_id",false],[2,[7,"/",false],[2,[6,"partner_branches",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// partner_partner_branches_json => /administrativo/partners/:partner_id/partner_branches_json(.:format)
  // function(partner_id, options)
  partner_partner_branches_json_path: Utils.route([["partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[3,"partner_id",false],[2,[7,"/",false],[2,[6,"partner_branches_json",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// partner_show => /rede-de-parceiros/:id(.:format)
  // function(id, options)
  partner_show_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rede-de-parceiros",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// partner_show_json => /rede-de-parceiros/:id/json(.:format)
  // function(id, options)
  partner_show_json_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rede-de-parceiros",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"json",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// partner_states_json => /partner_states(.:format)
  // function(options)
  partner_states_json_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"partner_states",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// partner_sub_categories_json => /partner_sub_categories(.:format)
  // function(options)
  partner_sub_categories_json_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"partner_sub_categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// partners => /administrativo/partners(.:format)
  // function(options)
  partners_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"partners",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// partners_import => /administrativo/partners/import(.:format)
  // function(options)
  partners_import_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[6,"import",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// partners_imports_json => /administrativo/partners/imports/json(.:format)
  // function(options)
  partners_imports_json_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[6,"imports",false],[2,[7,"/",false],[2,[6,"json",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// partners_json => /partners(.:format)
  // function(options)
  partners_json_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"partners",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// partners_layout_file => /administrativo/partners/layout_file(.:format)
  // function(options)
  partners_layout_file_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[6,"layout_file",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// partners_list => /rede-de-parceiros(.:format)
  // function(options)
  partners_list_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rede-de-parceiros",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// payment_webhooks_cancel_invoice => /payment_webhooks/cancel_invoice(.:format)
  // function(options)
  payment_webhooks_cancel_invoice_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"payment_webhooks",false],[2,[7,"/",false],[2,[6,"cancel_invoice",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// payment_webhooks_invoice => /payment_webhooks/invoice(.:format)
  // function(options)
  payment_webhooks_invoice_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"payment_webhooks",false],[2,[7,"/",false],[2,[6,"invoice",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// payment_webhooks_notification => /payment_webhooks/notification(.:format)
  // function(options)
  payment_webhooks_notification_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"payment_webhooks",false],[2,[7,"/",false],[2,[6,"notification",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// privacy_policy => /politica-de-privacidade(.:format)
  // function(options)
  privacy_policy_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"politica-de-privacidade",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// products => /nossos-produtos(.:format)
  // function(options)
  products_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"nossos-produtos",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// rails_blob_representation => /rails/active_storage/representations/:signed_blob_id/:variation_key/*filename(.:format)
  // function(signed_blob_id, variation_key, filename, options)
  rails_blob_representation_path: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
  // function(id, options)
  rails_conductor_inbound_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_email_reroute => /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
  // function(inbound_email_id, options)
  rails_conductor_inbound_email_reroute_path: Utils.route([["inbound_email_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[3,"inbound_email_id",false],[2,[7,"/",false],[2,[6,"reroute",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_emails => /rails/conductor/action_mailbox/inbound_emails(.:format)
  // function(options)
  rails_conductor_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_direct_uploads => /rails/active_storage/direct_uploads(.:format)
  // function(options)
  rails_direct_uploads_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"direct_uploads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_disk_service => /rails/active_storage/disk/:encoded_key/*filename(.:format)
  // function(encoded_key, filename, options)
  rails_disk_service_path: Utils.route([["encoded_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_mailgun_inbound_emails => /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
  // function(options)
  rails_mailgun_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mailgun",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"mime",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_mandrill_inbound_emails => /rails/action_mailbox/mandrill/inbound_emails(.:format)
  // function(options)
  rails_mandrill_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_mandrill_inbound_health_check => /rails/action_mailbox/mandrill/inbound_emails(.:format)
  // function(options)
  rails_mandrill_inbound_health_check_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_postmark_inbound_emails => /rails/action_mailbox/postmark/inbound_emails(.:format)
  // function(options)
  rails_postmark_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"postmark",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_relay_inbound_emails => /rails/action_mailbox/relay/inbound_emails(.:format)
  // function(options)
  rails_relay_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"relay",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_sendgrid_inbound_emails => /rails/action_mailbox/sendgrid/inbound_emails(.:format)
  // function(options)
  rails_sendgrid_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"sendgrid",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_service_blob => /rails/active_storage/blobs/:signed_id/*filename(.:format)
  // function(signed_id, filename, options)
  rails_service_blob_path: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// root => /
  // function(options)
  root_path: Utils.route([], {}, [7,"/",false]),
// rswag_api => /api-docs
  // function(options)
  rswag_api_path: Utils.route([], {}, [2,[7,"/",false],[6,"api-docs",false]]),
// rswag_ui => /api-docs
  // function(options)
  rswag_ui_path: Utils.route([], {}, [2,[7,"/",false],[6,"api-docs",false]]),
// sales_dashboard => /area-do-vendedor(.:format)
  // function(options)
  sales_dashboard_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// sales_dashboard_all_customers => /area-do-vendedor/clientes(.:format)
  // function(options)
  sales_dashboard_all_customers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_dashboard_approve_request => /area-do-vendedor/clientes/solicitacoes/:request_id/approve(.:format)
  // function(request_id, options)
  sales_dashboard_approve_request_path: Utils.route([["request_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"solicitacoes",false],[2,[7,"/",false],[2,[3,"request_id",false],[2,[7,"/",false],[2,[6,"approve",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// sales_dashboard_cash_accounting => /area-do-vendedor/caixas/:id(.:format)
  // function(id, options)
  sales_dashboard_cash_accounting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"caixas",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sales_dashboard_cash_accounting_histories => /area-do-vendedor/historico-de-caixas(.:format)
  // function(options)
  sales_dashboard_cash_accounting_histories_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"historico-de-caixas",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_dashboard_cash_accounting_history => /area-do-vendedor/historico-de-caixas/:id(.:format)
  // function(id, options)
  sales_dashboard_cash_accounting_history_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"historico-de-caixas",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sales_dashboard_cash_accounting_json => /area-do-vendedor/caixas_json/:id(.:format)
  // function(id, options)
  sales_dashboard_cash_accounting_json_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"caixas_json",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sales_dashboard_cash_accounting_local_seller_report => /area-do-vendedor/caixas/:id/local_seller_report(.:format)
  // function(id, options)
  sales_dashboard_cash_accounting_local_seller_report_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"caixas",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"local_seller_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// sales_dashboard_cash_accounting_open_json => /area-do-vendedor/caixa_aberto_json(.:format)
  // function(options)
  sales_dashboard_cash_accounting_open_json_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"caixa_aberto_json",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_dashboard_cash_accountings => /area-do-vendedor/caixas(.:format)
  // function(options)
  sales_dashboard_cash_accountings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"caixas",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_dashboard_cash_accountings_json => /area-do-vendedor/caixas_json(.:format)
  // function(options)
  sales_dashboard_cash_accountings_json_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"caixas_json",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_dashboard_comentate_cancellation_request_json => /area-do-vendedor/clientes/pessoa-fisica/:id/solicitacoes_json/:cancellation_request_id/comments(.:format)
  // function(id, cancellation_request_id, options)
  sales_dashboard_comentate_cancellation_request_json_path: Utils.route([["id",true],["cancellation_request_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"solicitacoes_json",false],[2,[7,"/",false],[2,[3,"cancellation_request_id",false],[2,[7,"/",false],[2,[6,"comments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// sales_dashboard_companies => /area-do-vendedor/clientes/pessoa-juridica(.:format)
  // function(options)
  sales_dashboard_companies_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-juridica",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sales_dashboard_companies_json => /area-do-vendedor/clientes/pessoa-juridica_json(.:format)
  // function(options)
  sales_dashboard_companies_json_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-juridica_json",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sales_dashboard_company => /area-do-vendedor/clientes/pessoa-juridica/:id(.:format)
  // function(id, options)
  sales_dashboard_company_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-juridica",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// sales_dashboard_company_company_users => /area-do-vendedor/clientes/pessoa-juridica/:company_id/usuarios(.:format)
  // function(company_id, options)
  sales_dashboard_company_company_users_path: Utils.route([["company_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-juridica",false],[2,[7,"/",false],[2,[3,"company_id",false],[2,[7,"/",false],[2,[6,"usuarios",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// sales_dashboard_company_company_users_json => /area-do-vendedor/clientes/pessoa-juridica/:company_id/usuarios_json(.:format)
  // function(company_id, options)
  sales_dashboard_company_company_users_json_path: Utils.route([["company_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-juridica",false],[2,[7,"/",false],[2,[3,"company_id",false],[2,[7,"/",false],[2,[6,"usuarios_json",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// sales_dashboard_company_create_hr_account => /area-do-vendedor/clientes/pessoa-juridica/:company_id/adicionar-acesso-rh(.:format)
  // function(company_id, options)
  sales_dashboard_company_create_hr_account_path: Utils.route([["company_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-juridica",false],[2,[7,"/",false],[2,[3,"company_id",false],[2,[7,"/",false],[2,[6,"adicionar-acesso-rh",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// sales_dashboard_company_delete_hr_account => /area-do-vendedor/clientes/pessoa-juridica/:company_id/remover-acesso-rh/:id(.:format)
  // function(company_id, id, options)
  sales_dashboard_company_delete_hr_account_path: Utils.route([["company_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-juridica",false],[2,[7,"/",false],[2,[3,"company_id",false],[2,[7,"/",false],[2,[6,"remover-acesso-rh",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// sales_dashboard_company_edit_hr_account => /area-do-vendedor/clientes/pessoa-juridica/:company_id/editar-acesso-rh/:id(.:format)
  // function(company_id, id, options)
  sales_dashboard_company_edit_hr_account_path: Utils.route([["company_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-juridica",false],[2,[7,"/",false],[2,[3,"company_id",false],[2,[7,"/",false],[2,[6,"editar-acesso-rh",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// sales_dashboard_company_new_hr_account => /area-do-vendedor/clientes/pessoa-juridica/:company_id/adicionar-acesso-rh(.:format)
  // function(company_id, options)
  sales_dashboard_company_new_hr_account_path: Utils.route([["company_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-juridica",false],[2,[7,"/",false],[2,[3,"company_id",false],[2,[7,"/",false],[2,[6,"adicionar-acesso-rh",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// sales_dashboard_company_update_hr_account => /area-do-vendedor/clientes/pessoa-juridica/:company_id/editar-acesso-rh/:id(.:format)
  // function(company_id, id, options)
  sales_dashboard_company_update_hr_account_path: Utils.route([["company_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-juridica",false],[2,[7,"/",false],[2,[3,"company_id",false],[2,[7,"/",false],[2,[6,"editar-acesso-rh",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// sales_dashboard_confirm_cash_accounting_closing => /area-do-vendedor/caixas/:id/fechar_json(.:format)
  // function(id, options)
  sales_dashboard_confirm_cash_accounting_closing_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"caixas",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"fechar_json",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// sales_dashboard_confirm_customer_data => /area-do-vendedor/clientes/pessoa-fisica/:id/confirmar-dados(.:format)
  // function(id, options)
  sales_dashboard_confirm_customer_data_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"confirmar-dados",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// sales_dashboard_confirm_renewal_data => /area-do-vendedor/clientes/pessoa-fisica/:id/renovacao/confirmar-dados(.:format)
  // function(id, options)
  sales_dashboard_confirm_renewal_data_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"renovacao",false],[2,[7,"/",false],[2,[6,"confirmar-dados",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// sales_dashboard_create_cancellation_request_json => /area-do-vendedor/clientes/pessoa-fisica/:id/solicitacoes_json(.:format)
  // function(id, options)
  sales_dashboard_create_cancellation_request_json_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"solicitacoes_json",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// sales_dashboard_create_customer => /area-do-vendedor/clientes/pessoa-fisica(.:format)
  // function(options)
  sales_dashboard_create_customer_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sales_dashboard_create_dependant => /area-do-vendedor/clientes/pessoa-fisica/:id/dependentes(.:format)
  // function(id, options)
  sales_dashboard_create_dependant_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"dependentes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// sales_dashboard_create_sellers_batch => /area-do-vendedor/importar-vendedores(.:format)
  // function(options)
  sales_dashboard_create_sellers_batch_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"importar-vendedores",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_dashboard_customer => /area-do-vendedor/clientes/pessoa-fisica/:id(.:format)
  // function(id, options)
  sales_dashboard_customer_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// sales_dashboard_customer_creation_success => /area-do-vendedor/clientes/pessoa-fisica/:id/cadastro-concluido(.:format)
  // function(id, options)
  sales_dashboard_customer_creation_success_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"cadastro-concluido",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// sales_dashboard_customer_renewal_success => /area-do-vendedor/clientes/pessoa-fisica/:id/renovacao/cadastro-concluido(.:format)
  // function(id, options)
  sales_dashboard_customer_renewal_success_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"renovacao",false],[2,[7,"/",false],[2,[6,"cadastro-concluido",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// sales_dashboard_customer_search => /area-do-vendedor/clientes/pessoa-fisica/buscar(.:format)
  // function(options)
  sales_dashboard_customer_search_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[2,[7,"/",false],[2,[6,"buscar",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// sales_dashboard_customers => /area-do-vendedor/clientes/pessoa-fisica(.:format)
  // function(options)
  sales_dashboard_customers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sales_dashboard_customers_json => /area-do-vendedor/clientes/pessoa-fisica_json(.:format)
  // function(options)
  sales_dashboard_customers_json_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica_json",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sales_dashboard_delete_dependant => /area-do-vendedor/clientes/pessoa-fisica/:id/dependentes/:dependant_id(.:format)
  // function(id, dependant_id, options)
  sales_dashboard_delete_dependant_path: Utils.route([["id",true],["dependant_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"dependentes",false],[2,[7,"/",false],[2,[3,"dependant_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// sales_dashboard_dependant => /area-do-vendedor/clientes/dependentes/:dependant_id(.:format)
  // function(dependant_id, options)
  sales_dashboard_dependant_path: Utils.route([["dependant_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"dependentes",false],[2,[7,"/",false],[2,[3,"dependant_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// sales_dashboard_dependants => /area-do-vendedor/clientes/dependentes(.:format)
  // function(options)
  sales_dashboard_dependants_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"dependentes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sales_dashboard_dependants_json => /area-do-vendedor/clientes/dependentes_json(.:format)
  // function(options)
  sales_dashboard_dependants_json_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"dependentes_json",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sales_dashboard_documents => /area-do-vendedor/documentos(.:format)
  // function(options)
  sales_dashboard_documents_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"documentos",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_dashboard_edit_customer => /area-do-vendedor/clientes/pessoa-fisica/:id/edit(.:format)
  // function(id, options)
  sales_dashboard_edit_customer_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// sales_dashboard_edit_dependant => /area-do-vendedor/clientes/pessoa-fisica/:id/dependentes/:dependant_id/edit(.:format)
  // function(id, dependant_id, options)
  sales_dashboard_edit_dependant_path: Utils.route([["id",true],["dependant_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"dependentes",false],[2,[7,"/",false],[2,[3,"dependant_id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// sales_dashboard_edit_personal_data => /area-do-vendedor/primeiro-acesso(.:format)
  // function(options)
  sales_dashboard_edit_personal_data_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"primeiro-acesso",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_dashboard_edit_sale => /area-do-vendedor/clientes/pessoa-fisica/editar-venda/:id(.:format)
  // function(id, options)
  sales_dashboard_edit_sale_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[2,[7,"/",false],[2,[6,"editar-venda",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// sales_dashboard_faqs => /area-do-vendedor/faq(.:format)
  // function(options)
  sales_dashboard_faqs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"faq",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_dashboard_generate_management_report => /area-do-vendedor/generate_management_report(.:format)
  // function(options)
  sales_dashboard_generate_management_report_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"generate_management_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_dashboard_generate_report_cash_accounting_history => /area-do-vendedor/historico-de-caixa/:id/generate_report(.:format)
  // function(id, options)
  sales_dashboard_generate_report_cash_accounting_history_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"historico-de-caixa",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"generate_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// sales_dashboard_goal_groups_json => /area-do-vendedor/grupo_de_metas_json(.:format)
  // function(options)
  sales_dashboard_goal_groups_json_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"grupo_de_metas_json",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_dashboard_import_file_status => /area-do-vendedor/import_file_status(.:format)
  // function(options)
  sales_dashboard_import_file_status_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"import_file_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_dashboard_import_sellers_batch => /area-do-vendedor/importar-vendedores(.:format)
  // function(options)
  sales_dashboard_import_sellers_batch_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"importar-vendedores",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_dashboard_inactivate_cancellation_request_json => /area-do-vendedor/clientes/pessoa-fisica/:id/solicitacoes_json/:cancellation_request_id(.:format)
  // function(id, cancellation_request_id, options)
  sales_dashboard_inactivate_cancellation_request_json_path: Utils.route([["id",true],["cancellation_request_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"solicitacoes_json",false],[2,[7,"/",false],[2,[3,"cancellation_request_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// sales_dashboard_latest_cash_accounting => /area-do-vendedor/caixas/recentes_json(.:format)
  // function(options)
  sales_dashboard_latest_cash_accounting_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"caixas",false],[2,[7,"/",false],[2,[6,"recentes_json",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sales_dashboard_list_cancellation_request_comments_json => /area-do-vendedor/clientes/pessoa-fisica/:id/solicitacoes_json/:cancellation_request_id/comments(.:format)
  // function(id, cancellation_request_id, options)
  sales_dashboard_list_cancellation_request_comments_json_path: Utils.route([["id",true],["cancellation_request_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"solicitacoes_json",false],[2,[7,"/",false],[2,[3,"cancellation_request_id",false],[2,[7,"/",false],[2,[6,"comments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// sales_dashboard_list_cancellation_request_statuses_json => /area-do-vendedor/clientes/pessoa-fisica/:id/solicitacoes_json/:cancellation_request_id/status(.:format)
  // function(id, cancellation_request_id, options)
  sales_dashboard_list_cancellation_request_statuses_json_path: Utils.route([["id",true],["cancellation_request_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"solicitacoes_json",false],[2,[7,"/",false],[2,[3,"cancellation_request_id",false],[2,[7,"/",false],[2,[6,"status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// sales_dashboard_list_cancellation_requests_json => /area-do-vendedor/clientes/pessoa-fisica/:id/solicitacoes_json(.:format)
  // function(id, options)
  sales_dashboard_list_cancellation_requests_json_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"solicitacoes_json",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// sales_dashboard_list_cash_accounting_history_json => /area-do-vendedor/historico-de-caixa/list_json(.:format)
  // function(options)
  sales_dashboard_list_cash_accounting_history_json_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"historico-de-caixa",false],[2,[7,"/",false],[2,[6,"list_json",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sales_dashboard_list_notifications_json => /area-do-vendedor/notificacoes/json(.:format)
  // function(options)
  sales_dashboard_list_notifications_json_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"notificacoes",false],[2,[7,"/",false],[2,[6,"json",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sales_dashboard_list_requests_json => /area-do-vendedor/clientes/solicitacoes_json(.:format)
  // function(options)
  sales_dashboard_list_requests_json_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"solicitacoes_json",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sales_dashboard_list_subscriptions_to_cash_accounting_history_json => /area-do-vendedor/historico-de-caixa/list_subscriptions_json(.:format)
  // function(options)
  sales_dashboard_list_subscriptions_to_cash_accounting_history_json_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"historico-de-caixa",false],[2,[7,"/",false],[2,[6,"list_subscriptions_json",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sales_dashboard_local => /area-do-vendedor/unidades/:id(.:format)
  // function(id, options)
  sales_dashboard_local_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"unidades",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sales_dashboard_local_on_session_json => /area-do-vendedor/unidade_selecionada_json(.:format)
  // function(options)
  sales_dashboard_local_on_session_json_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"unidade_selecionada_json",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_dashboard_locals => /area-do-vendedor/unidades(.:format)
  // function(options)
  sales_dashboard_locals_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"unidades",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_dashboard_locals_by_region_json => /area-do-vendedor/unidades_por_regional_json(.:format)
  // function(options)
  sales_dashboard_locals_by_region_json_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"unidades_por_regional_json",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_dashboard_locals_by_search_json => /area-do-vendedor/unidades_filtradas_json(.:format)
  // function(options)
  sales_dashboard_locals_by_search_json_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"unidades_filtradas_json",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_dashboard_locals_json => /area-do-vendedor/unidades_json(.:format)
  // function(options)
  sales_dashboard_locals_json_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"unidades_json",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_dashboard_management_report_status => /area-do-vendedor/management_report_status(.:format)
  // function(options)
  sales_dashboard_management_report_status_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"management_report_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_dashboard_metlife_remuneration_json => /area-do-vendedor/remuneracao_metlife_json(.:format)
  // function(options)
  sales_dashboard_metlife_remuneration_json_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"remuneracao_metlife_json",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_dashboard_metlife_report => /area-do-vendedor/metlife-report(.:format)
  // function(options)
  sales_dashboard_metlife_report_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"metlife-report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_dashboard_new_customer => /area-do-vendedor/clientes/pessoa-fisica/novo(.:format)
  // function(options)
  sales_dashboard_new_customer_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[2,[7,"/",false],[2,[6,"novo",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// sales_dashboard_new_dependant => /area-do-vendedor/clientes/pessoa-fisica/:id/dependentes/new(.:format)
  // function(id, options)
  sales_dashboard_new_dependant_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"dependentes",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// sales_dashboard_payment_not_confirmed => /area-do-vendedor/clientes/pessoa-fisica/:id/pagamento-nao-confirmado(.:format)
  // function(id, options)
  sales_dashboard_payment_not_confirmed_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"pagamento-nao-confirmado",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// sales_dashboard_reactivate_dependant => /area-do-vendedor/clientes/pessoa-fisica/:id/dependentes/:dependant_id/reativar(.:format)
  // function(id, dependant_id, options)
  sales_dashboard_reactivate_dependant_path: Utils.route([["id",true],["dependant_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"dependentes",false],[2,[7,"/",false],[2,[3,"dependant_id",false],[2,[7,"/",false],[2,[6,"reativar",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// sales_dashboard_refund_request_accepted => /area-do-vendedor/clientes/solicitacoes/:request_id/reverse(.:format)
  // function(request_id, options)
  sales_dashboard_refund_request_accepted_path: Utils.route([["request_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"solicitacoes",false],[2,[7,"/",false],[2,[3,"request_id",false],[2,[7,"/",false],[2,[6,"reverse",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// sales_dashboard_refuse_request => /area-do-vendedor/clientes/solicitacoes/:request_id/refuse(.:format)
  // function(request_id, options)
  sales_dashboard_refuse_request_path: Utils.route([["request_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"solicitacoes",false],[2,[7,"/",false],[2,[3,"request_id",false],[2,[7,"/",false],[2,[6,"refuse",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// sales_dashboard_region => /area-do-vendedor/regionais/:id(.:format)
  // function(id, options)
  sales_dashboard_region_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"regionais",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sales_dashboard_regions => /area-do-vendedor/regionais(.:format)
  // function(options)
  sales_dashboard_regions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"regionais",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_dashboard_regions_json => /area-do-vendedor/regionais_json(.:format)
  // function(options)
  sales_dashboard_regions_json_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"regionais_json",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_dashboard_reopen_request => /area-do-vendedor/clientes/solicitacoes/:request_id/reopen(.:format)
  // function(request_id, options)
  sales_dashboard_reopen_request_path: Utils.route([["request_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"solicitacoes",false],[2,[7,"/",false],[2,[3,"request_id",false],[2,[7,"/",false],[2,[6,"reopen",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// sales_dashboard_reports => /area-do-vendedor/remuneracao_metlife(.:format)
  // function(options)
  sales_dashboard_reports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"remuneracao_metlife",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_dashboard_request => /area-do-vendedor/clientes/solicitacoes/:id(.:format)
  // function(id, options)
  sales_dashboard_request_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"solicitacoes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// sales_dashboard_requests => /area-do-vendedor/clientes/solicitacoes(.:format)
  // function(options)
  sales_dashboard_requests_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"solicitacoes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sales_dashboard_select_local => /area-do-vendedor/selecionar-unidade(.:format)
  // function(options)
  sales_dashboard_select_local_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"selecionar-unidade",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_dashboard_seller => /area-do-vendedor/vendedores/:id(.:format)
  // function(id, options)
  sales_dashboard_seller_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"vendedores",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sales_dashboard_seller_deactivate => /area-do-vendedor/vendedores/:seller_id/desativar(.:format)
  // function(seller_id, options)
  sales_dashboard_seller_deactivate_path: Utils.route([["seller_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"vendedores",false],[2,[7,"/",false],[2,[3,"seller_id",false],[2,[7,"/",false],[2,[6,"desativar",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// sales_dashboard_seller_goal_group => /area-do-vendedor/grupo-de-metas/:id(.:format)
  // function(id, options)
  sales_dashboard_seller_goal_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"grupo-de-metas",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sales_dashboard_seller_goal_groups => /area-do-vendedor/grupo-de-metas(.:format)
  // function(options)
  sales_dashboard_seller_goal_groups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"grupo-de-metas",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_dashboard_seller_reactivate => /area-do-vendedor/vendedores/:seller_id/reativar(.:format)
  // function(seller_id, options)
  sales_dashboard_seller_reactivate_path: Utils.route([["seller_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"vendedores",false],[2,[7,"/",false],[2,[3,"seller_id",false],[2,[7,"/",false],[2,[6,"reativar",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// sales_dashboard_seller_reset_password => /area-do-vendedor/vendedores/:seller_id/senha-temporaria(.:format)
  // function(seller_id, options)
  sales_dashboard_seller_reset_password_path: Utils.route([["seller_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"vendedores",false],[2,[7,"/",false],[2,[3,"seller_id",false],[2,[7,"/",false],[2,[6,"senha-temporaria",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// sales_dashboard_seller_temporary_password => /area-do-vendedor/vendedores/:seller_id/senha-temporaria(.:format)
  // function(seller_id, options)
  sales_dashboard_seller_temporary_password_path: Utils.route([["seller_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"vendedores",false],[2,[7,"/",false],[2,[3,"seller_id",false],[2,[7,"/",false],[2,[6,"senha-temporaria",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// sales_dashboard_sellers => /area-do-vendedor/vendedores(.:format)
  // function(options)
  sales_dashboard_sellers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"vendedores",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_dashboard_sellers_by_search_json => /area-do-vendedor/vendedores_filtrados_json(.:format)
  // function(options)
  sales_dashboard_sellers_by_search_json_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"vendedores_filtrados_json",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_dashboard_sellers_json => /area-do-vendedor/vendedores_json(.:format)
  // function(options)
  sales_dashboard_sellers_json_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"vendedores_json",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_dashboard_sellers_layout_file => /area-do-vendedor/planilha-vendedores(.:format)
  // function(options)
  sales_dashboard_sellers_layout_file_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"planilha-vendedores",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_dashboard_send_payment_link_to_customer => /area-do-vendedor/clientes/pessoa-fisica/:id/send-payment-link(.:format)
  // function(id, options)
  sales_dashboard_send_payment_link_to_customer_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"send-payment-link",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// sales_dashboard_set_local_on_session_json => /area-do-vendedor/selecionar_unidade_json(.:format)
  // function(options)
  sales_dashboard_set_local_on_session_json_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"selecionar_unidade_json",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_dashboard_show_confirm_customer_data => /area-do-vendedor/clientes/pessoa-fisica/:id/confirmar-dados(.:format)
  // function(id, options)
  sales_dashboard_show_confirm_customer_data_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"confirmar-dados",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// sales_dashboard_show_confirm_customer_data_to_insert_machine_code => /area-do-vendedor/clientes/pessoa-fisica/:id/inserir-codigo(.:format)
  // function(id, options)
  sales_dashboard_show_confirm_customer_data_to_insert_machine_code_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"inserir-codigo",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// sales_dashboard_show_confirm_renewal_data => /area-do-vendedor/clientes/pessoa-fisica/:id/renovacao/confirmar-dados(.:format)
  // function(id, options)
  sales_dashboard_show_confirm_renewal_data_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"renovacao",false],[2,[7,"/",false],[2,[6,"confirmar-dados",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// sales_dashboard_show_confirm_renewal_data_to_insert_machine_code => /area-do-vendedor/clientes/pessoa-fisica/:id/renovacao/inserir-codigo(.:format)
  // function(id, options)
  sales_dashboard_show_confirm_renewal_data_to_insert_machine_code_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"renovacao",false],[2,[7,"/",false],[2,[6,"inserir-codigo",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// sales_dashboard_show_dependant => /area-do-vendedor/clientes/pessoa-fisica/:id/dependentes/:dependant_id(.:format)
  // function(id, dependant_id, options)
  sales_dashboard_show_dependant_path: Utils.route([["id",true],["dependant_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"dependentes",false],[2,[7,"/",false],[2,[3,"dependant_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// sales_dashboard_show_notification => /area-do-vendedor/notificacoes/:id(.:format)
  // function(id, options)
  sales_dashboard_show_notification_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"notificacoes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sales_dashboard_start_analysis => /area-do-vendedor/clientes/solicitacoes/:request_id(.:format)
  // function(request_id, options)
  sales_dashboard_start_analysis_path: Utils.route([["request_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"solicitacoes",false],[2,[7,"/",false],[2,[3,"request_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// sales_dashboard_subscription_contract => /area-do-vendedor/clientes/pessoa-fisica/:id/contract(.:format)
  // function(id, options)
  sales_dashboard_subscription_contract_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"contract",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// sales_dashboard_subscription_contract_plus => /area-do-vendedor/clientes/pessoa-fisica/:id/contract-plus(.:format)
  // function(id, options)
  sales_dashboard_subscription_contract_plus_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"contract-plus",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// sales_dashboard_subscription_histories => /area-do-vendedor/clientes/pessoa-fisica/:id/historico-assinatura(.:format)
  // function(id, options)
  sales_dashboard_subscription_histories_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"historico-assinatura",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// sales_dashboard_subscription_history_receipt => /area-do-vendedor/clientes/pessoa-fisica/:id/historico-assinatura/:history_id/recibo(.:format)
  // function(id, history_id, options)
  sales_dashboard_subscription_history_receipt_path: Utils.route([["id",true],["history_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"historico-assinatura",false],[2,[7,"/",false],[2,[3,"history_id",false],[2,[7,"/",false],[2,[6,"recibo",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// sales_dashboard_subscription_receipt => /area-do-vendedor/clientes/pessoa-fisica/:id/receipt(.:format)
  // function(id, options)
  sales_dashboard_subscription_receipt_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"receipt",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// sales_dashboard_subscription_renewal_index => /area-do-vendedor/clientes/pessoa-fisica/:id/renovar-plano(.:format)
  // function(id, options)
  sales_dashboard_subscription_renewal_index_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"renovar-plano",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// sales_dashboard_subscription_renewal_receipt => /area-do-vendedor/clientes/pessoa-fisica/:id/renovacao/receipt(.:format)
  // function(id, options)
  sales_dashboard_subscription_renewal_receipt_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"renovacao",false],[2,[7,"/",false],[2,[6,"receipt",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// sales_dashboard_subscription_renewal_update => /area-do-vendedor/clientes/pessoa-fisica/:id/renovar-plano(.:format)
  // function(id, options)
  sales_dashboard_subscription_renewal_update_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"renovar-plano",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// sales_dashboard_subscriptions_json => /area-do-vendedor/assinaturas_json(.:format)
  // function(options)
  sales_dashboard_subscriptions_json_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"assinaturas_json",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_dashboard_synchronize_vindi_subscription => /area-do-vendedor/clientes/pessoa-fisica/:id/synchronize-subscription(.:format)
  // function(id, options)
  sales_dashboard_synchronize_vindi_subscription_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"synchronize-subscription",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// sales_dashboard_update_customer => /area-do-vendedor/clientes/pessoa-fisica/:id(.:format)
  // function(id, options)
  sales_dashboard_update_customer_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// sales_dashboard_update_dependant => /area-do-vendedor/clientes/pessoa-fisica/:id/dependentes/:dependant_id(.:format)
  // function(id, dependant_id, options)
  sales_dashboard_update_dependant_path: Utils.route([["id",true],["dependant_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"dependentes",false],[2,[7,"/",false],[2,[3,"dependant_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// sales_dashboard_update_payment_data => /area-do-vendedor/atualizar-dados-de-pagamento(.:format)
  // function(options)
  sales_dashboard_update_payment_data_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"atualizar-dados-de-pagamento",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_dashboard_update_personal_data => /area-do-vendedor/primeiro-acesso/atualizar(.:format)
  // function(options)
  sales_dashboard_update_personal_data_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"primeiro-acesso",false],[2,[7,"/",false],[2,[6,"atualizar",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sales_dashboard_update_sale => /area-do-vendedor/atualizar-venda(.:format)
  // function(options)
  sales_dashboard_update_sale_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"atualizar-venda",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sales_dashboard_update_seen_notifications => /area-do-vendedor/notificacoes/marcar-notificacoes-json(.:format)
  // function(options)
  sales_dashboard_update_seen_notifications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"notificacoes",false],[2,[7,"/",false],[2,[6,"marcar-notificacoes-json",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sales_dashboard_upload_renewal_signed_contract => /area-do-vendedor/clientes/pessoa-fisica/:id/renovacao/upload-contrato(.:format)
  // function(id, options)
  sales_dashboard_upload_renewal_signed_contract_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"renovacao",false],[2,[7,"/",false],[2,[6,"upload-contrato",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// sales_dashboard_upload_signed_contract => /area-do-vendedor/clientes/pessoa-fisica/:id/upload-contrato(.:format)
  // function(id, options)
  sales_dashboard_upload_signed_contract_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-vendedor",false],[2,[7,"/",false],[2,[6,"clientes",false],[2,[7,"/",false],[2,[6,"pessoa-fisica",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"upload-contrato",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// search_hr_account => /administrativo/search_hr_account(.:format)
  // function(options)
  search_hr_account_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"search_hr_account",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// subscription => /administrativo/subscriptions/:id(.:format)
  // function(id, options)
  subscription_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"subscriptions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// subscription_edit_subscription_timestamps => /administrativo/subscriptions/:subscription_id/edit_subscription_timestamps(.:format)
  // function(subscription_id, options)
  subscription_edit_subscription_timestamps_path: Utils.route([["subscription_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"subscriptions",false],[2,[7,"/",false],[2,[3,"subscription_id",false],[2,[7,"/",false],[2,[6,"edit_subscription_timestamps",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// subscription_resend_payment_link_email => /administrativo/subscriptions/:subscription_id/resend_payment_link_email(.:format)
  // function(subscription_id, options)
  subscription_resend_payment_link_email_path: Utils.route([["subscription_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"subscriptions",false],[2,[7,"/",false],[2,[3,"subscription_id",false],[2,[7,"/",false],[2,[6,"resend_payment_link_email",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// subscription_update_subscription_timestamps => /administrativo/subscriptions/:subscription_id/update_subscription_timestamps(.:format)
  // function(subscription_id, options)
  subscription_update_subscription_timestamps_path: Utils.route([["subscription_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"subscriptions",false],[2,[7,"/",false],[2,[3,"subscription_id",false],[2,[7,"/",false],[2,[6,"update_subscription_timestamps",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// subscriptions => /administrativo/subscriptions(.:format)
  // function(options)
  subscriptions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"subscriptions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// telemedicine_hotpage => /consulta-online(.:format)
  // function(options)
  telemedicine_hotpage_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"consulta-online",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// update_company_user_info => /empresarial/primeiro-acesso/confirmar-dados(.:format)
  // function(options)
  update_company_user_info_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"empresarial",false],[2,[7,"/",false],[2,[6,"primeiro-acesso",false],[2,[7,"/",false],[2,[6,"confirmar-dados",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_dependant_password => /area-do-dependente/alterar-senha(.:format)
  // function(options)
  update_dependant_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-dependente",false],[2,[7,"/",false],[2,[6,"alterar-senha",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// update_dependant_personal_data => /area-do-dependente/dados-pessoais/editar(.:format)
  // function(options)
  update_dependant_personal_data_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"area-do-dependente",false],[2,[7,"/",false],[2,[6,"dados-pessoais",false],[2,[7,"/",false],[2,[6,"editar",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_rails_disk_service => /rails/active_storage/disk/:encoded_token(.:format)
  // function(encoded_token, options)
  update_rails_disk_service_path: Utils.route([["encoded_token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user => /administrativo/users/:id(.:format)
  // function(id, options)
  user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_activation_fill_info => /usuarios/primeiro-acesso/confirmar-dados(.:format)
  // function(options)
  user_activation_fill_info_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"usuarios",false],[2,[7,"/",false],[2,[6,"primeiro-acesso",false],[2,[7,"/",false],[2,[6,"confirmar-dados",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_activation_start => /usuarios/primeiro-acesso(.:format)
  // function(options)
  user_activation_start_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"usuarios",false],[2,[7,"/",false],[2,[6,"primeiro-acesso",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_activation_update_info => /usuarios/primeiro-acesso/confirmar-dados(.:format)
  // function(options)
  user_activation_update_info_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"usuarios",false],[2,[7,"/",false],[2,[6,"primeiro-acesso",false],[2,[7,"/",false],[2,[6,"confirmar-dados",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_activation_validate => /usuarios/primeiro-acesso(.:format)
  // function(options)
  user_activation_validate_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"usuarios",false],[2,[7,"/",false],[2,[6,"primeiro-acesso",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_edit_address => /administrativo/users/:user_id/edit_address(.:format)
  // function(user_id, options)
  user_edit_address_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"edit_address",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_password => /users/password(.:format)
  // function(options)
  user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_session => /users/sign_in(.:format)
  // function(options)
  user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_synchronize_vindi_subscription => /administrativo/users/:user_id/synchronize_vindi_subscription(.:format)
  // function(user_id, options)
  user_synchronize_vindi_subscription_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"synchronize_vindi_subscription",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_unlock => /users/unlock(.:format)
  // function(options)
  user_unlock_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"unlock",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_update_address => /administrativo/users/:user_id/update_address(.:format)
  // function(user_id, options)
  user_update_address_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"update_address",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_virtual_card => /cartao-virtual(.:format)
  // function(options)
  user_virtual_card_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"cartao-virtual",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// users => /administrativo/users(.:format)
  // function(options)
  users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// users_json => /administrativo/users/json(.:format)
  // function(options)
  users_json_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"json",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// users_virtual_card_qrcode => /users_virtual_card/qrcode(.:format)
  // function(options)
  users_virtual_card_qrcode_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users_virtual_card",false],[2,[7,"/",false],[2,[6,"qrcode",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// validate_company_user_activation => /empresarial/primeiro-acesso(.:format)
  // function(options)
  validate_company_user_activation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"empresarial",false],[2,[7,"/",false],[2,[6,"primeiro-acesso",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// voucher => /administrativo/vouchers/:voucher_id(.:format)
  // function(voucher_id, options)
  voucher_path: Utils.route([["voucher_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"vouchers",false],[2,[7,"/",false],[2,[3,"voucher_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// voucher_use_voucher => /administrativo/vouchers/:voucher_id(.:format)
  // function(voucher_id, options)
  voucher_use_voucher_path: Utils.route([["voucher_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"vouchers",false],[2,[7,"/",false],[2,[3,"voucher_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// vouchers => /administrativo/vouchers(.:format)
  // function(options)
  vouchers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"vouchers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// vouchers_find_voucher_json => /administrativo/vouchers/find_voucher_json(.:format)
  // function(options)
  vouchers_find_voucher_json_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"vouchers",false],[2,[7,"/",false],[2,[6,"find_voucher_json",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// vouchers_use_voucher_json => /administrativo/vouchers/use_voucher_json(.:format)
  // function(options)
  vouchers_use_voucher_json_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"administrativo",false],[2,[7,"/",false],[2,[6,"vouchers",false],[2,[7,"/",false],[2,[6,"use_voucher_json",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]])}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      Object.defineProperty(routes, 'defaults', {
        get: function() {
          throw new Error("Routes" + ".defaults is removed. Use " + "Routes" + ".configure() instead.");
        },
        set: function(value) {}
      });
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Utils.namespace(root, "Routes", routes);
    }
  };

  result = Utils.make();

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return result;
    });
  }

  return result;

}).call(this);

