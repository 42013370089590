import "jquery-mask-plugin";
import moment from "moment";

window.subscribers = {};

export function subscribe(event, callback) {
  if (!window.subscribers[event]) {
    window.subscribers[event] = [];
  }
  window.subscribers[event].push(callback);
}

export function publish(event, data) {
  if (!window.subscribers[event]) return;
  window.subscribers[event].forEach(subscriberCallback => subscriberCallback(data));
}

export function normalizeStr(str) {
  if (!str) return str;
  return str.normalize("NFKD").replace(/[^\w]/g, "");
}

export function capitalizeAllWords(text) {
  if (typeof text !== "string") return "";

  var words = text.toLowerCase().split(" ");

  for (var a = 0; a < words.length; a++) {
    var w = words[a];
    words[a] = w[0].toUpperCase() + w.slice(1);
  }

  return words.join(" ");
}

export function goToByScroll(selector, offset = 0) {
  $("html,body").animate(
    {
      scrollTop: $(selector).offset().top - offset,
    },
    "fast"
  );
}

export function scrollToTop() {
  $("html, body").animate({ scrollTop: 0 }, 600);
}

export function initInfiniteScroll(anchorPoint) {
  if (!$(anchorPoint).length) return;
  const offsetAmount = $(".faqs-shared-page").length ? 300 : 150;
  $(window).off("scroll");
  $(window).on("scroll", function () {
    if (
      $(anchorPoint).offset() &&
      $(window).scrollTop() >=
        $(anchorPoint).offset().top +
          $(anchorPoint).outerHeight() -
          window.innerHeight +
          offsetAmount
    ) {
      $(".see-more").click();
    }
  });
}

export function formMasks() {
  $(".cep").mask("00000-000");
  $(".cpf").mask("000.000.000-00");
  $(".cnpj").mask("00.000.000/0000-00");
  $(".phone").mask("(00) 00000-0000");
  $(".street-number").mask("00000000");
  $(".cvv").mask("00ZZ", {
    translation: { Z: { pattern: /[0-9]/, optional: true } },
  });
  $(".card-number").mask("0000 0000 0000 ZZZZ", {
    translation: { Z: { pattern: /[0-9]/, optional: true } },
  });

  $(".card-expiration").mask("00/0000");

  let dateValue = $(".date").val();
  if (dateValue) {
    let dateSplit = dateValue.split("-");
    if (dateSplit[0].length == 4 && dateSplit.length == 3) {
      $(".date").val(`${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`);
    }
  }
  $(".date").mask("00/00/0000");
}

export function initSearch() {
  $("#tableSearch").on("keyup", function () {
    var value = $(this)
      .val()
      .toLowerCase()
      .normalize("NFKD")
      .replace(/[^\w]/g, "");
    $("#indexTable tr").filter(function () {
      $(this).toggle(
        $(this)
          .text()
          .toLowerCase()
          .normalize("NFKD")
          .replace(/[^\w]/g, "")
          .indexOf(value) > -1
      );
    });
  });
}

export function maskPhones() {
  $(".phone-number").text(function (i, text) {
    if (text.length == 10) {
      return text.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
    } else {
      return text.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    }
  });
}

export function maskPhoneText(phone) {
  if (!phone) return;
  
  if (phone.length == 10) {
    return phone.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
  } else {
    return phone.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
  }
}

const applySearch = (value) => {
  if (value) {
    value = value.toLowerCase().normalize("NFKD").replace(/[^\w]/g, "");
  }

  $("#siteSearchableList")
    .find(".site-searchable-row")
    .filter(function () {
      $(this).toggle(
        $(this)
          .text()
          .toLowerCase()
          .normalize("NFKD")
          .replace(/[^\w]/g, "")
          .indexOf(value) > -1
      );
    });
};

const verifyDataInInputSearch = () => {
  const siteSearchInput = $("#siteSearchInput");
  const siteSearchableList = $("#siteSearchableList");
  const panelAskServiceType = $("#panelAskServiceType");

  if (siteSearchInput && siteSearchInput.val()) {
    siteSearchableList.show();
    panelAskServiceType.hide();
  } else {
    siteSearchableList.hide();
    panelAskServiceType.show();
  }
};

export function initSiteSearch() {
  const siteSearchInput = $("#siteSearchInput");

  applySearch(siteSearchInput.val());

  siteSearchInput.on("keyup", (event) => {
    applySearch(event.target.value);
    verifyDataInInputSearch();
  });
}

export function initSimpleSiteSearch() {
  $("#siteSearchInput").on("keyup", (event) => {
    applySearch(event.target.value);
  });
}

export function initFaq() {
  $(".question").off();
  $(".question").click((e) => {
    $(e.target).closest(".faq-item").find(".answer").toggle("fast");

    if ($(e.target).closest(".question").find(".plus").text() == "+")
      $(e.target).closest(".question").find(".plus").text("-");
    else $(e.target).closest(".question").find(".plus").text("+");
  });
}

export function initFaqSearch() {
  $("#faqSearchInput").on("keyup", function () {
    var value = $(this)
      .val()
      .toLowerCase()
      .normalize("NFKD")
      .replace(/[^\w]/g, "");
    $("#faqList")
      .find(".faq-item")
      .filter(function () {
        $(this).toggle(
          $(this)
            .text()
            .toLowerCase()
            .normalize("NFKD")
            .replace(/[^\w]/g, "")
            .indexOf(value) > -1
        );
      });
  });
}

export function initZipCodeAutocomplete() {
  $(".cep").blur(() => {
    const cepDigits = $(".cep")
      .val()
      .replace(/[^0-9]/g, "");

    if (cepDigits.length != 8) {
      $(".cep-error").text("CEP inválido");
      $(".submit").prop("disabled", true);
      return;
    }

    fetch(`https://viacep.com.br/ws/${cepDigits}/json`)
      .then((response) => {
        response.json().then((data) => {
          if (data.erro) {
            $(".submit").prop("disabled", true);
            $(".cep-error").text("CEP inválido");
          } else {
            $(".submit").prop("disabled", false);
            $(".cep-error").text("");
            $(".state").val(data.uf);
            $(".street").val(data.logradouro);
            $(".district").val(data.bairro);
            
            if (data.localidade) {
              $(".city").val(data.localidade.toUpperCase());
            }

            publish('on_update_address', data)
          }
        });
      })
      .catch((error) => {
        $(".cep-error").text("Não foi possível verificar o CEP informado");
      });
  });
}

export function searchByEmail(searchEndpoint) {
  $(".email").blur(() => {
    const email = $(".email").val();

    fetch(`${searchEndpoint}?email=${email}`).then((response) => {
      response.json().then((data) => {
        if (data) {
          $(".name").val(data.name);
          $(".cpf").val(data.cpf);
        }
      });
    });
  });
}

export function findBInA(a, b) {
  return normalizeStr(a.toLowerCase()).includes(normalizeStr(b.toLowerCase()));
}

export function setToUpperCaseByElementId(elementId) {
  $(`#${elementId}`).keyup(function () {
    $(this).val($(this).val().toUpperCase());
  });
}

export const removeDuplicates = (items, key) => {
  if (!items) return items;

  return items.reduce((accumulator, value) => {
    if (!value) return accumulator;

    if (key) {
      const accumulatorNames = accumulator.map((obj) =>
        normalizeStr(obj[key].trim().toUpperCase())
      );
      const currentValue = normalizeStr(value[key].trim().toUpperCase());
      if (accumulatorNames.includes(currentValue)) {
        return accumulator;
      } else {
        return [...accumulator, value];
      }
    } else {
      const currentValue = normalizeStr(value.trim().toUpperCase());
      if (accumulator.includes(currentValue)) {
        return accumulator;
      } else {
        return [...accumulator, currentValue];
      }
    }
  }, []);
};

export function translateStatus(status) {
  switch (status) {
    case "active":
      return "ativo";
    case "future":
      return "Em renovação";
    case "pending_checkout":
      return "pendente";
    case "pending":
      return "pendente";
    case "canceled":
      return "cancelado";
    case "expired":
      return "expirado";
    case "pending_on_protheus":
      return "pendente no Protheus";
    case "on_canceling":
      return "Em cancelamento";
    default:
      return status;
  }
}

export function reverseTranslatedStatus(statusPTBR, isCompany) {
  switch (statusPTBR.toLowerCase()) {
    case "ativo":
      return "active";
    case "pendente":
      return isCompany ? "pending" : "pending_checkout";
    case "cancelado":
      return "canceled";
    case "expirado":
      return "expired";
    case "pendente no Protheus":
      return "pending_on_protheus";
    default:
      return statusPTBR;
  }
}

export function formatPhone(strPhone) {
  if (!strPhone) return strPhone;

  strPhone = strPhone.replace(/[^0-9]/g, "");

  if (strPhone.length === 10) {
    const ddd = strPhone.substring(0, 2);
    const part1 = strPhone.substring(2, 6);
    const part2 = strPhone.substring(6);
    return `(${ddd}) ${part1}-${part2}`;
  } else if (strPhone.length === 11) {
    const ddd = strPhone.substring(0, 2);
    const part1 = strPhone.substring(2, 7);
    const part2 = strPhone.substring(7);
    return `(${ddd}) ${part1}-${part2}`;
  } else {
    return strPhone;
  }
}

export function redirectPage(href) {
  window.location.href = href;
}

export function calculateDays(date) {
  if (!date || date.length < 10) return null;

  try {
    const formatedDate = date.substring(0, 10);
    const now = moment(new Date());
    const past = moment(formatedDate);
    const duration = moment.duration(now.diff(past));
    const days = duration.asDays();
    return Math.trunc(days);
  } catch (_ex) {
    return null;
  }
}

export function formatDateTime(dateStr) {
  if (!dateStr) return dateStr;

  const [part1, part2] = dateStr.split("T");
  const date = part1.split("-").reverse().join("/");
  const time = part2.substring(0, 5);

  return `${date} ${time}`;
}

export function formatDate(dateStr) {
  if (!dateStr) return dateStr;

  const [part1] = dateStr.split("T");
  const date = part1.split("-").reverse().join("/");

  return `${date}`;
}


export function getMonthByDate(dateStr) {
  if (!dateStr) return dateStr;

  const convertedDate = new Date(dateStr);
  const month = convertedDate.getMonth();
  const monthList = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
    "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
  const monthName = monthList[month];

  return `${monthName}`;
}


export function translateStatusCancellation(statusRequest) {
  switch (statusRequest.status) {
    case "created":
      return `A solicitação foi criada por ${statusRequest.admin.name}`;
    case "analyzing":
      return `A solicitação está sendo analisada por ${statusRequest.admin.name}`;
    case "approved":
      return `A solicitação foi aprovada por ${statusRequest.admin.name}`;
    case "refused":
      return `A solicitação foi recusada por ${statusRequest.admin.name}`;
    case "canceled":
      return `A solicitação foi cancelada por ${statusRequest.admin.name}`;
    case "completed":
      return `A solicitação foi completada por ${statusRequest.admin.name}`;
    case "refunded":
      return `A solicitação foi estornada por ${statusRequest.admin.name}`;
    case "reopened":
      return `A solicitação foi reaberta por ${statusRequest.admin.name}`;
  }
}

export function mountArrayPages(totalPage) {
  const pages = [];
  for (let i = 1; i <= totalPage; i++) {
    pages.push(i);
  }
  return pages;
}

export function limit(str, length, addEllipsis = true) {
  if (!str) return str;

  let reducedStr = str.substring(0, length);

  if (str.length > length && addEllipsis) {
    reducedStr += "...";
  }

  return reducedStr;
}

export function dateMask(value) {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1');
}
