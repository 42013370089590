import {
  formMasks,
  maskPhones,
  reverseTranslatedStatus,
  translateStatus,
  initZipCodeAutocomplete,
  goToByScroll,
  formatPhone,
  redirectPage,
  limit,
} from '../../shared/utils'
import { initPluginsSelect } from './newCompany'
import Vue from 'vue/dist/vue.esm'

const mountArrayPages = (totalPage) => {
  const pages = []
  for (let i = 1; i <= totalPage; i++) {
    pages.push(i)
  }
  return pages
}

const mountCompanyStatusClass = (companyStatus) => {
  let companyStatusClass = {}

  switch (companyStatus) {
    case 'active':
      companyStatusClass['active'] = true
      return companyStatusClass
    case 'pending':
      companyStatusClass['pending'] = true
      return companyStatusClass
    case 'canceled':
      companyStatusClass['canceled'] = true
      return companyStatusClass
    case 'expired':
      companyStatusClass['expired'] = true
      return companyStatusClass
    default:
      companyStatusClass['default'] = true
      return companyStatusClass
  }
}

const initCompaniesSearch = () => {
  if (!document.getElementById('companies-list-sales-dashboard')) return

  const app = new Vue({
    el: '#companies-list-sales-dashboard',
    data: {
      companies: [],
      search: '',
      current_page: 1,
      total_count: 0,
      total_filtered_count: 0,
      total_pages: 0,
      per_page: 20,
      pages: [],
      loading: false,
      infoMessage: '',
      debounceTimer: null,
      status: ['Todos', 'Pendente', 'Ativo', 'Cancelado', 'Expirado'],
      selectedStatus: '',
      companyPath: Routes.sales_dashboard_company_path,
    },
    computed: {},
    methods: {
      limit,
      redirectPage,

      loadCompanies() {
        this.loading = true

        const params = {
          page: this.current_page,
          search: this.search,
          per_page: this.per_page,
          status: `${
            this.selectedStatus === 'Todos'
              ? ''
              : reverseTranslatedStatus(this.selectedStatus, true)
          }`,
        }

        fetch(Routes.sales_dashboard_companies_json_path(params), {
          cache: 'no-cache',
        })
          .then((response) => response.json())
          .then((data) => {
            ;(this.loading = false), (this.infoMessage = '')
            this.companies = data.companies.map(this.formatCompany)
            this.current_page = data.page
            this.total_count = data.total_count
            this.total_filtered_count = data.total_filtered_count
            this.total_pages = data.total_pages
            this.per_page = data.per_page
            this.pages = mountArrayPages(this.total_pages)

            if (!this.companies.length) {
              this.infoMessage = 'Nenhuma empresa encontrada.'
            }
          })
          .catch((_error) => {
            this.infoMessage =
              'Erro ao listar empresas. Por favor tente novamente!'
          })
      },

      showPage(page) {
        this.current_page = page
        this.loadCompanies()
      },

      onPerPageSelect(event) {
        this.per_page = event.target.value
        this.showPage(1)
      },

      onSearch() {
        this.infoMessage = 'Carregando...'
        this.current_page = 1
        this.companies = []

        clearTimeout(this.debounceTimer)
        this.debounceTimer = setTimeout(() => {
          this.debounceTimer = null
          this.loadCompanies()
        }, 1000)
      },

      translatedCompanyStatus(status) {
        switch (status) {
          case 'active':
            return 'ativo'
          case 'pending':
            return 'pendente'
          case 'canceled':
            return 'cancelado'
          case 'expired':
            return 'expirado'
          default:
            return status
        }
      },

      formatCompany(company) {
        const companyStatus = company.status
        const companyStatusTranslated = translateStatus(companyStatus)

        return {
          id: company.id,
          name: company.name,
          trade_name: company.trade_name,
          cnpj: company.cnpj,
          status: company.status,
          total_users: company.users_count,
          total_dependants: company.dependants_count,
          companyStatusClass: mountCompanyStatusClass(company.status),
          href: this.companyPath(company),
          companyStatusTranslated,
        }
      },
    },
  })

  app.loadCompanies()
}

const initTabAnchors = () => {
  $('.tab.general-info').off()
  $('.tab.company-data').off()
  $('.tab.address').off()
  $('.tab.hr-accounts').off()
  $('.tab.company-bills').off()
  $('.tab.employees').off()

  $('.tab.general-info').click((e) => {
    $('.tab').removeClass('active')
    $('.tab.general-info').addClass('active')
    $(window).scrollTop(0)
  })

  $('.tab.company-data').click((e) => {
    $('.tab').removeClass('active')
    $('.tab.company-data').addClass('active')
    goToByScroll('#companyData', 130)
  })

  $('.tab.address').click((e) => {
    $('.tab').removeClass('active')
    $('.tab.address').addClass('active')
    goToByScroll('#addrs', 130)
  })

  $('.tab.hr-accounts').click((e) => {
    $('.tab').removeClass('active')
    $('.tab.hr-accounts').addClass('active')
    goToByScroll('#hrAccounts', 130)
  })

  $('.tab.employees').click((e) => {
    $('.tab').removeClass('active')
    $('.tab.employees').addClass('active')
    goToByScroll('#employees', 130)
  })

  $('.tab.company-bills').click((e) => {
    $('.tab').removeClass('active')
    $('.tab.company-bills').addClass('active')
    goToByScroll('#companyBills', 130)
  })
}

const initCompanyUsersSearch = (company_id) => {
  if (!document.getElementById('company-users-sales-dashboard')) return

  const app = new Vue({
    el: '#company-users-sales-dashboard',
    data: {
      users: [],
      currentPage: 1,
      totalPages: 0,
      perPage: 20,
      totalCount: 0,
      pages: [],
      selectedStatus: '',
      search: '',
      loading: false,
      infoMessage: '',
      debounceTimer: null,
      status: ['Todos', 'Pendente', 'Ativo', 'Cancelado', 'Expirado'],
      userPath: Routes.sales_dashboard_customer_path,
    },
    computed: {},
    methods: {
      limit,
      redirectPage,

      loadUsers() {
        const status =
          this.selectedStatus === 'Todos'
            ? ''
            : reverseTranslatedStatus(this.selectedStatus, false)

        const params = {
          page: this.currentPage,
          search: this.search,
          per_page: this.perPage,
          status,
        }

        const route = Routes.sales_dashboard_company_company_users_json_path(
          company_id,
          params
        )
        fetch(route, { cache: 'no-cache' })
          .then((response) => response.json())
          .then((data) => {
            this.loading = false
            this.infoMessage = ''
            this.users = data.users.map(this.formatUser)
            this.currentPage = data.page
            this.totalCount = data.total_count
            this.totalPages = data.total_pages
            this.perPage = data.perPage
            this.pages = mountArrayPages(this.totalPages)

            if (!this.users.length) {
              this.infoMessage = 'Nenhum funcionário encontrado.'
            }
          })
          .catch((_error) => {
            this.infoMessage =
              'Erro ao listar funcionários. Por favor tente novamente!'
          })
      },

      onSearch() {
        this.infoMessage = 'Carregando...'
        this.currentPage = 1
        this.companies = []

        clearTimeout(this.debounceTimer)
        this.debounceTimer = setTimeout(() => {
          this.debounceTimer = null
          this.loadUsers()
        }, 1000)
      },

      formatUser(user) {
        const {
          subscription: { status },
        } = user
        const userStatusTranslated = translateStatus(status)

        return {
          id: user.id,
          name: user.name,
          cpf: user.cpf,
          phone: formatPhone(user.phone),
          email: user.email,
          numDependants: user.dependants_count,
          userStatusClass: mountCompanyStatusClass(status),
          href: this.userPath(user),
          userStatusTranslated,
        }
      },

      showPage(page) {
        this.currentPage = page
        this.loadUsers()
      },

      onPerPageSelect(event) {
        this.perPage = event.target.value
        this.showPage(1)
      },
    },
  })

  app.loadUsers()
}

window.pages = window.pages || {}
window.pages.sales_dashboard = window.pages.sales_dashboard || {}
window.pages.sales_dashboard.companies = {
  formMasks,
  maskPhones,
  initCompaniesSearch,
  initZipCodeAutocomplete,
  initTabAnchors,
  initPluginsSelect,
  initCompanyUsersSearch,
}
