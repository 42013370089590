import Vue from 'vue/dist/vue.esm'
import { redirectPage, calculateDays } from '../../shared/utils'

export const initIndexRequests = (requestType) => {
  if (!document.getElementById('index-requests-sales-dashboard')) return

  const app = new Vue({
    el: '#index-requests-sales-dashboard',
    data: {
      requests: [],
      search: '',
      current_page: 1,
      total_filtered_count: 0,
      total_pages: 0,
      per_page: 20,
      pages: [],
      loading: false,
      infoMessage: '',
      debounceTimer: null,
      status: ['Todos', 'Pendente', 'Ativo', 'Cancelado', 'Expirado', 'Aguardando aprovação'],
      selectedStatus: '',
    },
    computed: {},
    methods: {
      redirectPage,

      loadRequests() {
        this.loading = true
        this.infoMessage = 'Carregando...'

        const params = {
          page: this.current_page,
          per_page: this.per_page,
          request_type: requestType
        }

        const api = Routes.sales_dashboard_list_requests_json_path(params)
        fetch(api, { cache: 'no-cache' })
          .then(response => response.json())
          .then(data => {
            this.loading = false
            this.infoMessage = ''
            this.requests = data.requests.map(this.formatRequest)
            this.current_page = data.page
            this.total_filtered_count = data.total_filtered_count
            this.total_pages = data.total_pages
            this.per_page = data.per_page
            this.pages = this.mountArrayPages(this.total_pages)

            if (!this.requests.length) {
              this.infoMessage = 'Nenhuma solicitação encontrada.'
            }
          })
          .catch(_error => {
            this.infoMessage = 'Erro ao listar solicitações. Por favor tente novamente!'
          })
      },

      onSearch() {
        this.infoMessage = 'Carregando...'
        this.current_page = 1
        this.requests = []

        clearTimeout(this.debounceTimer)
        this.debounceTimer = setTimeout(() => {
          this.debounceTimer = null
          this.loadRequests()
        }, 1000)
      },

      formatRequest(cancellationRequest) {
        const responsibleAdmin = cancellationRequest.responsible_admin
          ? cancellationRequest.responsible_admin.name
          : 'Nenhum'

        return {
          id: cancellationRequest.id,
          numDays: calculateDays(cancellationRequest.created_at),
          createdAt: cancellationRequest.created_at,
          formatedCreatedAt: this.formatDate(cancellationRequest.created_at),
          requestingUser: cancellationRequest.requesting_admin.name,
          cancellationType: cancellationRequest.cancellation_type,
          translatedCancellationType: this.translateCancellationType(cancellationRequest.cancellation_type),
          status: this.translateCancellationRequestStatus(cancellationRequest.status),
          href: Routes.sales_dashboard_request_path(cancellationRequest.id),
          responsibleAdmin,
        }
      },

      mountArrayPages(totalPage) {
        const pages = []
        for (let i = 1; i <= totalPage; i++) {
          pages.push(i)
        }
        return pages
      },

      formatDate(date) {
        if (!date || date.length < 10) return date

        try {
          date = date.substring(0, 10)
          date = date.split('-').reverse().join('/')
          return date
        } catch (_ex) {
          return ''
        }
      },

      translateCancellationType(cancellationType) {
        switch (cancellationType) {
          case 'with_refund':
            return 'Reembolsável'
          case 'no_refund':
            return 'Não reembolsável'
        }
      },

      translateCancellationRequestStatus(status) {
        switch (status) {
          case 'created':
            return 'Criado'
          case 'analyzing':
            return 'Analisando'
          case 'approved':
            return 'Aprovado'
          case 'refused':
            return 'Recusado'
          case 'canceled':
            return 'Cancelado'
          case 'completed':
            return 'Completado'
          case 'refunded':
            return 'Reembolsado';
        }
      },

      showPage(page) {
        this.current_page = page
        this.loadRequests()
      },

      onPerPageSelect(event) {
        this.per_page = event.target.value
        this.showPage(1)
      },
    }
  })

  app.loadRequests()
}