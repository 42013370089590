import { initFaq, goToByScroll } from '../../shared/utils'

const initScrollingOnButtonClick = () => {
  $('.want-my-card-button').on('click', () => {
    goToByScroll('#planInfo', 70)
  })

  $('.telemedicine-want-more-info').on('click', () => {
    goToByScroll('#telemedicineInfo', 70)
  })
}

const initTelemedicineCarousel = () => {
  $('.carousel-item', '.show-neighbors').each(function(){
    var next = $(this).next();
    if (! next.length) {
      next = $(this).siblings(':first');
    }
    next.children(':first-child').clone().appendTo($(this));
  }).each(function(){
    var prev = $(this).prev();
    if (! prev.length) {
      prev = $(this).siblings(':last');
    }
    prev.children(':nth-last-child(2)').clone().prependTo($(this));
  });
}

window.pages = window.pages || {}
window.pages.site = window.pages.site || {}
window.pages.site.home = {
  initFaq,
  initTelemedicineCarousel,
  initScrollingOnButtonClick
}
