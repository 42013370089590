import { formMasks, initZipCodeAutocomplete } from '../shared/utils'

const handleSubscriptionForm = (event, statusWas) => {
  if (!event) return

  const status = $('#statusSelect option:selected').text()
  const method = $('#methodSelect option:selected').text()
  const planType = $('#planTypeSelect option:selected').text()
  let text = ''

  if (statusWas === 'active' && status === 'Ativo') {
    text = `Tem certeza que deseja renovar a assinatura com as seguintes informações?\n\n` +
      `Status da assinatura: ${status}\n` +
      `Método de pagamento: ${method}\n` +
      `Tipo de plano: ${planType}\n\n` +
      `Obs.: Caso confirme a edição, a assinatura será renovada,` +
      `ficando implícito que um pagamento foi feito, mesmo que ela já esteja ativa.`
  } else if (status === 'Cancelado') {
    text = `Tem certeza que deseja cancelar a assinatura com as seguintes informações?\n\n` +
      `Status da assinatura: ${status}.\n` +
      `Método de pagamento: ${method}.\n` +
      `Tipo de plano: ${planType}.`
  } else {
    text = `As informações abaixo estão corretas?\n\n` +
      `Status da assinatura: ${status}.\n` +
      `Método de pagamento: ${method}.\n` +
      `Tipo de plano: ${planType}.\n`
  }

  if (!confirm(text)) {
    event.preventDefault()
  }
  event.stopPropagation()
}

const checkPaymentPlace = (event) => {
  const select = event.target || event
  const value = select.options[select.selectedIndex].value

  const statusSelect = document.getElementById('statusSelect')
  const methodSelect = document.getElementById('methodSelect')
  const planTypeSelect = document.getElementById('planTypeSelect')
  const vindiPlanTypeSelect = document.getElementById('vindiPlanTypeSelect')

  if (value === 'unidade') {
    statusSelect.disabled = false
    methodSelect.disabled = false
    vindiPlanTypeSelect.hidden = true
    planTypeSelect.hidden = false
  } else if (value === 'link') {
    statusSelect.value = 'pending_checkout'
    statusSelect.disabled = true
    methodSelect.disabled = true
    vindiPlanTypeSelect.hidden = false
    planTypeSelect.hidden = true
    methodSelect.value = 'credit_card'
  }
}

const selectPaymentPlaceComboBox = (hasIdOnGateway, paymentPlaceElement) => {
  if (hasIdOnGateway) {
    paymentPlaceElement.value = 'link'
  } else {
    paymentPlaceElement.value = 'unidade'
  }
}

const initSelectPaymentPlaceComboBox = (hasIdOnGateway) => {
  const paymentPlaceElement = document.getElementById('payment-place')
  if (paymentPlaceElement) {
    $(paymentPlaceElement).change(checkPaymentPlace)
    selectPaymentPlaceComboBox(hasIdOnGateway, paymentPlaceElement)
    checkPaymentPlace(paymentPlaceElement)
  }
}

window.pages = window.pages || {}
window.pages.subscriptions = {
  formMasks,
  initZipCodeAutocomplete,
  handleSubscriptionForm,
  initSelectPaymentPlaceComboBox
}
