import { subscribe } from '../shared/utils'

const initAddressForm = () => {
  subscribe('on_update_address', function (data) {
    selectCities();
    $("#city").val(data.localidade);
  })
  selectCities();
  fillCityName();

  $("#state").change(function () {
    selectCities()
  })

  function selectCities() {
    var states = JSON.parse(document.getElementById('states').value || '[]')
    if (!states.length) return

    var selectedState = $("#state").val()
    var state = states.find(function (state) { return state.shortName === selectedState })
    if (!state) return

    $("#city").empty()
    $("#city").append("<option value=\"\">Cidade</option>")
    state.cities.forEach(city => {
      $("#city").append("<option value=\"" + city + "\">" + city + "</option>")
    })
  }

  function fillCityName() {
    var cityName = $("#city").val() || document.getElementById('cityName')?.value
    if (cityName) {
      $("#city").val(cityName)
    }
  }
}

window.pages = window.pages || {}
window.pages.address = {
  initAddressForm,
}
