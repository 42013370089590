import { formMasks, maskPhones, mountArrayPages, limit } from '../../shared/utils'
import Vue from 'vue/dist/vue.esm'

const initMetlifeRemunerationsSearch = () => {
  if (!document.getElementById('remuneration-sales-dashboard-list-page')) return

  var currentDate = new Date()

  const app = new Vue({
    el: '#remuneration-sales-dashboard-list-page',
    data: {
      subscriptions: [],
      individual_count: 0,
      dependants_count: 0,
      total_potential: 0,
      individual_refused_count: 0,
      dependants_refused_count: 0,
      total_refused: 0,
      individual_accepted_count: 0,
      dependants_accepted_count: 0,
      total_accepted: 0,
      remuneration_individual_value: 0,
      remuneration_dependants_value: 0,
      total_remuneration: 0,
      search: '',
      showDatePicker: false,
      reference_date: '',
      year: currentDate.getFullYear(),
      tempYear: currentDate.getFullYear(),
      month: 0,
      tempMonth: 0,
      monthName: 0,
      current_page: 1,
      total_filtered_count: 0,
      total_pages: 0,
      per_page: 20,
      pages: [],
      loading: false,
      infoMessage: '',
      debounceTimer: null,
      metlifeStatus: 'all',
    },
    computed: {},
    methods: {
      init() {
        this.tempMonth = currentDate.getMonth() + 1
        this.month = currentDate.getMonth() + 1
        this.monthName = this.getMonthName(this.month)

        this.loadMetlifeRemuneration()
        this.loadSubscriptions()
      },

      loadSubscriptions() {
        this.loading = true

        const params = { page: this.current_page, 
                         search: this.search, 
                         per_page: this.per_page, 
                         metlife_status: this.metlifeStatus,
                        reference_date: this.reference_date }

        fetch(
          Routes.sales_dashboard_subscriptions_json_path(params),
          { cache: 'no-cache' }
        )
          .then(response => response.json())
          .then(data => {
            this.loading = false
            this.infoMessage = ''
            this.subscriptions = data.subscriptions.map(this.formatSubscription)
            this.current_page = data.page
            this.total_filtered_count = data.total_filtered_count
            this.total_pages = data.total_pages
            this.per_page = data.per_page
            this.pages = mountArrayPages(this.total_pages)

            if (!this.subscriptions.length) {
              this.infoMessage = 'Nenhuma assinatura encontrada.'
            }
          })
          .catch(_error => {
            this.infoMessage =
              'Erro ao listar assinaturas. Por favor tente novamente!'
          })
      },

      openDatePicker() {
        this.showDatePicker = true
      },

      closeDatePicker() {
        this.showDatePicker = false
      },

      loadMetlifeRemuneration() {
        this.infoMessage = 'Carregando...';

        const api = Routes.sales_dashboard_metlife_remuneration_json_path({
          reference_date: this.reference_date
        });

        fetch(api, { cache: 'no-cache' })
          .then(response => response.json())
          .then(data => {
            this.infoMessage = '';
            this.loading = false;
            this.individual_refused_count = data.individual_refused_count;
            this.dependants_refused_count = data.dependants_refused_count;
            this.total_refused = data.total_refused;
            this.individual_accepted_count = data.individual_accepted_count;
            this.dependants_accepted_count = data.dependants_accepted_count;
            this.total_accepted = data.total_accepted;
            this.individual_count = data.individual_count;
            this.dependants_count = data.dependants_count;
            this.total_potential = data.total_potential;
            this.remuneration_individual_value = data.remuneration_individual_value;
            this.remuneration_dependants_value = data.remuneration_dependants_value;
            this.total_remuneration = data.total_remuneration;
          })
          .catch(_error => {
            this.infoMessage = 'Erro ao procurar pela remuneração. Por favor tente novamente!';
          });
      },

      setMetlifeStatus(status) {
        this.metlifeStatus = status
        this.showPage(1)
      },

      showPage(page) {
        this.current_page = page
        this.loadSubscriptions()
      },

      onPerPageSelect(event) {
        this.per_page = event.target.value
        this.showPage(1)
      },

      subtracYear() {
        this.tempYear -= 1
      },

      addYear() {
        this.tempYear += 1
      },

      setMonth(month) {
        this.tempMonth = month
      },

      setReferenceDate() {
        this.month = this.tempMonth ?  this.tempMonth : this.month 
        this.year = this.tempYear ?  this.tempYear : this.year 
        this.monthName = this.getMonthName(this.month)

        this.reference_date = `${this.month}/${this.year}`

        this.loadMetlifeRemuneration()
        this.loadSubscriptions()
        this.closeDatePicker() 
      },

      onSearch() {
        this.infoMessage = 'Carregando...'
        this.current_page = 1
        this.subscriptions = []

        clearTimeout(this.debounceTimer)
        this.debounceTimer = setTimeout(() => {
          this.debounceTimer = null
          this.loadSubscriptions()
        }, 1000)
      },

      getMonthName(month) {
        switch (month) {
          case 1:
            return 'Janeiro'
          case 2:
            return 'Fevereiro'
          case 3:
            return 'Março'
          case 4:
            return 'Abril'
          case 5:
            return 'Maio'
          case 6:
            return 'Junho'
          case 7:
            return 'Julho'
          case 8:
            return 'Agosto'
          case 9:
            return 'Setembro'
          case 10:
            return 'Outubro'
          case 11:
            return 'Novembro'
          case 12:
            return 'Dezembro'
          default:
            return month
        }
      },

      translatedMetlifeKind(kind) {
        switch (kind) {
          case 'metlife_individual':
            return 'individual'
          case 'metlife_with_dependants':
            return 'familiar'
          default:
            return kind
        }
      },

      getMetlifeStatus(user) {
        if (user.metlife_insured_at && !user.metlife_error_response) return 'Aceito'
        if (user.metlife_error_response && !user.metlife_insured_at) return 'Recusado'
        if (user.metlife_canceled_at) return 'Cancelado'
        
        return 'Pendente'
      },

      formatSubscription(sub) {
        const companyName = sub.user.company ? sub.user.company.name : '-'
        const kind = this.translatedMetlifeKind(sub.metlife_plugin.kind) 
        const errorCode = sub.user.metlife_error_response
        const metlifeStatus = this.getMetlifeStatus(sub.user)
        const value = sub.metlife_value ? Number(sub.metlife_value).toFixed(2).replace(".", ",") : '0.0'

        return {
          id: sub.id,
          name: sub.user.name,
          cpf: sub.user.cpf,
          companyName: companyName,
          planType: kind,
          value: value,
          metlifeStatus: metlifeStatus,
          errorCode: errorCode
        }
      },

      limit,
    }
  })

  app.init()
}

window.pages = window.pages || {}
window.pages.sales_dashboard = window.pages.sales_dashboard || {}
window.pages.sales_dashboard.metlifeRemunerations = {
  formMasks,
  maskPhones,
  initMetlifeRemunerationsSearch,
}
