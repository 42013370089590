import { initSearch } from '../shared/utils'

const handleChangeAreas = event => {
  if (!event) return

  const select = event.target || event
  const value = select.options[select.selectedIndex].value

  if (value === 'rh') {
    $('#pages').hide()
  } else {
    $('#pages').show()
  }
}

const initForm = () => {
  const areasElement = document.getElementById('areas')
  handleChangeAreas(areasElement)
  $(areasElement).change(handleChangeAreas)
}

window.pages = window.pages || {}
window.pages.faqs = {
  initSearch,
  initForm
}
