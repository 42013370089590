import {
  formMasks,
  initSearch,
  maskPhones,
  initZipCodeAutocomplete,
  limit,
  dateMask
} from '../shared/utils'
import Vue from 'vue/dist/vue.esm'

const handlePaymentUrlCopy = () => {
  $('#btn-payment-url').click(function(event) {
    const copyText = document.getElementById('payment-url')
    copyText.select()
    copyText.setSelectionRange(0, 99999)
    document.execCommand('copy')
    event.target.textContent = 'Copiado!'

    setTimeout(() => {
      event.target.textContent = 'Copiar link'
    }, 500)
  })
}

const initUsersSearch = () => {
  if (!document.getElementById('users-search-container')) return

  const app = new Vue({
    el: '#users-search-container',
    data: {
      users: [],
      search: '',
      current_page: 1,
      total_count: 0,
      total_pages: 0,
      pages: [],
      msgInfoListUsers: '',
      debounceTimer: null
    },
    computed: {},
    methods: {
      loadUsers() {
        this.msgInfoListUsers = 'Carregando...'
        fetch(
          `${Routes.users_json_path()}?page=${this.current_page}&search=${
            this.search
          }`
        )
          .then(response => response.json())
          .then(data => {
            this.msgInfoListUsers = ''
            this.users = data.users.map(this.formatUser)
            this.current_page = data.page
            this.total_count = data.total_count
            this.total_pages = data.total_pages
            this.pages = this.mountArrayPages(this.total_pages)

            if (!this.users.length) {
              this.msgInfoListUsers = 'Nenhum usuário encontrado.'
            }
          })
          .catch(_error => {
            this.msgInfoListUsers =
              'Erro ao listar usuários. Por favor tente novamente!'
          })
      },

      mountArrayPages(totalPage) {
        const pages = []
        for (let i = 1; i <= totalPage; i++) {
          pages.push(i)
        }
        return pages
      },

      showPage(page) {
        this.current_page = page
        this.loadUsers()
      },

      onSearch() {
        this.msgInfoListUsers = 'Carregando...'
        this.current_page = 1
        this.users = []

        clearTimeout(this.debounceTimer)
        this.debounceTimer = setTimeout(() => {
          this.debounceTimer = null
          this.loadUsers()
        }, 1000)
      },

      translatedSubscriptionStatus(status) {
        switch (status) {
          case 'active':
            return 'ativo'
          case 'future':
            return 'em renovação'
          case 'pending_checkout':
            return 'pendente'
          case 'canceled':
            return 'cancelado'
          case 'expired':
            return 'expirado'
          case 'pending_on_protheus':
            return 'pendente no Protheus'  
          default:
            return status
        }
      },

      mountBadgeObject(subscriptionStatus) {
        let badgeObject = {}
        badgeObject['badge'] = true

        switch (subscriptionStatus) {
          case 'active':
            badgeObject[`${'badge-success'}`] = true
            return badgeObject
          case 'future':
            badgeObject[`${'badge-success'}`] = true
            return badgeObject
          case 'pending_checkout':
            badgeObject[`${'badge-warning'}`] = true
            return badgeObject
          case 'canceled':
            badgeObject[`${'badge-danger'}`] = true
            return badgeObject
          case 'expired':
            badgeObject[`${'badge-secondary'}`] = true
            return badgeObject
          case 'pending_on_protheus':
            badgeObject[`${'badge-warning'}`] = true
            return badgeObject  
          default:
            badgeObject[`${'badge-light'}`] = true
            return badgeObject
        }
      },

      formatUser(user) {
        const companyName = user.company ? user.company.name : ''
        const companyId = user.company ? user.company.id : ''
        const subscriptionStatus = user.subscription
          ? user.subscription.status
          : ''
        const subscriptionStatusTranslated = this.translatedSubscriptionStatus(
          subscriptionStatus
        )

        return {
          id: user.id,
          name: user.name,
          cpf: user.cpf,
          email: user.email,
          badgeObject: this.mountBadgeObject(subscriptionStatus),
          companyName,
          companyId,
          subscriptionStatus,
          subscriptionStatusTranslated
        }
      },

      limit,
    }
  })

  app.loadUsers()
}

const addDateMaskByElementId = (elementId) => {
  const element = document.getElementById(elementId);
  if (!element) return;
  element.addEventListener('keyup', function(_params) {
    element.value = dateMask(element.value);
  });
}

window.pages = window.pages || {}
window.pages.users = {
  initSearch,
  formMasks,
  maskPhones,
  initZipCodeAutocomplete,
  handlePaymentUrlCopy,
  initUsersSearch,
  addDateMaskByElementId,
}
