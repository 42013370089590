// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//= require jquery-mask-plugin
//= require toastr

require('@rails/ujs').start()
require('turbolinks').start()
require('@rails/activestorage').start()
require('channels')

import 'bootstrap'
import toastr from 'toastr'
import Routes from '../routes/index.js.erb'
import '@fortawesome/fontawesome-free/css/all.css'

// Require every page file
require('../pages/subscriptions.js')
require('../pages/users.js')
require('../pages/dependants.js')
require('../pages/partners.js')
require('../pages/leads.js')
require('../pages/admins.js')
require('../pages/userDashboard.js')
require('../pages/companies.js')
require('../pages/faqs.js')
require('../pages/address.js')

// Hotsite pages
require('../pages/site/home.js')
require('../pages/site/footer.js')
require('../pages/site/partners.js')
require('../pages/site/partnerShow.js')
require('../pages/site/leads.js')
require('../pages/site/examSpots.js')
require('../pages/site/faq.js')
require('../pages/site/checkout.js')
require('../pages/site/companyUsersActivation.js')

// Sales Dashboard pages
require('../pages/sales_dashboard/newCustomer.js')
require('../pages/sales_dashboard/listCustomers.js')
require('../pages/sales_dashboard/customers.js')
require('../pages/sales_dashboard/companies.js')
require('../pages/sales_dashboard/dependants.js')
require('../pages/sales_dashboard/sellers.js')
require('../pages/sales_dashboard/requests.js')
require('../pages/sales_dashboard/sellerGoalGroups.js')
require('../pages/sales_dashboard/navigator.js')
require('../pages/sales_dashboard/locals.js')
require('../pages/sales_dashboard/cashAccountings.js')
require('../pages/sales_dashboard/metlifeRemunerations.js')
require('../pages/sales_dashboard/selectLocal.js')
require('../pages/sales_dashboard/editSales.js')

// Admin dashboard
require('../pages/admin_dashboard/lgpdReport.js')
require('../pages/admin_dashboard/integrationErrors.js')
require('../pages/admin_dashboard/subscribers.js')
require('../pages/admin_dashboard/changeSubscriber.js')

// User dashboard
require('../pages/user_dashboard/index.js')
require('../pages/user_dashboard/newPaymentMethod.js')

// Vendor
require('../vendor/slick.min.js')
require('../vendor/cardvalidator.js')

/*global toastr*/
toastr.options = {
  "positionClass": "toast-top-right",
}

window.$ = $
window.toastr = toastr
window.Routes = Routes
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
