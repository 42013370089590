import Vue from 'vue/dist/vue.esm';
import { formatDateTime, limit, publish, subscribe } from '../../shared/utils';
import Rails from '@rails/ujs';

import TurbolinksAdapter from 'vue-turbolinks'
import { BootstrapVue } from 'bootstrap-vue'

Vue.use(TurbolinksAdapter)
Vue.use(BootstrapVue)

let _interval = null;

const initNavigatorCommands = ({ isLocalSeller, showModalToCloseCashAccounting, isLocalManager }) => {
  if (!document.getElementById('sales-dashboard-navigator-page')) return;

  const app = new Vue({
    el: '#sales-dashboard-navigator-page',
    data: {
      elementIdNotification: 'notification-box',
      notifications: [],
      hasNewNotifications: false,
      amountNewNotifications: 0,
      selectedLocalName: null,
      locals: [],
      infoMessage: '',
      search: '',
    },
    computed: {},
    methods: {
      init() {
        if (isLocalSeller || isLocalManager) {
          this.loadSelectedLocal();
        }
      
        this.loadNotifications();
        clearInterval(_interval);
        _interval = setInterval(this.loadNotifications, 60000);

        setTimeout(this.initNewClientEvent, 500);
      },

      initNewClientEvent() {
        const element = document.getElementById('new-customer-clickable');
        if (!element) return;

        element.addEventListener('click', (_event) => {
          if (showModalToCloseCashAccounting) {
            this.openCashAccountingModal();
            return;
          }

          document.getElementById('link-new-customer').click();
        });
      },

      loadNotifications() {
        fetch(Routes.sales_dashboard_list_notifications_json_path(), { cache: 'no-cache' })
          .then(response => response.json())
          .then(data => {
            this.notifications = data.notifications.map(this.formatNotification);
            this.amountNewNotifications = this.amountOfNewNotifications();
            if (!!this.amountNewNotifications) {
              this.openAlertCont();
            }
          });
      },

      loadSelectedLocal() {
        fetch(Routes.sales_dashboard_local_on_session_json_path(), { cache: 'no-cache' })
          .then(response => response.json())
          .then(data => {
            const { local, status } = data;

            if (status !== 200) return;

            this.selectedLocalName = local.name;
            document.getElementById('select-local-icon').style.display = "block";
          });
      },

      switchLocal() {
        window.location.href = Routes.sales_dashboard_select_local_path(); 
      },

      markNotifications() {
        const params = {
          method: 'POST',
          cache: 'no-cache',
          body: '',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': Rails.csrfToken(),
          },
        }

        fetch(Routes.sales_dashboard_update_seen_notifications_path(), params)
          .then(response => response.json())
          .then(_data => {
            this.closeAlertCont();
          });
      },

      formatNotification(notification) {
        let moreDetailsHref = null;
        let showDetails = false;

        if (notification.event_type === 'import_sellers_batch_status' &&
          ['success', 'error', 'notice', 'warning'].includes(notification.status)) {
          showDetails = true;
          moreDetailsHref = Routes.sales_dashboard_show_notification_path(notification.id);
        } else if (notification.event_type === "cancellation_request" && !!notification.content) {
          const { cancellation_request_id } = notification.content;
          if (!!cancellation_request_id) {
            showDetails = true;
            moreDetailsHref = Routes.sales_dashboard_request_path(cancellation_request_id);
          }
        } else if(notification.event_type === 'cash_accounting_history_report') {
          showDetails = true;
          moreDetailsHref = Routes.sales_dashboard_show_notification_path(notification.id);
        }

        return {
          id: notification.id,
          status: notification.status,
          title: notification.title,
          description: notification.description,
          eventType: notification.event_type,
          path: null,
          showDetails,
          moreDetailsHref,
          seen: notification.seen,
          dateTimeFormated: formatDateTime(notification.created_at),
          classStyle: this.createClassStyle(notification.status),
        }
      },

      amountOfNewNotifications() {
        return this.notifications.filter(n => !n.seen).length;
      },

      createClassStyle(status) {
        const classStyle = {};
        classStyle[`${status}`] = true;
        return classStyle;
      },

      openAlertCont() {
        document.getElementById('alert-cont').style.display = "flex";
      },

      closeAlertCont() {
        document.getElementById('alert-cont').style.display = "none";
      },

      openMenu() {
        document.getElementById('modal-navigator').style.display = "initial";
      },

      closeMenu() {
        document.getElementById('modal-navigator').style.display = "none";
      },

      openNotifications() {
        document.getElementById(this.elementIdNotification).style.display = "flex";

        const boxNotificationElement = document.getElementById(this.elementIdNotification)
        window.addEventListener('click', event => {
          if (event.target == boxNotificationElement) {
            this.closeNotifications();
          }
        });

        this.markNotifications();
      },

      closeNotifications() {
        document.getElementById(this.elementIdNotification).style.display = "none";
      },

      openCashAccountingModal() {
        document.getElementById('opened-cash-accounting-modal').style.display = "flex";

        const openModal = document.getElementById('opened-cash-accounting-modal')
        window.addEventListener('click', event => {
          if (event.target == openModal) {
            this.closeCashAccountingModal();
          }
        });
      },

      closeCashAccountingModal() {
        document.getElementById('opened-cash-accounting-modal').style.display = "none";
      },

      limit
    }
  });

  app.init();
}

window.pages = window.pages || {}
window.pages.sales_dashboard = window.pages.sales_dashboard || {}
window.pages.sales_dashboard.navigator = {
  initNavigatorCommands,
}