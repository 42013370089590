import { formMasks, maskPhones } from '../../shared/utils'
import { initNewCustomerPage } from './newCustomer'
import { initSubscriptionRenewal } from './subscriptionRenewal'
import { initConfirmSubscriptionRenewal } from './confirmSubscriptionRenewal'
import { initShowCustomer } from './showCustomer'
import { initFileDropArea } from './customerConfirmation'
import {
  initCustomersSearch, 
  initTabAnchors
} from './listCustomers'

window.pages = window.pages || {}
window.pages.sales_dashboard = window.pages.sales_dashboard || {}
window.pages.sales_dashboard.customers = {
  formMasks,
  maskPhones,
  initCustomersSearch,
  initNewCustomerPage,
  initTabAnchors,
  initShowCustomer,
  initFileDropArea,
  initSubscriptionRenewal,
  initConfirmSubscriptionRenewal
}
