import Vue from 'vue/dist/vue.esm';

export function initSubscriptionRenewal(currentPaymentMethod, gatewaySubs) {
  if (!document.getElementById('new-customer-sales-dashboard')) return

  const paymentMethod = gatewaySubs === "true" ? getValue('payment_method') :  currentPaymentMethod;
  const planId = getValue('plan_id');
  const plans = getPlans();
  const name = getValue('txt_name');
  const cpf = getValue('txt_cpf');
  const gender = getValue('txt_gender');
  const birthDate = getValue('txt_birth_date');
  const email = getValue('txt_email');
  const phone = getValue('txt_phone');
  const additionalInfo = getValue('txt_additional_info');
  const zipCode = getValue('txt_zip_code');
  const street = getValue('txt_street');
  const number = getValue('txt_number');
  const complement = getValue('txt_complement');
  const district = getValue('txt_district');
  const city = getValue('txt_city');
  const state = getValue('txt_state');

  function getPlans() {
    return JSON.parse(getValue('plans') || '[]').map(formatPlan);
  }

  function formatPlan(plan) {
    if (planId && plan.id == planId) {
      return { ...plan, selected: true };
    } else {
      return plan;
    }
  }

  function getValue(id) {
    return document.getElementById(id).value || null;
  }

  const app = new Vue({
    el: '#new-customer-sales-dashboard',
    data: {
      confirmingData: false,
      plans: plans,
      selectedPaymentMethod: paymentMethod,
      email: email,
      phone: phone,
      planId: planId,
      name: name,
      cpf: cpf,
      gender: gender,
      birthDate: birthDate,
      additionalInfo: additionalInfo,
      zipCode: zipCode,
      street: street,
      number: number,
      complement: complement,
      district: district,
      city: city,
      state: state,
      loading: false,
      holderNameErrors: [],
      cardCvvErrors: [],
      cardExpirationErrors: [],
      cardNumberErrors: [],
      holderName: '',
      cardExpiration: '',
      cardNumber: '',
      cardCvv: '',
    },
    computed: {},
    methods: {
      selectPlan(selectedPlan) {
        this.planId = selectedPlan.id
        this.plans = this.plans.map(plan => {
          if (selectedPlan.id === plan.id) {
            return { ...plan, selected: true }
          }
          return { ...plan, selected: false }
        })
      },

      selectFirstPlan() {
        if (this.plans.length > 1 && this.planId == null) {
          const plan = plans[0]
          plan.selected = true
          this.planId = plan.id
        }
      },

      getSelectedPlan() {
        if (!this.planId) return null;
        return this.plans.find(p => p.id === this.planId);
      },

      backStep() {
        this.confirmingData = false;
      },

      nextStep() {
        document.getElementById('renewal-form').submit();
        this.confirmingData = true;
      },

      handleSubmit() {
        // document.getElementById('renewal-form').submit();
        return;
      }
    }
  })

  app.selectFirstPlan()
}
