import Vue from 'vue/dist/vue.esm'
import Rails from '@rails/ujs'
import { calculateDays, translateStatusCancellation } from '../../shared/utils'

export const initShowCustomer = (customerId, currentAdminId) => {
  if (!document.getElementById('show-customer')) return

  const app = new Vue({
    el: '#show-customer',
    data: {
      showCancellationModal: false,
      requestType: 'with_refund',
      description: '',
      completedRequest: false,
      txtComment: '',
      cancellationRequests: [],
      interval: null,
      resquesting: false,
    },
    computed: {},
    methods: {
      translateStatusCancellation,

      openRequestCancellationModal() {
        this.showCancellationModal = true
        this.requestType = 'with_refund'
        this.description = ''
        this.completedRequest = false

        const modal = document.getElementById('cancellation_request_modal')
        window.addEventListener('click', event => {
          if (event.target == modal) {
            this.closeRequestCancellationModal()
          }
        })
      },

      closeRequestCancellationModal() {
        this.showCancellationModal = false
      },

      finishRequestCancellation() {
        this.closeRequestCancellationModal()
        this.listCancellationRequests()
      },

      requestCancellation() {
        if (this.resquesting) return;

        this.resquesting = true;

        const body = {
          cancellation_type: this.requestType,
          description: this.description,
        }

        const params = {
          method: 'POST',
          cache: 'no-cache',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': Rails.csrfToken(),
          },
        }

        fetch(Routes.sales_dashboard_create_cancellation_request_json_path(customerId), params)
          .then(response => response.json())
          .then(_data => {
            this.resquesting = false;
            this.completedRequest = true;
          })
          .catch(_error => {
            this.resquesting = false;
          });
      },

      cancelRequest({ id }) {
        if (!id) return

        const params = {
          method: 'DELETE',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': Rails.csrfToken(),
          },
        }

        const api = Routes.sales_dashboard_inactivate_cancellation_request_json_path(customerId, id)
        fetch(api, params)
          .then(response => response.json())
          .then(_data => {
            this.listCancellationRequests()
          })
      },

      openAccordionCancellationRequest(cancellationRequest) {
        cancellationRequest.open = !cancellationRequest.open
        if (cancellationRequest.open) {
          this.listComments(cancellationRequest.id)
          this.listStatus(cancellationRequest.id)
          this.refreshCommentsAndStatus()
        }
      },

      refreshCommentsAndStatus() {
        clearInterval(this.interval)
        this.interval = setInterval(() => {
          this.cancellationRequests.forEach((cr) => {
            if (cr.open) {
              this.listComments(cr.id)
              this.listStatus(cr.id)
            }
          })
        }, 60000)
      },

      comment(cancellationRequest) {
        if (!this.txtComment.trim() || !cancellationRequest) return;

        const body = {
          comment: this.txtComment,
        }

        const params = {
          method: 'POST',
          cache: 'no-cache',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': Rails.csrfToken(),
          },
        }

        const api = Routes.sales_dashboard_comentate_cancellation_request_json_path(
          customerId,
          cancellationRequest.id
        )
        fetch(api, params)
          .then(response => response.json())
          .then(data => {
            this.cancellationRequests = this.cancellationRequests.map(cr => {
              if (cancellationRequest.id === cr.id) {
                const comments = cr.comments
                comments.push(this.formatComment(data.comment))
                return { ...cr, comments }
              } else {
                return cr
              }
            })
          })

        this.txtComment = ''
      },

      listCancellationRequests() {
        fetch(Routes.sales_dashboard_list_cancellation_requests_json_path(customerId), { cache: 'no-cache' })
          .then(response => response.json())
          .then(data => {
            this.cancellationRequests = data.cancellation_requests.map(this.formatCancellationRequests)
          })
      },

      listStatus(cancellationRequestId) {
        const api = Routes.sales_dashboard_list_cancellation_request_statuses_json_path(
          customerId,
          cancellationRequestId
        )
        fetch(api, { cache: 'no-cache' })
          .then(response => response.json())
          .then(data => {
            this.cancellationRequests = this.cancellationRequests.map(cr => {
              if (cr.id === cancellationRequestId) {
                const statusRequests = data.status_requests.map(this.formatStatus)
                return {
                  ...cr,
                  statusRequests
                }
              } else {
                return cr;
              }
            })
          })
      },

      listComments(cancellationRequestId) {
        const api = Routes.sales_dashboard_list_cancellation_request_comments_json_path(
          customerId,
          cancellationRequestId
        )
        fetch(api, { cache: 'no-cache' })
          .then(response => response.json())
          .then(data => {
            this.cancellationRequests = this.cancellationRequests.map(cr => {
              if (cr.id === cancellationRequestId) {
                const comments = data.comments.map(this.formatComment)
                return {
                  ...cr,
                  comments
                }
              } else {
                return cr;
              }
            })
          })
      },

      formatCancellationRequests(cr) {
        return {
          id: cr.id,
          statusRequests: [],
          comments: [],
          description: cr.description,
          status: cr.status,
          type: cr.cancellation_type,
          open: false,
          statusClass: this.mountStatusClass(cr.status, cr.cancellation_type),
          statusIconCheck: this.selectStatusIconCheck(cr.status, cr.cancellation_type),
          title: this.getStatusCancellationRequest(cr.status, cr.cancellation_type),
        }
      },

      selectStatusIconCheck(status, type) {
        if (['active', 'created'].includes(status)) {
          return 'success';
        } else if (['approved'].includes(status)) {
          if (type == 'with_refund') {
            return 'warning';
          } else {
            return 'success';
          }
        } else if (['refused', 'canceled'].includes(status)) {
          return 'error';
        } else if (['analyzing'].includes(status)) {
          return 'warning';
        } else {
          return 'success';
        }
      },

      mountStatusClass(status, type) {
        const cssClass = {};

        switch (status) {
          case 'analyzing':
            cssClass['analyzing'] = true;
            break;
          case 'refused':
            cssClass['refused'] = true;
            break;
          case 'canceled':
            cssClass['canceled'] = true;
            break;
          case 'approved':
            if (type == 'with_refund') {
              cssClass['analyzing'] = true;
            } else {
              cssClass['active'] = true;
            }
            break;
          default:
            cssClass['active'] = true;
        }

        return cssClass;
      },

      getStatusCancellationRequest(status, type) {
        if (['created'].includes(status)) {
          return 'Solicitação está aguardando ser analisado'
        } else if (['analyzing'].includes(status)) {
          return 'Solicitação está sendo analisada'
        } else if (['canceled'].includes(status)) {
          return 'Solicitação cancelada'
        } else if (['approved'].includes(status)) {
          if (type == 'with_refund') {
            return 'Solicitação aguardando ser aprovada pela area do financeiro';
          } else {
            return 'Solicitação aprovada';
          }
        } else if (['refused'].includes(status)) {
          return 'Solicitação recusada'
        } else if (['completed'].includes(status)) {
          return 'Solicitação finalizada'
        } else if (['refunded'].includes(status)) {
          return 'Solicitação estornada'
        } else if (['reopened'].includes(status)) {
          return 'Solicitação reaberta'
        }
      },

      formatComment(comment) {
        return {
          comment: comment.comment,
          adminName: comment.admin.name,
          myComment: (comment.admin.id == currentAdminId)
        }
      },

      formatStatus(statusRequest) {
        return {
          title: this.translateStatusCancellation(statusRequest),
          status: statusRequest.status,
          description: statusRequest.description,
          adminName: statusRequest.admin.name,
          numDays: calculateDays(statusRequest.created_at),
        }
      },

      isCancellationRequestActive(cancellationRequest) {
        return ['created', 'analyzing', 'reopened'].includes(cancellationRequest.status)
      },
    }
  })

  app.listCancellationRequests()
}