const openMenu = () => {
  $('#modalMenuUsers').show()
}

const closeMenu = () => {
  $('#modalMenuUsers').hide()
}

const getInitials = name => {
  if (!name) {
    return ''
  }

  const parts = `${name}`
    .toUpperCase()
    .split(' ')
    .filter(p => !!p)
    .map(p => p.substr(0, 1))

  if (parts && parts.length > 1) {
    return parts[0] + parts[1]
  } else {
    return parts[0]
  }
}

const modifyInitialName = value => {
  if (value) {
    $('#logo-img').text(getInitials(value))
    $('#box-img-profile').show()
  } else {
    $('#logo-img').text('')
  }
}

const initNewDependant = () => {
  $('#box-img-profile').hide()

  $('#input-name-dependant').off()
  $('#input-name-dependant').on('keyup', event => {
    modifyInitialName(event.target.value)
  })
}

const openModal = modal => (modal.style.display = 'flex')

const closeModal = modal => (modal.style.display = 'none')

const closeModalConfirm = modalId => {
  const modal = document.getElementById(modalId)
  closeModal(modal)
}

const openModalConfirmAdd = () => {
  const modal = document.getElementById('modalCreateNewPaymentMethod')
  openModal(modal)
  window.addEventListener('click', event => {
    if (event.target == modal) {
      closeModal(modal)
    }
  })
}

const btnConfirmAdd = () => {
  closeModal(document.getElementById('modalCreateNewPaymentMethod'))
  document.getElementById('btn_modal_confirm').click()
}

const openModalConfirmRemove = paymentId => {
  const modal = document.getElementById('modalDeletePaymentMethod')
  openModal(modal)
  window.addEventListener('click', event => {
    if (event.target == modal) {
      closeModal(modal)
    }
  })

  const btnDelete = $('#btnDelete')
  const [part1, part2, part3] = btnDelete.attr('href').split('/')
  const newUrl = [part1, part2, part3, paymentId].join('/')
  btnDelete.attr('href', newUrl)
}

const showFullScreenCard = () => {
  $('.full-screen-card-panel').css('display', 'flex')
}

const hideFullScreenCard = () => {
  $('.full-screen-card-panel').css('display', 'none')
}

const openDependantExclusionConfirmationModal = (id) => {
  const modal = document.getElementById(`dependantExclusionConfirmationModal${id}`)
  openModal(modal)
  window.addEventListener('click', event => {
    if (event.target == modal) {
      closeModal(modal)
    }
  })
}

const openDependantCreateLoginConfirmationModal = (id) => {
  const modal = document.getElementById(`dependantCreateLoginConfirmationModal${id}`)
  openModal(modal)
  window.addEventListener('click', event => {
    if (event.target == modal) {
      closeModal(modal)
    }
  })
}

const initDependantsPopUp = () => {
  $('#dismissPopUp').on('change', function () {
    let checkedDismiss = $('input:checked').length > 0
    if (checkedDismiss) {
      sessionStorage.setItem('popUpDismissed', checkedDismiss)
    } else {
      sessionStorage.removeItem('popUpDismissed')
    }
  })
}

const verifyPopUpDismissed = () => {
  if (!sessionStorage.getItem('popUpDismissed')) {
    $('#dependantsModal').modal('show')
  }
}

const clearSessionStorageOnLogout = () => {
  $('#logoutButton').on('click', function () {
    sessionStorage.clear()
  })
}

window.pages = window.pages || {}
window.pages.userDashboard = {
  openMenu,
  closeMenu,
  initNewDependant,
  openModalConfirmAdd,
  closeModalConfirm,
  openModalConfirmRemove,
  btnConfirmAdd,
  showFullScreenCard,
  hideFullScreenCard,
  openDependantExclusionConfirmationModal,
  openDependantCreateLoginConfirmationModal,
  initDependantsPopUp,
  verifyPopUpDismissed,
  clearSessionStorageOnLogout
}
