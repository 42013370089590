import Vue from 'vue/dist/vue.esm'
import Rails from '@rails/ujs';

const initChangeSubscriberSearch = () => {
  if (!document.getElementById('change-subscriber-container')) return

  const app = new Vue({
    el: '#change-subscriber-container',
    data: {
      cpfSubscriber: '',
      cpfDependant: '',
      message: ''
    },
    computed: {},
    methods: {
      changeSubscriber() {
        this.message = 'Carregando...'

        const params = {
          cpfSubscriber: this.cpfSubscriber,
          cpfDependant: this.cpfDependant,
        }
        fetch(Routes.admin_dashboard_change_subscriber_to_dependent_path(), {
          method: 'POST',
          body: JSON.stringify(params),
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': Rails.csrfToken(),
          },
        }).then((response) => {
          response.json().then((data) => {
            this.message = data.message;
            this.cpfSubscriber = ''
            this.cpfDependant = ''
          })
        })
      },
    }
  })
}

window.pages = window.pages || {}
window.pages.admin_dashboard = window.pages.admin_dashboard || {}
window.pages.admin_dashboard.changeSubscriber = {
  initChangeSubscriberSearch,
}
