import 'select2';

export const initCompanySelect2 = () => {
  let company_id_select = $('#user_company_id');
  company_id_select.select2();
  $(document).on('mouseenter', '.select2-selection__rendered', function () {
    $('.select2-selection__rendered').removeAttr('title');
  });

  if (company_id_select.attr('disabled')){
    $(".select2-container").tooltip(
      {
        'placement': "right",
        'title': "Não é possível editar empresa enquanto a assinatura está ativa."
      }
    );
  }
}

window.pages = window.pages || {};
window.pages.admin_dashboard = window.pages.admin_dashboard || {};
window.pages.admin_dashboard.subscribers = {
  initCompanySelect2
};
