import Vue from 'vue/dist/vue.esm'
import { BootstrapVue } from 'bootstrap-vue'

import TurbolinksAdapter from 'vue-turbolinks'

Vue.use(TurbolinksAdapter)
Vue.use(BootstrapVue)

const initLocals = (regions) => {
  if (!document.getElementById('edit-sale')) return
  
  const app = new Vue({
    el: '#edit-sale',
    data: {
      locals: [],
      regions: [],
      regionName: '',
      selectedLocalId: null,
      selectedRegion: null,
      localsOptions: [],
      selectedPaymentMethod: null,
      machineCode: null,
      cardHolderName: null,
      showSaleForm: false,
      showMachineDataForm: false,
      sellerSearchInput: '',
      showSellersPopover: false,
      debounceTimer: null,
      loading: false,
      infoMessage: '',
      sellers: [],
      selectedSeller: { value: null, text: 'Selecionar vendedor' },
      selectedSellerId: null,
    },
    methods: {
      init(){
        this.regions = JSON.parse(regions.replace(/&quot;/g,'"'));
        this.regions = this.regions.map((region, index) => {
          return { value: index, text: region }
        })
      },

      loadLocals() {
        fetch(
          Routes.sales_dashboard_locals_by_region_json_path({ region_name: this.regionName }),
          { cache: 'no-cache' }
        )
          .then(response => response.json())
          .then(data => {
            if (data.locals) {
              this.locals = data.locals.map(this.formatLocal)
            } else {
              this.selectedLocalId = null
            }
          })
          .catch(error => {
            this.infoMessage =
              'Erro ao listar unidades. Por favor tente novamente!'
          })
      },

      changeShowSaleForm() {
        this.selectedLocalId = null  
        this.selectedRegion = null  
        this.selectedSellerId = null
        this.showSaleForm = !this.showSaleForm
      },

      changeShowMachineDataForm() {
        this.machineCode = null
        this.cardHolderName = null
        this.selectedPaymentMethod = null
        this.showMachineDataForm = !this.showMachineDataForm
      },

      onSearch() {
        this.infoMessage = 'Carregando...'
        this.sellers = [];

        clearTimeout(this.debounceTimer)
        this.debounceTimer = setTimeout(() => {
          this.debounceTimer = null
          this.loadSellersBySearch();
        }, 1000)
      },
      
      loadSellersBySearch() {
        this.loading = true
        const params = { search: this.sellerSearchInput }

        fetch(
          Routes.sales_dashboard_sellers_by_search_json_path(params),
          { cache: 'no-cache' }
        )
          .then(response => response.json())
          .then(data => {
            this.loading = false,
            this.infoMessage = ''
            this.sellers = data.sellers.map(this.formatSeller)            
     
            if (!this.sellers.length) {
              this.infoMessage = 'Nenhum vendedor encontrado.'
            }
          })
          .catch(_error => {
            this.infoMessage =
              'Erro ao listar vendedores. Por favor tente novamente!'
          })
      },
      
      formatSeller(seller) {
        return {
          value: seller.id,
          text: seller.name
        }
      },

      closeSellersPopover() {
        this.showSellersPopover = false
        this.selectedSeller = this.sellers.find(seller => seller.value == this.selectedSellerId);
        this.sellers = []
        this.sellerSearchInput = ''
      },

      setRegion(value) {
        this.locals = []
        this.regionName = value
        
        this.loadLocals()
      },

      formatLocal(local) {
        return {
          value: local.id,
          text: local.name
        }
      },

      resetSeller() {
        this.sellers = []
        this.sellerSearchInput = ''
        this.selectedSeller = { value: null, text: 'Selecionar vendedor' }
        this.selectedSellerId = null
        this.showSellersPopover = false
      },

      clearMachineFields() {
        if (this.selectedPaymentMethod != 'cash') return 

        this.cardHolderName = null
        this.machineCode = null
      },
    }
  })
  
  app.init()
}

window.pages = window.pages || {}
window.pages.sales_dashboard = window.pages.sales_dashboard || {}
window.pages.sales_dashboard.editSales = {
  initLocals
}
