import { formMasks, initZipCodeAutocomplete, subscribe, publish } from '../../shared/utils';
import ProgressBar from '../../vendor/progressbar.js';
import Rails from '@rails/ujs';
import Vue from 'vue/dist/vue.esm';
import Base64 from 'base-64';

const startCardValidator = () => {
  $('.card-number').validateCreditCard(
    (result) => {
      $('.card-brand').removeClass('valid')
      $('.card-brand').removeClass('invalid')
      $('.card-brand').addClass('disabled')

      // in this case we have to re-mask the fields because they lose the mask
      $('.card-expiration').mask('00/0000')
      $('.card-number').mask('0000 0000 0000 ZZZZ', {
        translation: { Z: { pattern: /[0-9]/, optional: true } }
      })
      $('.cvv').mask('00ZZ', {
        translation: { Z: { pattern: /[0-9]/, optional: true } }
      })

      if (result.card_type && result.card_type.name) {
        $(`.${result.card_type.name}`).removeClass('disabled')
        $('#payment-company-select').val(result.card_type.name)

        if (result.length_valid && result.luhn_valid) {
          $(`.${result.card_type.name}`).addClass('valid')
          $(`.${result.card_type.name}`).removeClass('invalid')
        } else {
          $(`.${result.card_type.name}`).addClass('invalid')
          $(`.${result.card_type.name}`).removeClass('valid')
        }
      }
    },
    {
      accept: [
        'visa',
        'mastercard',
        'elo',
        'diners_club',
        'american_express',
        'hipercard'
      ]
    }
  )
}

const selectPlan = (element, plan_id) => {
  if (!element && !plan_id) return

  $('.plan-option').each(function () {
    if ($(this).attr('class').includes('plan-checked')) {
      $(this).removeClass('plan-checked')
      $(this).addClass('plan-unchecked')
    }
  })

  $(`#radio_button_${plan_id}`).prop('checked', true)
  $(element).removeClass('plan-unchecked')
  $(element).addClass('plan-checked')
}

const initCheckoutStepsCircle = (step) => {
  var bar = new ProgressBar.Circle(stepcircle, {
    strokeWidth: 7,
    easing: 'easeInOut',
    text: {
      value: `${step} de 4`,
      className: 'progressbar-text'
    },
    duration: 1400,
    color: '#65A427',
    trailColor: '#eee',
    trailWidth: 5,
    svgStyle: null
  })

  bar.animate(step / 4) // Number from 0.0 to 1.0
}

const initPlanSelector = () => {
  if (!document.getElementById('select-plan-container')) return;

  const app = new Vue({
    el: '#select-plan-container',
    data: {
      plans: [],
      plan: {},
      loading: false,
    },
    computed: {},
    methods: {
      init() {
        this.loadPlans();
        this.getChosenPlanSession();
        window.addEventListener('click', event => {
          const element = event.target;
          const containerModal = document.getElementById('checkout-chosen-plan-modal');
          const closeModal = document.getElementById('close-modal-choose-plan');
          if (element == containerModal || element == closeModal) {
            this.closeModal();
          }
        });
      },

      openModal() {
        this.cleanSelectedPlans();
        $('#checkout-chosen-plan-modal').css('display', 'flex');
      },

      closeModal() {
        $('#checkout-chosen-plan-modal').css('display', 'none');
      },

      cleanSelectedPlans() {
        this.plans = this.plans.map((plan) => {
          if (this.plan && this.plan.id === plan.id) {
            return { ...plan, selected: true };
          } else {
            return { ...plan, selected: false };
          }
        });
      },

      loadPlans() {
        const value = document.getElementById('plans').value;
        const plans = JSON.parse(value || '[]').map(plan => {
          const isPlus = plan.code === 'cartao-mais-anual';
          const price = Number(plan.total_price || '').toFixed(2);
          const value = (price / plan.interval_count).toFixed(2);
          const selected = false;
          return { ...plan, selected, isPlus, value }
        });
        this.plans = [...plans];
      },

      selectPlan(planSelected) {
        if (!planSelected) return;
        this.plans = [...this.plans].map((plan) => {
          if (plan.code === planSelected.code) {
            plan.selected = true;
          } else {
            plan.selected = false;
          }
          return plan;
        });
      },

      getChosenPlanSession() {
        const params = {
          method: 'GET',
          headers: {
            'X-CSRF-Token': Rails.csrfToken(),
            'Content-Type': 'application/json',
          },
          cache: 'no-cache',
        };

        this.loading = true;
        fetch(Routes.get_chosen_plan_session_path(), params)
          .then((response) => response.json())
          .then((data) => {
            this.loading = false;
            if (!!data) {
              this.plan = this.setInfoValues(data);
              publish('confirm_plan', data);
            }
          })
          .catch((_error) => {
            this.loading = false;
          });
      },

      setInfoValues(plan) {
        if (!plan) return plan;

        const [planItem] = plan.plan_items;
        if (!planItem) return plan;

        const price = Number(planItem.pricing_schema.price || '').toFixed(2);
        const pricePerMonth = (price / plan.interval_count).toFixed(2);
        plan.totalPrice = price;
        plan.pricePerMonth = pricePerMonth;
        return plan;
      },

      confirmPlan() {
        if (this.loading) return;

        const [plan] = this.plans.filter(plan => plan.selected);
        if (!plan) return;

        const params = {
          method: 'POST',
          headers: {
            'X-CSRF-Token': Rails.csrfToken(),
            'Content-Type': 'application/json'
          },
          cache: 'no-cache'
        };

        this.loading = true;
        fetch(Routes.choose_plan_path({ plan_id: plan.id }), params)
          .then((response) => response.json())
          .then((_data) => {
            this.loading = false;
            this.getChosenPlanSession();
            this.closeModal();
          })
          .catch((_error) => {
            this.loading = false;
          })
      },
    }
  });
  app.init();
}

const initAcceptTermsLock = () => {
  $(document).on('change', '#accept-terms', function () {
    if (this.checked) {
      $('.submit-form-btn').removeAttr('disabled')
    } else {
      $('.submit-form-btn').prop('disabled', true);
    }
    $('#acceptance').prop('checked', this.checked);
  })

  $(document).on('change', '#acceptance', function () {
    if (this.checked) {
      $('.submit-form-btn').removeAttr('disabled');
    } else {
      $('.submit-form-btn').prop('disabled', true);
    }
    $('#accept-terms').prop('checked', this.checked);
  })
}

const openContractPopUp = () => {
  $(document).on('click', '#contractPopUp', function (event) {
    $('#dependantsModal').modal('show');
  });
}

const initAcceptancePopUp = () => {
  $('#dismissPopUp').on('change', function () {
    let checkedDismiss = $('input:checked').length > 0
    if (checkedDismiss) {
      sessionStorage.setItem('popUpDismissed', checkedDismiss)
    } else {
      sessionStorage.removeItem('popUpDismissed');
    }
  })
}

const chooseContract = () => {
  subscribe('confirm_plan', (data) => {
    if (data && data.code === 'cartao-mais-anual') {
      $('#contract-cartao-alianca-plus').css('display', 'flex');
      $('#contract-cartao-alianca').css('display', 'none');
    } else {
      $('#contract-cartao-alianca-plus').css('display', 'none');
      $('#contract-cartao-alianca').css('display', 'flex');
    }
  });
}

const handleFillPaymentInfo = (event, gatewayPublicKey, gatewayPublicApi) => {
  if (!event) return;

  event.preventDefault();
  clearErrorFields();

  if (!verifyFieldsToGateway()) return;

  const params = {
    method: 'POST',
    cache: 'no-cache',
    body: JSON.stringify(getPaymentParams()),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Basic ${Base64.encode(`${gatewayPublicKey}:`)}`,
    },
  }

  document.getElementById("btn-submit").value = 'Carregando...';
  fetch(gatewayPublicApi, params)
    .then(response => response.json())
    .then(data => {
      if (data.errors && data.errors.length) {
        setErrors(data.errors);
        document.getElementById("btn-submit").value = 'Continuar';
      } else if (data.payment_profile && data.payment_profile.gateway_token) {
        document.getElementById('gateway_token').value = data.payment_profile.gateway_token;
        document.getElementById('submit-form-btn').click();
      } else {
        document.getElementById("btn-submit").value = 'Continuar';
      }
    })
    .catch(_error => {
      document.getElementById("btn-submit").value = 'Continuar';
    });
}

  const verifyFieldsToGateway = () => {
    let fieldsCorrect = true;
    let errorMsg = "Campo obrigatório!";
    const paymentParams = getPaymentParams();

    if (!paymentParams.card_number) {
      document.getElementById('card_number_error').textContent = errorMsg
      fieldsCorrect = false;
    }

    if (!paymentParams.holder_name) {
      document.getElementById('holder_name_error').textContent = errorMsg
      fieldsCorrect = false;
    }

    if (!paymentParams.card_expiration) {
      document.getElementById('card_expiration_error').textContent = errorMsg
      fieldsCorrect = false;
    }

    if (!paymentParams.card_cvv) {
      document.getElementById('card_cvv_error').textContent = errorMsg
      fieldsCorrect = false;
    }
    
    return fieldsCorrect;
  }  

const setErrors = (errors) => {
  const holderNameErrors = errors.filter(err => err.parameter === 'holder_name');
  if (holderNameErrors.length) {
    document.getElementById('holder_name_error').textContent = holderNameErrors[0].message;
  }

  const cardCvvErrors = errors.filter(err => err.parameter === 'card_cvv');
  if (cardCvvErrors.length) {
    document.getElementById('card_cvv_error').textContent = cardCvvErrors[0].message;
  }

  const cardExpirationErrors = errors.filter(err => err.parameter === 'card_expiration');
  if (cardExpirationErrors.length) {
    document.getElementById('card_expiration_error').textContent = cardExpirationErrors[0].message;
  }

  const cardNumberErrors = errors.filter(err => err.parameter === 'card_number');
  if (cardNumberErrors.length) {
    document.getElementById('card_number_error').textContent = cardNumberErrors[0].message;
  }
}

const clearErrorFields = () => {
  document.getElementById('holder_name_error').textContent = '';
  document.getElementById('card_cvv_error').textContent = '';
  document.getElementById('card_expiration_error').textContent = '';
  document.getElementById('card_number_error').textContent = '';
}

const getPaymentParams = () => {
  const cardNumber = document.getElementById('payment_profile_card_number').value;
  const holderName = document.getElementById('payment_profile_holder_name').value;
  const cardExpiration = document.getElementById('payment_profile_card_expiration').value;
  const cardCvv = document.getElementById('payment_profile_card_cvv').value;
  const paymentMethodCode = document.getElementById('payment-company-select').value;

  return {
    holder_name: holderName,
    card_expiration: cardExpiration,
    card_number: cardNumber?.replace(/ /g, ''),
    card_cvv: cardCvv,
    payment_method_code: "credit_card",
    payment_company_code: paymentMethodCode,
  }
}

window.pages = window.pages || {}
window.pages.site = window.pages.site || {}
window.pages.site.checkout = {
  formMasks,
  selectPlan,
  startCardValidator,
  initCheckoutStepsCircle,
  initZipCodeAutocomplete,
  initPlanSelector,
  initAcceptTermsLock,
  initAcceptancePopUp,
  openContractPopUp,
  subscribe,
  chooseContract,
  handleFillPaymentInfo,
}
