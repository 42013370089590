import { formMasks, redirectPage, limit } from '../../shared/utils'
import Vue from 'vue/dist/vue.esm'

const initLocalsSearch = () => {
  if (!document.getElementById('locals-list-sales-dashboard')) return

  const app = new Vue({
    el: '#locals-list-sales-dashboard',
    data: {
      locals: [],
      search: '',
      current_page: 1,
      total_count: 0,
      total_filtered_count: 0,
      total_pages: 0,
      per_page: 20,
      pages: [],
      loading: false,
      infoMessage: '',
      localPath: Routes.sales_dashboard_local_path
    },
    methods: {
      loadLocals() {
        this.loading = true
        const params = { page: this.current_page, 
                         search: this.search, 
                         per_page: this.per_page }
        fetch(
          Routes.sales_dashboard_locals_json_path(params),
          { cache: 'no-cache' }
        )
          .then(response => response.json())
          .then(data => {
            this.loading = false,
            this.infoMessage = ''
            this.locals = data.locals.map(this.formatLocal)
            this.current_page = data.page
            this.total_count = data.total_count
            this.total_filtered_count = data.total_filtered_count
            this.total_pages = data.total_pages
            this.per_page = data.per_page
            this.pages = this.mountArrayPages(this.total_pages)
     
            if (!this.locals.length) {
              this.infoMessage = 'Nenhuma unidade encontrado.'
            }
          })
          .catch(error => {
            this.infoMessage =
              'Erro ao listar localais. Por favor tente novamente!'
          })
      },

      redirectPage,

      mountArrayPages(totalPage) {
        const pages = []
        for (let i = 1; i <= totalPage; i++) {
          pages.push(i)
        }
        return pages
      },

      showPage(page) {
        this.current_page = page
        this.loadLocals()
      },

      onPerPageSelect(event) {
        this.per_page = event.target.value
        this.showPage(1)
      },

      onSearch() {
        this.infoMessage = 'Carregando...'
        this.current_page = 1
        this.locals = []

        clearTimeout(this.debounceTimer)
        this.debounceTimer = setTimeout(() => {
          this.debounceTimer = null
          this.loadLocals()
        }, 1000)
      },

      formatLocal(local) {
        const href = this.localPath(local.id)

        return {
          id: local.id,
          name: local.name,
          brandName: local.brand_name,
          cnpj: local.cnpj,
          billingCnpj: local.billing_cnpj,
          regionName: local.region.name,
          href
        }
      },

      limit
    }
  })

  app.loadLocals()
}

window.pages = window.pages || {}
window.pages.sales_dashboard = window.pages.sales_dashboard || {}
window.pages.sales_dashboard.locals = {
  formMasks,
  initLocalsSearch
}
