import { publish } from '../../shared/utils';
import Vue from 'vue/dist/vue.esm';
 
import TurbolinksAdapter from 'vue-turbolinks';

Vue.use(TurbolinksAdapter);

export const initSelectLocals = (regionName, isLocalManager) => {
  if (!document.getElementById('select-local-page')) return;

  const app = new Vue({
    el: '#select-local-page',
    data: {
      search: '',
      loading: false,
      infoMessage: "",
      locals: [],
      debounceTimer: null,
      isLocalManager: isLocalManager
    },
    methods: {
      init() {
        this.loadLocals();
      },

      onSearch() {
        this.infoMessage = 'Carregando...';
        this.currentPage = 1;
        this.locals = [];

        clearTimeout(this.debounceTimer);
        this.debounceTimer = setTimeout(() => {
          this.debounceTimer = null;
          this.init();
        }, 1000);
      },

      setLocal(localId) {
        const api = Routes.sales_dashboard_set_local_on_session_json_path({ local_id: localId })
        fetch(api, { cache: 'no-cache' })
          .then(response => response.json())
          .then(data => {
            const { local, status } = data;
            if (status !== 201) return;

            window.location.href = Routes.sales_dashboard_path(); 
            publish('change-local-id', local);
          });
      },

      loadLocals() {
        this.infoMessage = 'Carregando...';

        const params = {
          region_name: regionName,
          search: this.search
        };

        fetch(Routes.sales_dashboard_locals_by_search_json_path(params), { cache: 'no-cache' })
          .then(response => response.json())
          .then(localsData => {
            this.infoMessage = '';

            if (localsData.locals.length) {
              this.locals = localsData.locals;
            } else {
              this.infoMessage = 'Nenhuma unidade encontrada.';
            }
          })
          .catch((_error) => {
            this.infoMessage = 'Erro ao listar às unidades. Por favor tente novamente!';
          });
      },
    }
  })

  app.init()
}

window.pages = window.pages || {}
window.pages.sales_dashboard = window.pages.sales_dashboard || {}
window.pages.sales_dashboard.selectLocal = {
  initSelectLocals
}