import Vue from 'vue/dist/vue.esm';
import { limit, formatDateTime, redirectPage } from '../../shared/utils';
import Rails from '@rails/ujs';

export const initShowCashAccounting = (cashAccountingId, isLocalManager, regionName) => {
  if (!document.getElementById('show-cash-accounting')) return

  const app = new Vue({
    el: '#show-cash-accounting',
    data: {
      cashAccounting: null,
      subscriptions: [],
      infoMessage: '',
      currentPage: 1,
      search: '',
      totalFilteredCount: 0,
      totalCount: 0,
      totalPages: 0,
      perPage: 20,
      pages: [],
      selectedLocalNameTemporary: null,
      selectedLocalName: null,
      selectedLocalId: null,
      selectingNewLocal: false,
      isLocalManager: isLocalManager,
      cashAccountingId: cashAccountingId,
      regionName: regionName,
      infoErrorMessage: "",
      locals: [],
      cashAccountingPath: Routes.sales_dashboard_cash_accounting_path
    },
    methods: {
      loadCashAccounting() {
        const params = {
          page: this.currentPage,
          search: this.search,
          per_page: this.perPage,
          id: this.cashAccountingId
        };

        fetch(Routes.sales_dashboard_cash_accounting_json_path(params), { cache: 'no-cache' })
          .then(response => response.json())
          .then(data => {
            let { cash_accounting, subscriptions } = data;

            if (cash_accounting) {
              cash_accounting.subscriptions = subscriptions || [];

              this.cashAccounting = this.formatCashAccounting(cash_accounting);
              this.subscriptions = subscriptions.map(this.formatSubscription);
            } else {
              this.infoMessage = 'Nenhum caixa aberto no momento, o mesmo será aberto no momento que for criado o primeiro cliente.';
            }
          })
          .catch(_error => {
            this.infoMessage = 'Erro ao listar assinaturas. Por favor tente novamente!'
          });
      },

      translatedSubscriptionPaymentMethod(paymentMethod) {
        switch (paymentMethod) {
          case 'cash':
            return 'Dinheiro';
          case 'credit_card':
            return 'Crédito';
          case 'debit_card':
            return 'Débito';
          default:
            return paymentMethod
        }
      },

      formatCashAccounting(cashAccounting) {
        const href = this.cashAccountingPath(cashAccounting.id)

        return {
          id: cashAccounting.id,
          referenceDate: formatDateTime(cashAccounting.reference_date),
          localName: cashAccounting.local.name,
          sellerName: cashAccounting.seller.name,
          totalUsers: cashAccounting.subscriptions.length,
          totalDependants: cashAccounting.dependants.length,
          totalValue: cashAccounting.total_value,
          totalCash: cashAccounting.total_cash,
          totalDebit: cashAccounting.total_debit_card,
          totalCredit: cashAccounting.total_credit_card,
          href,
        }
      },

      formatSubscription(subscription) {
        const plan = subscription.create_plan_hash;

        return {
          id: subscription.id,
          idOnProtheus: subscription.id_on_protheus,
          localName: this.cashAccounting.localName,
          customerName: subscription.user.name,
          totalDependants: subscription.dependants_count,
          valuePaid: plan['value_paid'],
          paymentMethod: this.translatedSubscriptionPaymentMethod(subscription.payment_method)
        }
      },

      onSearch() {
        this.infoMessage = 'Carregando...'
        this.currentPage = 1
        this.subscriptions = []

        clearTimeout(this.debounceTimer)
        this.debounceTimer = setTimeout(() => {
          this.debounceTimer = null
          this.loadCashAccounting()
        }, 1000)
      },

      openCashAccountingModal() {
        this.selectingNewLocal = false;
        this.loadSelectedLocal();
        document.getElementById('cash-closing-modal').style.display = "flex";
        document.getElementById('container-info').style.display = "block";

        const openModal = document.getElementById('cash-closing-modal');
        window.addEventListener('click', event => {
          if (event.target == openModal) {
            this.closeCashAccountingModal();
          }
        });
      },

      closeCashAccountingModal() {
        document.getElementById('cash-closing-modal').style.display = "none";
      },

      loadSelectedLocal() {
        if (this.isLocalManager) {
          this.selectedLocalNameTemporary = local.name;
          this.selectedLocalName = local.name;
          this.selectedLocalId = local.id;
        } else {
          fetch(Routes.sales_dashboard_local_on_session_json_path(), { cache: 'no-cache' })
            .then(response => response.json())
            .then(data => {
              const { local, status } = data;

              if (status !== 200) return;

              this.selectedLocalNameTemporary = local.name;
              this.selectedLocalName = local.name;
              this.selectedLocalId = local.id;
            });
        }
      },

      changeLocal() {
        document.getElementById('container-info').style.display = "none";
        this.modalSearch = ''
        this.selectingNewLocal = true;
        this.loadLocals();
      },

      loadLocals() {
        this.infoMessage = 'Carregando...';

        const params = {
          region_name: this.regionName,
          search: this.modalSearch,
        };

        fetch(Routes.sales_dashboard_locals_by_search_json_path(params), { cache: 'no-cache' })
          .then(response => response.json())
          .then(localsData => {
            this.infoMessage = '';

            this.locals = localsData.locals.map(local => this.formatLocalOption(local));

            if (this.locals.length) {
              this.locals = this.locals.map((local) => {
                local.selected = local.id === this.selectedLocalId;

                return local;
              });
            } else {
              this.infoMessage = 'Nenhuma unidade encontrada.';
            }
          })
          .catch((_error) => {
            this.infoMessage = 'Erro ao listar às unidades. Por favor tente novamente!';
          });
      },

      formatLocalOption(local) {
        const { id, name } = local;

        return { id, name, selected: false };
      },

      confirmClosingCashAccounting() {
        this.loading = true;

        const params = {
          method: 'POST',
          cache: 'no-cache',
          body: '',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': Rails.csrfToken(),
          },
        };

        const id = this.cashAccounting.id;

        fetch(Routes.sales_dashboard_confirm_cash_accounting_closing_path(id), params)
          .then(response => response.json())
          .then(data => {
            this.infoErrorMessage = '';
            this.loading = false;

            if (data.status === 204) {
              const href = Routes.sales_dashboard_cash_accountings_path({ cash_accounting_status: 'closed' });
              this.redirectPage(href);
            } else {
              this.infoErrorMessage = 'Atenção: Não foi possível fechar o caixa, por favor tente novamente em instantes';
            }
          })
          .catch(_error => {
            this.infoErrorMessage = '';
            this.loading = false;
          });
      },

      limit,
      redirectPage,
    },
  })

  app.loadCashAccounting();
}

window.pages = window.pages || {}
window.pages.sales_dashboard = window.pages.sales_dashboard || {}
window.pages.sales_dashboard.cash_accountings = {
  initShowCashAccounting
}
