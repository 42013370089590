import Vue from 'vue/dist/vue.esm';
import Base64 from 'base-64';

export const initNewPaymentMethod = (gatewayPublicKey, gatewayPublicApi) => {
  if (!document.getElementById('new-payment-method')) return;

  new Vue({
    el: '#new-payment-method',
    data: {
      cardNumber: '',
      holderName: '',
      cardCvv: '',
      cardExpiration: '',
      loading: '',
      holderNameErrors: [],
      cardCvvErrors: [],
      cardExpirationErrors: [],
      cardNumberErrors: [],
    },
    computed: {},
    methods: {
      handleForm() {
        if (this.loading) return;

        this.loading = true;
        this.holderNameErrors = [];
        this.cardCvvErrors = [];
        this.cardExpirationErrors = [];
        this.cardNumberErrors = [];

        const paymentMethodCode = document.getElementById('payment-company-select').value;
        const body = {
          holder_name: this.holderName,
          card_expiration: this.cardExpiration,
          card_number: this.cardNumber.replace(/ /g, ''),
          card_cvv: this.cardCvv,
          payment_method_code: "credit_card",
          payment_company_code: paymentMethodCode,
        }
        
        const params = {
          method: 'POST',
          cache: 'no-cache',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Basic ${Base64.encode(`${gatewayPublicKey}:`)}`,
          },
        }

        fetch(gatewayPublicApi, params)
          .then(response => response.json())
          .then(data => {
            if (data.errors && data.errors.length) {
              this.loading = false;
              this.holderNameErrors = data.errors.filter(err => err.parameter === 'holder_name')
              this.cardCvvErrors = data.errors.filter(err => err.parameter === 'card_cvv')
              this.cardExpirationErrors = data.errors.filter(err => err.parameter === 'card_expiration')
              this.cardNumberErrors = data.errors.filter(err => err.parameter === 'card_number')
            } else if (data.payment_profile && data.payment_profile.gateway_token) {
              document.getElementById('gateway_token').value = data.payment_profile.gateway_token;
              document.getElementById('submit_btn').click();
            } else {
              this.loading = false;
            }
          })
          .catch(_error => {
            this.loading = false;
          });
      }
    },
  });
}
