import {
  maskPhones,
  maskPhoneText,
  initFaq,
  scrollToTop,
  initInfiniteScroll,
  findBInA,
} from '../../shared/utils'
import Rails from '@rails/ujs'
import Vue from 'vue/dist/vue.esm'
import TurbolinksAdapter from 'vue-turbolinks'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

Vue.use(TurbolinksAdapter)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

const initNewFilter = () => {
  if (!document.getElementById('vue-filter')) return

  let headers = {
    'Content-Type': 'application/json',
    'X-CSRF-Token': Rails.csrfToken(),
  }

  var app = new Vue({
    el: '#vue-filter',
    data: {
      partners: [],
      statesButtonText: 'Estado',
      mobileStatesButtonText: 'Selecionar estado',
      citiesButtonText: 'Cidade',
      mobileCitiesButtonText: 'Selecionar cidade',
      availableStates: [],
      availableCities: [],
      statesOptions: [],
      citiesOptions: [],
      selectedState: '',
      selectedCity: '',
      stateSearchInput: '',
      citySearchInput: '',
      nameSearchInput: '',
      filteringByState: false,
      filteringByCity: false,
      filteringByName: false,
      showStatesPopover: false,
      showCitiesPopover: false,
      showMainModalArea: true,
      showStatesModalArea: false,
      showCitiesModalArea: false,
      firstPartnersLoaded: false,
      completePartnersLoaded: false,
      shownPagesCount: 1,
      maxShownPagesCount: 1,
      totalPartnerCount: 0,
      sectionConfigs: {
        default: {
          limit: 3000,
        },
      },
    },
    methods: {
      initFilterWithSession() {
        fetch(Routes.partner_filtering_session_path(), {
          cache: 'no-cache',
        }).then((response) => {
          response.json().then((data) => {
            if (data.state) {
              this.filteringByState = true
              this.selectedState = data.state
            }
            if (data.city) {
              this.filteringByCity = true
              this.selectedCity = data.city
            }
            app.fetchStates()
            app.fetchCities()
            app.fetchPartnerPage(1)
          })
        })
      },
      fetchStates() {
        let params = {
          covid19: true,
        }
        fetch(Routes.partner_states_json_path(), {
          method: 'POST',
          body: JSON.stringify(params),
          headers: headers,
          cache: 'no-cache',
        }).then((response) => {
          response.json().then((data) => {
            let statesOptions = data.map((state) => {
              return { value: state.name, text: state.full_name }
            })
            this.availableStates = statesOptions
            this.statesOptions = statesOptions
          })
        })
      },
      fetchCities() {
        let params = {
          covid19: true,
          state: this.selectedState,
        }
        fetch(Routes.partner_cities_json_path(), {
          method: 'POST',
          body: JSON.stringify(params),
          headers: headers,
          cache: 'no-cache',
        }).then((response) => {
          response.json().then((data) => {
            let citiesOptions = data.map((city) => {
              return { value: city.name, text: city.name }
            })
            this.availableCities = citiesOptions
            this.citiesOptions = citiesOptions
          })
        })
      },
      fetchPartnerPage(number) {
        this.completePartnersLoaded = false
        if (number == 1) this.firstPartnersLoaded = false

        let params = {
          covid19: true,
          city: this.selectedCity,
          state: this.selectedState,
          page: number,
        }

        fetch(Routes.partners_json_path(), {
          method: 'POST',
          body: JSON.stringify(params),
          headers: headers,
          cache: 'no-cache',
        }).then((response) => {
          response.json().then((data) => {
            if (data.page == 1) {
              this.partners = []
              this.shownPagesCount = 1
            }

            this.partners = this.partners.concat(data.partners)
            this.firstPartnersLoaded = true
            this.maxShownPagesCount = data.total_pages
            this.totalPartnerCount = data.total_count
            this.completePartnersLoaded = true
          })
        })
      },
      applyStateFilter() {
        if (this.selectedState) {
          this.filteringByState = true
        } else {
          this.filteringByState = false
        }
        this.showStatesPopover = false
        this.updateStatesButtonText()
        this.toggleMainModalArea()
        this.resetCity()
      },
      applyCityFilter() {
        if (this.selectedCity) {
          this.filteringByCity = true
        } else {
          this.filteringByCity = false
        }
        this.showCitiesPopover = false
        this.updateCitiesButtonText()
        this.toggleMainModalArea()
        this.fetchPartnerPage(1)
      },
      applyNameFilter() {
        if (this.nameSearchInput) {
          this.filteringByName = true
        } else {
          this.filteringByName = false
        }
        this.fetchPartnerPage(1)
      },
      filterStatesOptions() {
        let filteredStatesOptions = this.availableStates.filter((state) =>
          findBInA(`${state.text}`, this.stateSearchInput)
        )
        this.statesOptions = filteredStatesOptions
      },
      filterCitiesOptions() {
        let filteredCitiesOptions = this.availableCities.filter((city) =>
          findBInA(`${city.text}`, this.citySearchInput)
        )
        this.citiesOptions = filteredCitiesOptions
      },
      resetState() {
        this.selectedState = ''
        this.filteringByState = false
        this.showStatesPopover = false
        this.updateStatesButtonText()
        this.toggleMainModalArea()
        this.fetchStates()
        this.resetCity()
      },
      resetCity() {
        this.selectedCity = ''
        this.filteringByCity = false
        this.showCitiesPopover = false
        this.updateCitiesButtonText()
        this.toggleMainModalArea()
        this.fetchCities()
        this.resetName()
      },
      resetName() {
        this.nameSearchInput = ''
        this.applyNameFilter()
      },
      updateStatesButtonText() {
        if (this.filteringByState) {
          this.statesButtonText = this.selectedState
          this.mobileStatesButtonText = this.selectedState
        } else {
          this.statesButtonText = 'Estado'
          this.mobileStatesButtonText = 'Selecionar estado'
        }
      },
      updateCitiesButtonText() {
        if (this.filteringByCity) {
          this.citiesButtonText = this.selectedCity
          this.mobileCitiesButtonText = this.selectedCity
        } else {
          this.citiesButtonText = 'Cidade'
          this.mobileCitiesButtonText = 'Selecionar cidade'
        }
      },
      toggleMainModalArea() {
        this.showMainModalArea = true
        this.showStatesModalArea = false
        this.showCitiesModalArea = false
      },
      toggleStatesModalArea() {
        this.showMainModalArea = false
        this.showStatesModalArea = true
      },
      toggleCitiesModalArea() {
        this.showMainModalArea = false
        this.showCitiesModalArea = true
      },
      maskPhone(phone) {
        return maskPhoneText(phone)
      },
      seeMorePages() {
        if (this.shownPagesCount < this.maxShownPagesCount) {
          this.shownPagesCount += 1
          this.fetchPartnerPage(this.shownPagesCount)
        }
      },
    },
  })
  app.initFilterWithSession()
}

window.pages = window.pages || {}
window.pages.site = window.pages.site || {}
window.pages.site.examSpots = {
  maskPhones,
  initFaq,
  initNewFilter,
  initInfiniteScroll,
  scrollToTop,
}
