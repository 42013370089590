import { scrollToTop } from '../../shared/utils'

const initMobileFooterCollapse = () => {
  $('.footer-menu-entry').off()

  $('.footer-menu-entry').click(e => {
    $(e.currentTarget)
      .find('ul')
      .toggle('medium')

    if (
      $(e.currentTarget)
        .find('.expander')
        .text() == '+'
    )
      $(e.currentTarget)
        .find('.expander')
        .text('-')
    else
      $(e.currentTarget)
        .find('.expander')
        .text('+')
  })
}

window.pages = window.pages || {}
window.pages.site = window.pages.site || {}
window.pages.site.footer = {
  initMobileFooterCollapse,
  scrollToTop
}
