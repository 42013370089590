import {
  maskPhones,
  findBInA,
  mountArrayPages,
  maskPhoneText,
} from "../../shared/utils";
import Rails from "@rails/ujs";
import Vue from "vue/dist/vue.esm";
import TurbolinksAdapter from "vue-turbolinks";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";

Vue.use(TurbolinksAdapter);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

const initShowPageFilter = (
  partnerId,
  cities,
  states,
  categories,
  subCategories
) => {
  if (!document.getElementById("vue-show-filter")) return;

  let tenWhiteSpaces =
    "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0";

  let statesOptions = states.map((state) => {
    return { value: state.name, text: state.full_name };
  });
  let citiesOptions = cities.map((city) => {
    return { value: city.name, text: city.name };
  });

  var app = new Vue({
    el: "#vue-show-filter",
    data: {
      classification: "",
      category: "",
      covid19: false,
      page: 1,
      perPage: 6,
      totalPages: 0,
      totalCount: 0,
      totalFilteredCount: 0,
      pages: [],
      partnerBranches: [],
      categoriesButtonText: "Selecionar categorias",
      subCategoriesButtonText: "Selecionar especialidades",
      statesButtonText: "Estado",
      citiesButtonText: "Cidade",
      availableCategories: categories || [],
      availableSubCategories: subCategories || [],
      availableStates: states || [],
      availableCities: cities || [],
      categoriesOptions: categories || [],
      subCategoriesOptions: subCategories || [],
      statesOptions: statesOptions || [],
      citiesOptions: citiesOptions || [],
      selectedCategories: [],
      selectedSubCategories: [],
      selectedState: "",
      selectedCity: "",
      categorySearchInput: "",
      subCategorySearchInput: "",
      stateSearchInput: "",
      citySearchInput: "",
      filteringByCategory: false,
      filteringBySubCategory: false,
      filteringByState: false,
      filteringByCity: false,
      showCategoriesPopover: false,
      showSubCategoriesPopover: false,
      showStatesPopover: false,
      showCitiesPopover: false,
      sectionConfigs: {
        default: {
          limit: 20,
        },
      },
    },
    methods: {
      fetchFilteringSession() {
        fetch(Routes.partner_filtering_session_path(), {
          cache: "no-cache",
        }).then((response) => {
          response.json().then((data) => {
            this.classification = data.classification;
            this.category = data.category;
            this.covid19 = data.covid19;
            if (data.categories && data.categories.length > 0) {
              this.filteringByCategory = true;
              this.selectedCategories = data.categories;
              this.updateCategoriesButtonText()
            }
            if (data.sub_categories && data.sub_categories.length > 0) {
              this.filteringBySubCategory = true;
              this.selectedSubCategories = data.sub_categories;
              this.updateSubCategoriesButtonText()
            }
            if (data.state) {
              this.filteringByState = true;
              this.selectedState = data.state;
              this.updateStatesButtonText()
            }
            if (data.city) {
              this.filteringByCity = true;
              this.selectedCity = data.city;
              this.updateCitiesButtonText()
            }
            this.fetchBranches()
          });
        }).catch(_error => {
          this.fetchBranches()
        });
      },
      fetchBranches() {
        let headers = {
          "Content-Type": "application/json",
          "X-CSRF-Token": Rails.csrfToken(),
        };
        let params = {
          classification: this.classification,
          category: this.category,
          categories: this.selectedCategories,
          sub_categories: this.selectedSubCategories,
          state: this.selectedState,
          city: this.selectedCity,
          page: this.page,
          per_page: this.perPage,
        };
        fetch(Routes.partner_show_json_path({ id: partnerId }), {
          method: "POST",
          body: JSON.stringify(params),
          headers: headers,
          cache: "no-cache",
        }).then((response) => {
          response
            .json()
            .then((data) => {
              this.partnerBranches = data.partner_branches;
              this.page = data.page;
              this.perPage = data.per_page;
              this.totalCount = data.total_count;
              this.totalFilteredCount = data.total_filtered_count;
              this.totalPages = data.total_pages;
              this.pages = mountArrayPages(this.totalPages);
            });
        });
      },
      showPage(page) {
        this.page = page;
        this.fetchBranches();
      },
      onPerPageSelect(event) {
        this.perPage = event.target.value;
        this.showPage(1);
      },
      maskPhoneText(phone) {
        return maskPhoneText(phone);
      },
      applyCategoriesFilter() {
        this.showCategoriesPopover = false;
        if (this.selectedCategories.length > 0) {
          this.filteringByCategory = true;
        } else {
          this.filteringByCategory = false;
        }
        this.updateCategoriesButtonText();
        this.resetSubCategories();
      },
      applySubCategoriesFilter() {
        this.showSubCategoriesPopover = false;
        if (this.selectedSubCategories.length > 0) {
          this.filteringBySubCategory = true;
        } else {
          this.filteringBySubCategory = false;
        }
        this.updateSubCategoriesButtonText();
        this.fetchBranches();
      },
      applyStateFilter() {
        if (this.selectedState) {
          this.filteringByState = true;
        } else {
          this.filteringByState = false;
        }
        this.showStatesPopover = false;
        this.updateStatesButtonText();
        this.resetCity();
      },
      applyCityFilter() {
        if (this.selectedCity) {
          this.filteringByCity = true;
        } else {
          this.filteringByCity = false;
        }
        this.showCitiesPopover = false;
        this.updateCitiesButtonText();
        this.fetchBranches();
      },
      filterCategoriesOptions() {
        let filteredCategoriesOptions = this.availableCategories.filter(
          (category) => findBInA(`${category.name}`, this.categorySearchInput)
        );
        this.categoriesOptions = filteredCategoriesOptions;
      },
      filterSubCategoriesOptions() {
        let filteredSubCategoriesOptions = this.availableSubCategories.filter(
          (subCategory) =>
            findBInA(`${subCategory.name}`, this.subCategorySearchInput)
        );
        this.subCategoriesOptions = filteredSubCategoriesOptions;
      },
      filterStatesOptions() {
        let filteredStatesOptions = this.availableStates
          .filter((s) => findBInA(`${s.full_name}`, this.stateSearchInput))
          .map((state) => {
            return { value: state.name, text: state.full_name };
          });
        this.statesOptions = filteredStatesOptions;
      },
      filterCitiesOptions() {
        let filteredCitiesOptions = this.availableCities
          .filter((c) => findBInA(`${c.name}`, this.citySearchInput))
          .map((city) => {
            return { value: city.name, text: city.name };
          });
        this.citiesOptions = filteredCitiesOptions;
      },
      resetCategories() {
        this.selectedCategories = [];
        this.filteringByCategory = false;
        this.showCategoriesPopover = false;
        this.updateCategoriesButtonText();
        this.resetSubCategories();
      },
      resetSubCategories() {
        this.selectedSubCategories = [];
        if (this.filteringByCategory) {
          this.subCategoriesOptions = this.availableSubCategories.filter(
            (sc) => {
              return this.selectedCategories.includes(sc.category);
            }
          );
        } else {
          this.subCategoriesOptions = this.availableSubCategories;
        }
        this.filteringBySubCategory = false;
        this.showSubCategoriesPopover = false;
        this.updateSubCategoriesButtonText();
        this.fetchBranches();
      },
      resetState() {
        this.selectedState = "";
        this.filteringByState = false;
        this.showStatesPopover = false;
        this.statesOptions = this.availableStates.map((state) => {
          return { value: state.name, text: state.full_name };
        });
        this.updateStatesButtonText();
        this.resetCity();
      },
      resetCity() {
        this.selectedCity = "";
        this.filteringByCity = false;
        this.showCitiesPopover = false;
        if (this.filteringByState) {
          this.citiesOptions = this.availableCities.filter((city) => {
            return (
              city.state == this.selectedState
            )
          }).map((city) => {
            return { value: city.name, text: city.name };
          });
        } else {
          this.citiesOptions = this.availableCities.map((city) => {
            return { value: city.name, text: city.name };
          });
        }
        
        this.updateCitiesButtonText();
        this.fetchBranches();
      },
      updateCategoriesButtonText() {
        if (this.filteringByCategory) {
          let [firstCategory] = this.selectedCategories;
          let shouldTruncateCategoryName = firstCategory.length > 10;
          if (shouldTruncateCategoryName) {
            firstCategory = `${firstCategory.substring(0, 10)}...`;
          }
          if (this.selectedCategories.length > 1) {
            let counter = this.selectedCategories.length - 1;
            if (shouldTruncateCategoryName) {
              this.categoriesButtonText = `${firstCategory}${tenWhiteSpaces}+${counter}`;
            } else {
              this.categoriesButtonText = `${firstCategory}...${tenWhiteSpaces}+${counter}`;
            }
          } else {
            this.categoriesButtonText = firstCategory;
          }
        } else {
          this.categoriesButtonText = "Selecionar categorias";
        }
      },
      updateSubCategoriesButtonText() {
        if (this.filteringBySubCategory) {
          let [firstSubCategoryId] = this.selectedSubCategories;
          let [firstSubCategory] = this.availableSubCategories.filter((sc) => {
            return sc.id == firstSubCategoryId;
          });
          let firstSubCategoryName = firstSubCategory.name;
          let shouldTruncateSubCategoryName = firstSubCategoryName.length > 10;
          if (shouldTruncateSubCategoryName) {
            firstSubCategoryName = `${firstSubCategoryName.substring(
              0,
              10
            )}...`;
          }
          if (this.selectedSubCategories.length > 1) {
            let counter = this.selectedSubCategories.length - 1;
            if (shouldTruncateSubCategoryName) {
              this.subCategoriesButtonText = `${firstSubCategoryName}${tenWhiteSpaces}+${counter}`;
            } else {
              this.subCategoriesButtonText = `${firstSubCategoryName}...${tenWhiteSpaces}+${counter}`;
            }
          } else {
            this.subCategoriesButtonText = firstSubCategoryName;
          }
        } else {
          this.subCategoriesButtonText = "Selecionar especialidades";
        }
      },
      updateStatesButtonText() {
        if (this.filteringByState) {
          this.statesButtonText = this.selectedState;
        } else {
          this.statesButtonText = "Estado";
        }
      },
      updateCitiesButtonText() {
        if (this.filteringByCity) {
          this.citiesButtonText = this.selectedCity;
        } else {
          this.citiesButtonText = "Cidade";
        }
      },

      removeUrlPrefix(url) {
        if (!url) return url;

        return url.replace("http://", "").replace("https://", "");
      },
    },
  });
  app.fetchFilteringSession();
};

window.pages = window.pages || {};
window.pages.site = window.pages.site || {};
window.pages.site.partnerShow = {
  maskPhones,
  initShowPageFilter,
};
