import { formMasks, maskPhones, initZipCodeAutocomplete } from '../shared/utils'

const initShowDependantDocumentAttributes = () => {
  $(document).ready(() => {
    toggleHiddenFields()
  })

  $('#documentSelect').change(() => {
    toggleHiddenFields()
  })
}

const toggleHiddenFields = () => {
  let value = $('#documentSelect').val()

  if (value === "RG (dependente)") {
    $('#rgDiv').removeClass('d-none')
    $('#issuingAgencyDiv').removeClass('d-none')
    $('#cpfDiv').addClass('d-none')
    $('#cpfAttribute').val('')
  } else {
    $('#rgDiv').addClass('d-none')
    $('#issuingAgencyDiv').addClass('d-none')
    $('#cpfDiv').removeClass('d-none')
    $('#rgAttribute').val('')
    $('#issuingAgencyAttribute').val('')
  }
}



window.pages = window.pages || {}
window.pages.dependants = {
  formMasks,
  maskPhones,
  initZipCodeAutocomplete,
  initShowDependantDocumentAttributes
}
