import Vue from 'vue/dist/vue.esm'

export function initPluginsSelect() {
  if (!document.getElementById('company-plugins-select')) return

  const hasMetlifeIndividual = $('#company_has_metlife_individual').val() === 'true'
  const hasMetlifeWithDependants = $('#company_has_metlife_with_dependants').val() === 'true'
  const hasTempoAssist = $('#company_has_tempo_assist').val() === 'true'

  const app = new Vue({
    el: '#company-plugins-select',
    data: {
      plugins: [
        {
          kind: 'metlife',
          title: 'Seguro de vida - Metlife',
          description: 'Internações hospitalares, assistência funerária e seguro por morte acidental.',
          plans: [
            {
              id: 'has_metlife_individual',
              price: '5,90',
              frequency: 'por Mês',
              description: 'O seguro não contempla seus dependentes.',
              selected: hasMetlifeIndividual
            },
            {
              id: 'has_metlife_with_dependants',
              price: '11,90',
              frequency: 'por Mês',
              description: 'O seguro contempla dependentes com vínculo famíliar.',
              selected: hasMetlifeWithDependants
            }
          ]
        },
        // {
        //   kind: 'tempo_assist',
        //   title: 'Assistência Técnica - Tempo Assist',
        //   description: 'Serviços de mão de obra, vigilância, reboque, pane seca e muito mais.',
        //   plans: [
        //     {
        //       id: 'has_tempo_assist',
        //       price: '6,95',
        //       frequency: 'por Mês',
        //       description: 'Proteja seu automóvel e sua residência.',
        //       selected: hasTempoAssist
        //     },
        //   ]
        // },
      ],
    },
    computed: {},
    methods: {
      selectPluginPlan: (selectedPlugin, selectedPlan) => {
        this.plugins = app.plugins.map(plugin => {
          if (selectedPlugin.kind === plugin.kind) {
            plugin.plans = plugin.plans.map(plan => {
              if (selectedPlan.id === plan.id) {
                $(`#company_${plan.id}`).val(!plan.selected)
                return { ...plan, selected: !plan.selected }
              } else {
                $(`#company_${plan.id}`).val(false)
                return { ...plan, selected: false }
              }
            })
          }
          return plugin
        })
      }
    },
  })
}
