import { formMasks, maskPhones, goToByScroll, redirectPage, limit } from '../../shared/utils'
import Vue from 'vue/dist/vue.esm'
import { BootstrapVue } from 'bootstrap-vue'

import TurbolinksAdapter from 'vue-turbolinks'

Vue.use(TurbolinksAdapter)
Vue.use(BootstrapVue)

const initSellersSearch = () => {
  if (!document.getElementById('sellers-list-sales-dashboard')) return

  const app = new Vue({
    el: '#sellers-list-sales-dashboard',
    data: {
      sellers: [],
      search: '',
      current_page: 1,
      total_count: 0,
      total_filtered_count: 0,
      total_pages: 0,
      per_page: 20,
      pages: [],
      loading: false,
      infoMessage: '',
      debounceTimer: null,
      seller_type: 'call_center',
      ordainRanking: 'DESC',
      rankingPosition: '1',
      sellersRanking : [],
      achievedGoalPercentage: 0,
      notification: null,
      importBatchInterval: null,
      notificationPath: Routes.sales_dashboard_show_notification_path,
      sellerPath: Routes.sales_dashboard_seller_path
    },
    methods: {
      loadSellers() {
        this.loading = true
        const params = { page: this.current_page, 
                         search: this.search, 
                         per_page: this.per_page, 
                         seller_type: this.seller_type, 
                         ordain_ranking: this.ordainRanking }
        fetch(
          Routes.sales_dashboard_sellers_json_path(params),
          { cache: 'no-cache' }
        )
          .then(response => response.json())
          .then(data => {
            this.loading = false,
            this.infoMessage = ''
            this.sellers = data.sellers.map(this.formatSeller)
            this.current_page = data.page
            this.total_count = data.total_count
            this.total_filtered_count = data.total_filtered_count
            this.total_pages = data.total_pages
            this.per_page = data.per_page
            this.pages = this.mountArrayPages(this.total_pages)
     
            if (!this.sellers.length) {
              this.infoMessage = 'Nenhum vendedor encontrado.'
            }

            app.importBatchStatus()
          })
          .catch(_error => {
            this.infoMessage =
              'Erro ao listar vendedores. Por favor tente novamente!'
          })
      },

      importBatchStatus() {
        fetch(Routes.sales_dashboard_import_file_status_path(), { cache: 'no-cache' })
        .then(response => response.json())
        .then(data => {
          if (data) {
            clearInterval(this.importBatchInterval);
            if (data.status == 'success' || data.status == 'notice') {
              this.notification = data;
              this.loadSellers();
            } else if (data.status == 'processing') {
              this.notification = data;
              this.importBatchInterval = setInterval(this.importBatchStatus, 60000);
            } else if (data.status == 'error') {
              this.notification = data;
            }
          }
        })
      },

      redirectPage,

      mountArrayPages(totalPage) {
        const pages = []
        for (let i = 1; i <= totalPage; i++) {
          pages.push(i)
        }
        return pages
      },

      showPage(page) {
        this.current_page = page
        this.loadSellers()
      },

      onPerPageSelect(event) {
        this.per_page = event.target.value
        this.showPage(1)
      },

      onSearch() {
        this.infoMessage = 'Carregando...'
        this.current_page = 1
        this.sellers = []

        clearTimeout(this.debounceTimer)
        this.debounceTimer = setTimeout(() => {
          this.debounceTimer = null
          this.loadSellers()
        }, 1000)
      },

      between(x, min, max) {
        return x >= min && x <= max;
      },

      applyRankingOrdering(value) {
        this.ordainRanking = value
        this.loadSellers()
      },

      setSellerType(type) {
        this.seller_type = type
        this.showPage(1)
      },

      formatSeller(seller) {
        const achievedUsersGoalPercentage = seller.goal_for_current_period ? seller.goal_for_current_period.achieved_users_goal_percentage : 0
        const achievedDependantsGoalPercentage = seller.goal_for_current_period ? seller.goal_for_current_period.achieved_dependants_goal_percentage : 0
        const totalUsers = seller.goal_for_current_period ? seller.goal_for_current_period.achieved_users_count : 0
        const totalDependants = seller.goal_for_current_period ?  seller.goal_for_current_period.achieved_dependants_count : 0
        const href = this.sellerPath(seller.id)

        return {
          id: seller.id,
          name: seller.name,
          cpf: seller.cpf,
          regionName: seller.seller_profile.region_name,
          totalUsers: totalUsers,
          totalDependants: totalDependants,
          achievedUsersGoalPercentage: achievedUsersGoalPercentage,
          achievedDependantsGoalPercentage: achievedDependantsGoalPercentage,
          rankingPosition: seller.ranking_position,
          href
        }
      },

      limit,
    }
  })

  app.loadSellers()
}

const initLocals = (sellerRole, localIds) => {
  if (!document.getElementById('form-sellers')) return

  let region = document.getElementById('select_region').value || ''
  
  const app = new Vue({
    el: '#form-sellers',
    data: {
      locals: [],
      region_name: region,
      selectedLocals: [],
      localsOptions: [],
      sellerRole: '',
      showRegionInput: false,
      showLocalCheckboxs: false
    },
    methods: {
      init() {
        this.loadLocals();
        this.initInputsBySellerRole();

        this.selectedLocals = JSON.parse(localIds || '[]')
      },

      loadLocals() {
        fetch(
          Routes.sales_dashboard_locals_by_region_json_path({ region_name: this.region_name }),
          { cache: 'no-cache' }
        )
          .then(response => response.json())
          .then(data => {
            this.locals = data.locals.map(this.formatLocal)
          })
          .catch(error => {
            this.infoMessage =
              'Erro ao listar unidades. Por favor tente novamente!'
          })
      },

      setRegion(event) {
        this.locals = []
        this.region_name = event.target.value

        this.loadLocals()
      },

      formatLocal(local) {
        return {
          id: local.id,
          name: local.name,
        }
      },

      initInputsBySellerRole() {
        this.sellerRole = sellerRole;

        if (['regional', 'local', 'regional_manager'].includes(this.sellerRole)) {
          this.showRegionInput = true;
        } else if (['local_manager'].includes(this.sellerRole)) {
          this.showRegionInput = true;
          this.showLocalCheckboxs = true;
        }
      },

      setSellerRole(event) {
        this.sellerRole = event.target.value;
        this.selectedLocals = []
        this.region_name = "";
        this.locals = []

        if (['regional', 'local', 'regional_manager'].includes(this.sellerRole)) {
          this.showRegionInput = true;
          this.showLocalCheckboxs = false;
        } else if (['local_manager'].includes(this.sellerRole)) {
          this.showRegionInput = true;
          this.showLocalCheckboxs = true;
        } else {
          this.showRegionInput = false;
          this.showLocalCheckboxs = false;
        }
      }  
    }
  })

  app.init() 
}

const initTabAnchors = () => {
  $('.tab.personal-data').off()
  $('.tab.seller-profile').off()

  $('.tab.personal-data').click(e => {
    $('.tab').removeClass('active')
    $('.tab.personal-data').addClass('active')
    $(window).scrollTop(0)
  })

  $('.tab.seller-profile').click(e => {
    $('.tab').removeClass('active')
    $('.tab.seller-profile').addClass('active')
    goToByScroll('#sellerprof', 130)
  })
}

const handleTemporaryPasswordCopy = () => {
  $('#btn-temporary-password').click(function(event) {
    const copyText = document.getElementById('temporary-password')
    copyText.select()
    copyText.setSelectionRange(0, 99999)
    document.execCommand('copy')
  })
}

window.pages = window.pages || {}
window.pages.sales_dashboard = window.pages.sales_dashboard || {}
window.pages.sales_dashboard.sellers = {
  formMasks,
  maskPhones,
  initSellersSearch,
  initTabAnchors,
  initLocals,
  handleTemporaryPasswordCopy
}
