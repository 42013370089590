import {
  redirectPage,
  formatPhone,
  limit,
} from '../../shared/utils'
import Vue from 'vue/dist/vue.esm'

const initDependantsSearch = () => {
  if (!document.getElementById('dependants-list-sales-dashboard')) return

  const app = new Vue({
    el: '#dependants-list-sales-dashboard',
    data: {
      dependants: [],
      search: '',
      currentPage: 1,
      totalCount: 0,
      totalFilteredCount: 0,
      totalPages: 0,
      perPage: 20,
      pages: [],
      loading: false,
      infoMessage: '',
      debounceTimer: null,
      statuses: ['Todos', 'Ativo', 'Inativo'],
      selectedStatus: 'Todos',
      customerPath: Routes.sales_dashboard_customer_path,
      dependantPath: Routes.sales_dashboard_show_dependant_path,
    },
    computed: {},
    methods: {
      limit,
      redirectPage,

      loadDependants() {
        this.loading = true
        const params = {
          page: this.currentPage,
          search: this.search,
          per_page: this.perPage,          
          active: this.selectedStatus === 'Todos' ? [true, false] : this.setSelectedStatus(this.selectedStatus),
        }

        fetch(Routes.sales_dashboard_dependants_json_path(params), {
          cache: 'no-cache',
        })
          .then((response) => response.json())
          .then((data) => {
            ;(this.loading = false), (this.infoMessage = '')
            this.dependants = data.dependants.map(this.formatDependant)
            this.currentPage = data.page
            this.totalCount = data.total_count
            this.totalFilteredCount = data.total_filtered_count
            this.totalPages = data.total_pages
            this.perPage = data.per_page
            this.pages = this.mountArrayPages(this.total_pages)

            if (!this.dependants.length) {
              this.infoMessage = 'Nenhum dependente encontrado.'
            }
          })
          .catch((_error) => {
            this.infoMessage =
              'Erro ao listar dependentes. Por favor tente novamente!'
          })
      },

      showPage(page) {
        this.currentPage = page
        this.loadDependants()
      },

      onPerPageSelect(event) {
        this.perPage = event.target.value
        this.showPage(1)
      },

      mountArrayPages(totalPage) {
        const pages = []
        for (let i = 1; i <= totalPage; i++) {
          pages.push(i)
        }
        return pages
      },

      onSearch() {
        this.infoMessage = 'Carregando...'
        this.currentPage = 1
        this.dependants = []

        clearTimeout(this.debounceTimer)
        this.debounceTimer = setTimeout(() => {
          this.debounceTimer = null
          this.loadDependants()
        }, 1000)
      },

      formatDependant(dependant) {
        return {
          id: dependant.id,
          name: dependant.name,
          cpf: dependant.cpf,
          phone: formatPhone(dependant.phone),
          holderName: dependant.user.name,
          active: dependant.active,
          status: this.setStatus(dependant.active),
          href: this.customerPath(dependant.user),
          hrefDependant: this.dependantPath(dependant.user, dependant),
          dependantStatusClass: this.mountDependantStatusClass(dependant.active),
        }
      },

      setStatus(active) {
        if (active) {
          return 'ativo'
        } else {
          return 'inativo' 
        }
      },

      setSelectedStatus(status) {
        if (status === 'Ativo') {
          return [true]
        } else {
          return [false]
        }
      },

      mountDependantStatusClass(dependantStatus) {
        let dependantStatusClass = {}
      
        if (dependantStatus) {
          dependantStatusClass['active'] = true
        } else {
          dependantStatusClass['default'] = true
        }
        return dependantStatusClass
      },
    },
  })

  app.loadDependants()
}

window.pages = window.pages || {}
window.pages.sales_dashboard = window.pages.sales_dashboard || {}
window.pages.sales_dashboard.dependants = {
  initDependantsSearch,
}
