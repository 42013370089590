import { formMasks } from '../../shared/utils';
import { initShowCashAccounting } from './showCashAccounting';
import { initCashAccountingsSearch } from './listCashAccountings';
import { initCashAccountingHistoryList } from './listCashAccountingHistory';
import { initShowCashAccountingHistory } from './showCashAccountingHistory';

window.pages = window.pages || {};
window.pages.sales_dashboard = window.pages.sales_dashboard || {};
window.pages.sales_dashboard.cash_accountings = {
  formMasks,
  initCashAccountingsSearch,
  initShowCashAccounting,
  initCashAccountingHistoryList,
  initShowCashAccountingHistory
};