import {
  limit,
  redirectPage
} from '../../shared/utils';
import Vue from 'vue/dist/vue.esm';
import TurbolinksAdapter from 'vue-turbolinks'

Vue.use(TurbolinksAdapter)

export const initIntegrationErrorsSearch = (filter_service_name, search) => {
  if (!document.getElementById('integration-errors-page')) return
  
  const app = new Vue({
    el: '#integration-errors-page',
    data: {
      services: ['Todos', 'Vidalink', 'Protheus'],
      selectedService: filter_service_name || 'Todos',
      search: search || '',
      debounceTimer: null
    },
    computed: {},
    methods: {
      init () {
        document.getElementById('filter_service_name').value = this.selectedService;
      },

      changeService(service) {
        this.selectedService = service
        this.search = ''
        document.getElementById('search_param').value = this.search;
        document.getElementById('filter_service_name').value = this.selectedService;
        document.getElementById('integrations_form').submit();
      },

      onSearch() {
        document.getElementById('integrations_form').submit();
      },

      onSearch() {
        clearTimeout(this.debounceTimer)
        this.debounceTimer = setTimeout(() => {
          this.debounceTimer = null
          document.getElementById('integrations_form').submit();
        }, 1000)
      },

      redirectPage,
      limit
    }
  })

  app.init()
}

window.pages = window.pages || {}
window.pages.admin_dashboard = window.pages.admin_dashboard || {}
window.pages.admin_dashboard.integrationErrors = {
  initIntegrationErrorsSearch
}