import { redirectPage, limit, formatDateTime, getMonthByDate, subscribe } from '../../shared/utils';
import Vue from 'vue/dist/vue.esm';
import Rails from '@rails/ujs';

import TurbolinksAdapter from 'vue-turbolinks';

Vue.use(TurbolinksAdapter);

export const initCashAccountingsSearch = (cashAccountingId, cashAccountingStatus, isLocalManager, isManager) => {
  if (!document.getElementById('cash_accounting-list-sales-dashboard')) return;

  const app = new Vue({
    el: '#cash_accounting-list-sales-dashboard',
    data: {
      cashAccounting: null,
      cashAccountings: [],
      cashAccountingsResume: null,
      subscriptions: [],
      search: '',
      modalSearch: '',
      currentPage: 1,
      totalCount: 0,
      totalFilteredCount: 0,
      totalPages: 0,
      perPage: 20,
      pages: [],
      loading: false,
      infoMessage: "",
      infoErrorMessage: "",
      debounceTimer: null,
      cashAccountingStatus: cashAccountingStatus,
      notification: null,
      managementReportInterval: null,
      isLocalManager: isLocalManager,
      isManager: isManager,
      locals: [],
      selectedLocalName: null,
      selectedLocalId: null,
      localsInfoMessage: '',
      initialDate: new Date(),
      endDate: new Date(),
      managementReportUrl: '',
      notificationPath: Routes.sales_dashboard_show_notification_path,
      cashAccountingPath: Routes.sales_dashboard_cash_accounting_path,
      salesDashboardCashAccountingLocalSellerReportPath: Routes.sales_dashboard_cash_accounting_local_seller_report_path,
    },
    methods: {
      init() {
        if (this.cashAccountingStatus === 'opened') {
          this.loadCashAccounting();
        } else {
          this.loadCashAccountings()
          subscribe('change-local-id', this.loadCashAccountings);
        }
      },

      translatedSubscriptionPaymentMethod(paymentMethod) {
        switch (paymentMethod) {
          case 'cash':
            return 'Dinheiro';
          case 'credit_card':
            return 'Crédito';
          case 'debit_card':
            return 'Débito';
          default:
            return paymentMethod;
        }
      },

      formatSubscription(subscription) {
        const valuePaid = subscription.create_plan_hash['value_paid'] || '0';

        return {
          id: subscription.id,
          idOnProtheus: subscription.id_on_protheus,
          localName: this.cashAccounting.localName,
          customerName: subscription.user.name,
          totalDependants: subscription.dependants_count,
          valuePaid: valuePaid,
          paymentMethod: this.translatedSubscriptionPaymentMethod(subscription.payment_method),
        }
      },

      loadCashAccountings() {
        this.infoMessage = 'Carregando...';
        this.loading = true;

        const params = {
          page: this.currentPage,
          search: this.search,
          per_page: this.perPage,
          cash_accounting_status: this.cashAccountingStatus
        };

        const api = Routes.sales_dashboard_cash_accountings_json_path(params);
        fetch(api, { cache: 'no-cache' })
          .then(response => {
              if (!response.ok) {
                if (response.status == 400) {
                  throw Error('É necessário selecionar uma unidade para listar os caixas!');
                } else {
                  throw Error('Erro ao listar os caixas. Por favor tente novamente!');
                }
              }

              return response.json()
          })
          .then(data => {
            this.loading = false;
            this.infoMessage = '';
            this.cashAccountings = data.cash_accountings.map(this.formatCashAccounting);
            this.cashAccountingsResume = this.cashAccountings.reduce(this.formatCashAccountingsResume, {});
            this.current_page = data.page;
            this.totalCount = data.total_count;
            this.totalFilteredCount = data.total_filtered_count;
            this.totalPages = data.total_pages;
            this.perPage = data.per_page;
            this.pages = this.mountArrayPages(this.total_pages);

            if (!this.cashAccountings.length) {
              this.infoMessage = 'Nenhum caixa encontrado.';
            }
          })
          .catch(error => {
            this.loading = false;
            this.infoMessage = error.message;
          });
      },

      formatCashAccountingsResume(accumulator, currentValue) {
        const { totalDependants, totalUsers, localName, totalValue, totalCash, totalDebit, totalCredit } = currentValue;

        accumulator.totalDependants = (accumulator.totalDependants || 0) + totalDependants;
        accumulator.totalUsers = (accumulator.totalUsers || 0) + totalUsers;
        accumulator.totalValue = (Number(accumulator.totalValue || 0) + Number(totalValue)).toFixed(2);
        accumulator.totalCash = (Number(accumulator.totalCash || 0) + Number(totalCash)).toFixed(2);
        accumulator.totalDebit = (Number(accumulator.totalDebit || 0) + Number(totalDebit)).toFixed(2);
        accumulator.totalCredit = (Number(accumulator.totalCredit || 0) + Number(totalCredit)).toFixed(2);
        accumulator.localName = localName;

        return accumulator;
      },

      loadCashAccounting() {
        this.infoMessage = 'Carregando...';

        const params = {
          page: this.currentPage,
          search: this.search,
          per_page: this.perPage,
          id: cashAccountingId
        };

        const api = Routes.sales_dashboard_cash_accounting_open_json_path(params);

        fetch(api, { cache: 'no-cache' })
          .then(response => response.json())
          .then(data => {
            this.infoMessage = '';
            this.loading = false;

            let { cash_accounting, subscriptions } = data;

            if (cash_accounting) {
              cash_accounting.subscriptions = subscriptions || [];

              this.cashAccounting = this.formatCashAccounting(cash_accounting);
              this.subscriptions = subscriptions.map(this.formatSubscription);
            } else {
              this.infoMessage = 'Nenhum caixa aberto no momento, o mesmo será aberto no momento que for criado o primeiro cliente.';
            }
          })
          .catch(_error => {
            this.infoMessage = 'Erro ao procurar pelo caixa. Por favor tente novamente!';
          });
      },

      loadLocals() {
        this.localsInfoMessage = 'Carregando...';

        const params = { search: this.modalSearch };

        fetch(Routes.sales_dashboard_locals_by_search_json_path(params), { cache: 'no-cache' })
          .then(response => response.json())
          .then(localsData => {
            this.localsInfoMessage = '';

            this.locals = localsData.locals.map(local => this.formatLocalOption(local));

            if (this.locals.length) {
              this.locals = this.locals.map((local) => {
                local.selected = local.id === this.selectedLocalId;

                return local;
              });
            } else {
              this.localsInfoMessage = 'Nenhuma unidade encontrada.';
            }
          })
          .catch((_error) => {
            this.localsInfoMessage = 'Erro ao listar às unidades. Por favor tente novamente!';
          });
      },

      formatLocalOption(local) {
        const { id, name } = local;

        return { id, name, selected: false };
      },

      selectLocal(selectedLocal) {
        this.locals = this.locals.map((local) => {
          local.selected = local.id === selectedLocal.id;

          return local;
        });

        const [{ id, name }] = this.locals.filter(local => local.selected);
        this.selectedLocalId = id;
        this.selectedLocalName = name;
      },

      mountArrayPages(totalPage) {
        const pages = [];
        for (let i = 1; i <= totalPage; i++) {
          pages.push(i);
        }
        return pages;
      },

      showPage(page) {
        this.currentPage = page;
        this.init();
      },

      onPerPageSelect(event) {
        this.perPage = event.target.value;
        this.showPage(1);
      },

      onSearch() {
        this.infoMessage = 'Carregando...';
        this.currentPage = 1;
        this.cashAccountings = [];
        this.subscriptions = [];

        clearTimeout(this.debounceTimer);
        this.debounceTimer = setTimeout(() => {
          this.debounceTimer = null;
          this.init();
        }, 1000);
      },

      between(x, min, max) {
        return x >= min && x <= max;
      },

      formatCashAccounting(cashAccounting) {
        const href = this.cashAccountingPath(cashAccounting.id);
        const reportPath = this.salesDashboardCashAccountingLocalSellerReportPath(cashAccounting.id, { format: "pdf" });

        let statusClass = {}
        statusClass[`${cashAccounting.status}`] = true;

        return {
          id: cashAccounting.id,
          referenceDate: formatDateTime(cashAccounting.reference_date),
          monthReferenceDate: getMonthByDate(cashAccounting.reference_date),
          localName: cashAccounting.local.name,
          sellerName: cashAccounting.seller.name,
          status: cashAccounting.status,
          statusClass: statusClass,
          totalUsers: cashAccounting.subscriptions.length,
          totalDependants: cashAccounting.dependants.length,
          totalValue: cashAccounting.total_value,
          totalCash: cashAccounting.total_cash,
          totalDebit: cashAccounting.total_debit_card,
          totalCredit: cashAccounting.total_credit_card,
          href,
          reportPath
        }
      },

      openCashAccountingModal() {
        document.getElementById('cash-closing-modal').style.display = "flex";
        document.getElementById('container-info').style.display = "block";
        if (isManager) {
          this.loadLocals();          
        }

        const openModal = document.getElementById('cash-closing-modal');
        window.addEventListener('click', event => {
          if (event.target == openModal) {
            this.closeCashAccountingModal();
          }
        });
      },

      closeCashAccountingModal() {
        document.getElementById('cash-closing-modal').style.display = "none";
      },

      confirmClosingCashAccounting() {
        this.loading = true;

        const params = {
          method: 'POST',
          cache: 'no-cache',
          body: '',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': Rails.csrfToken(),
          },
        };

        const id = this.cashAccounting.id;

        fetch(Routes.sales_dashboard_confirm_cash_accounting_closing_path(id), params)
          .then(response => response.json())
          .then(data => {
            this.infoErrorMessage = '';
            this.loading = false;

            if (data.status === 204) {
              const href = Routes.sales_dashboard_cash_accountings_path({ cash_accounting_status: 'closed' });
              this.redirectPage(href);
            } else {
              this.infoErrorMessage = 'Atenção: Não foi possível fechar o caixa, por favor tente novamente em instantes';
            }
          })
          .catch(_error => {
            this.infoErrorMessage = '';
            this.loading = false;
          });
      },

      openModal() {
        document.getElementById('confirm-report-modal').style.display = "flex";

        const modalElement = document.getElementById('confirm-report-modal');
        window.addEventListener('click', event => {
          if (event.target == modalElement) {
            this.closeModal();
          }
        });
      },

      closeModal() {
        if (this.notification && this.notification.status === 'processing') return;
        
        this.notification = null;
        this.initialDate = new Date()
        this.endDate = new Date()
        
        document.getElementById('confirm-report-modal').style.display = "none";
      },

      generateManagementReport() {
        if (new Date(this.initialDate) > new Date(this.endDate)) {
          this.infoErrorMessage = "A data de início deve ser maior ou igual a data fim."
          return
        }

        if (isManager && !this.selectedLocalId) {
          this.infoErrorMessage = "A unidade não pode ficar em branco."
          return
        }

        const params = { initial_date: this.initialDate, end_date: this.endDate, local_id: this.selectedLocalId }
        fetch(Routes.sales_dashboard_generate_management_report_path(params), { cache: 'no-cache' })
        .then(response => response.json())
        .then(data => { 
          if (data.notification) {
            this.infoErrorMessage = ''
            this.notification = data.notification;
            this.managementReportStatus(this.notification)
          }
        })          
        .catch(_error => {
          this.infoErrorMessage = 'Erro ao gerar o relatório gerencial. Por favor tente novamente!';
        });
      },

      managementReportStatus(notification) {
        fetch(Routes.sales_dashboard_management_report_status_path({ notification_id: notification.id }), { cache: 'no-cache' })
        .then(response => response.json())
        .then(data => {
          if (data && data.notification) {
            clearInterval(this.managementReportInterval);
            if (data.notification.status == 'success') {
              this.notification = data.notification;
              this.managementReportUrl = data.management_report_url
            } else if (data.notification.status == 'processing') {
              this.notification = data.notification;
              this.managementReportInterval = setInterval(this.managementReportStatus(notification), 60000);
            } else if (data.notification.status == 'error') {
              this.notification = data.notification;
            }
          }
        })
      },

      managementReportDownload() {
        window.location.href = this.managementReportUrl
        this.closeModal()
      },

      limit,
      redirectPage,
    }
  })

  app.init()
}