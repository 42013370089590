import Vue from 'vue/dist/vue.esm'
import Rails from '@rails/ujs'
import { calculateDays, translateStatusCancellation } from '../../shared/utils'

export const initShowRequest = (customerId, cancellationRequestId, currentAdminId, statusRequest) => {
  if (!document.getElementById('show-request-sales-dashboard')) return

  const app = new Vue({
    el: '#show-request-sales-dashboard',
    data: {
      comments: [],
      statusRequests: [],
      status: statusRequest,
      txtComment: '',
      interval: null,
      isOpenRefuseModal: false,
      txtRefuse: '',
      isOpenApproveModal: false,
      txtApprove: '',
    },
    computed: {},
    methods: {
      translateStatusCancellation,

      listComments() {
        const api = Routes.sales_dashboard_list_cancellation_request_comments_json_path(
          customerId,
          cancellationRequestId
        )
        fetch(api, { cache: 'no-cache' })
          .then(response => response.json())
          .then(data => {
            this.comments = data.comments.map(this.formatComment)
          })
      },

      listStatus() {
        const api = Routes.sales_dashboard_list_cancellation_request_statuses_json_path(
          customerId,
          cancellationRequestId
        )
        fetch(api, { cache: 'no-cache' })
          .then(response => response.json())
          .then(data => {
            this.statusRequests = data.status_requests.map(this.formatStatus)
          })
      },

      comment() {
        if (!this.txtComment.trim()) return;

        const body = {
          comment: this.txtComment,
        }

        const params = {
          method: 'POST',
          cache: 'no-cache',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': Rails.csrfToken(),
          },
        }

        const api = Routes.sales_dashboard_comentate_cancellation_request_json_path(
          customerId,
          cancellationRequestId
        )
        fetch(api, params)
          .then(response => response.json())
          .then(data => {
            this.comments = [...this.comments, this.formatComment(data.comment)]
          })

        this.txtComment = ''
      },

      formatComment(comment) {
        return {
          comment: comment.comment,
          adminName: comment.admin.name,
          myComment: (comment.admin.id == currentAdminId)
        }
      },

      openApproveModal() {
        this.isOpenApproveModal = true
        this.txtApprove = ''

        const approveModal = document.getElementById('approveModal')
        window.addEventListener('click', event => {
          if (event.target == approveModal) {
            this.closeModals()
          }
        })
      },
      
      openRefuseModal() {
        this.isOpenRefuseModal = true
        this.txtRefuse = ''

        const refuseModal = document.getElementById('refuseModal')
        window.addEventListener('click', event => {
          if (event.target == refuseModal) {
            this.closeModals()
          }
        })
      },

      closeModals() {
        this.isOpenApproveModal = false
        this.isOpenRefuseModal = false
      },

      formatStatus(statusRequest) {
        return {
          title: this.translateStatusCancellation(statusRequest),
          status: statusRequest.status,
          description: statusRequest.description,
          adminName: statusRequest.admin.name,
          numDays: calculateDays(statusRequest.created_at),
        }
      },

      refreshCommentsAndStatus() {
        clearInterval(this.interval)
        this.interval = setInterval(() => {
          this.listComments()
          this.listStatus()
        }, 60000)
      },

      init() {
        this.listComments()
        this.listStatus()
        this.refreshCommentsAndStatus()
      }
    }
  })

  app.init()
}