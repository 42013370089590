import {
  maskPhones,
  maskPhoneText,
  initFaq,
  scrollToTop,
  initInfiniteScroll,
  findBInA,
} from '../../shared/utils'
import Rails from '@rails/ujs'
import Vue from 'vue/dist/vue.esm'
import TurbolinksAdapter from 'vue-turbolinks'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

Vue.use(TurbolinksAdapter)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

const initNewFilter = (classification, category) => {
  if (!document.getElementById('vue-filter')) return

  let headers = {
    'Content-Type': 'application/json',
    'X-CSRF-Token': Rails.csrfToken(),
  }

  let tenWhiteSpaces =
    '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'

  var app = new Vue({
    el: '#vue-filter',
    data: {
      classification: classification || '',
      category: category || '',
      partners: [],
      categoriesButtonText: 'Selecionar categorias',
      subCategoriesButtonText: 'Selecionar especialidades',
      statesButtonText: 'Estado',
      mobileStatesButtonText: 'Selecionar estado',
      citiesButtonText: 'Cidade',
      mobileCitiesButtonText: 'Selecionar cidade',
      availableCategories: [],
      availableSubCategories: [],
      availableStates: [],
      availableCities: [],
      categoriesOptions: [],
      subCategoriesOptions: [],
      statesOptions: [],
      citiesOptions: [],
      selectedCategories: [],
      selectedSubCategories: [],
      selectedState: '',
      selectedCity: '',
      categorySearchInput: '',
      subCategorySearchInput: '',
      stateSearchInput: '',
      citySearchInput: '',
      nameSearchInput: '',
      filteringByCategory: false,
      filteringBySubCategory: false,
      filteringByState: false,
      filteringByCity: false,
      filteringByName: false,
      showCategoriesPopover: false,
      showSubCategoriesPopover: false,
      showStatesPopover: false,
      showCitiesPopover: false,
      showMainModalArea: true,
      showCategoriesModalArea: false,
      showSubCategoriesModalArea: false,
      showStatesModalArea: false,
      showCitiesModalArea: false,
      firstPartnersLoaded: false,
      completePartnersLoaded: false,
      shownPagesCount: 1,
      maxShownPagesCount: 1,
      totalPartnerCount: 0,
      sectionConfigs: {
        default: {
          limit: 3000,
        },
      },
    },
    methods: {
      initFilterWithSession() {
        fetch(Routes.partner_filtering_session_path(), {
          cache: 'no-cache',
        }).then((response) => {
          response.json().then((data) => {
            this.classification = data.classification
            this.category = data.category
            if (data.categories && data.categories.length > 0) {
              this.filteringByCategory = true
              this.selectedCategories = data.categories
            }
            if (data.sub_categories && data.sub_categories.length > 0) {
              this.filteringBySubCategory = true
              this.selectedSubCategories = data.sub_categories
            }
            if (data.state) {
              this.filteringByState = true
              this.selectedState = data.state
            }
            if (data.city) {
              this.filteringByCity = true
              this.selectedCity = data.city
            }
            app.fetchCategories()
            app.fetchSubCategories()
            app.fetchStates()
            app.fetchCities()
            app.fetchPartnerPage(1)
          })
        })
      },
      
      fetchCategories() {
        let params = {
          classification: this.classification,
          category: this.category,
        }
        fetch(Routes.partner_categories_json_path(), {
          method: 'POST',
          body: JSON.stringify(params),
          headers: headers,
          cache: 'no-cache',
        }).then((response) => {
          response.json().then((data) => {
            this.availableCategories = data
            this.categoriesOptions = data
          })
        })
      },
      
      fetchSubCategories() {
        let params = {
          classification: this.classification,
          category: this.category,
          categories: this.selectedCategories,
          city: this.selectedCity,
        }
        fetch(Routes.partner_sub_categories_json_path(), {
          method: 'POST',
          body: JSON.stringify(params),
          headers: headers,
          cache: 'no-cache',
        }).then((response) => {
          response.json().then((data) => {
            this.availableSubCategories = data
            this.subCategoriesOptions = data
          })
        })
      },
      
      fetchStates() {
        let params = {
          classification: this.classification,
          category: this.category,
          categories: this.selectedCategories,
          sub_categories: this.selectedSubCategories,
        }
        fetch(Routes.partner_states_json_path(), {
          method: 'POST',
          body: JSON.stringify(params),
          headers: headers,
          cache: 'no-cache',
        }).then((response) => {
          response.json().then((data) => {
            let statesOptions = data.map((state) => {
              return { value: state.name, text: state.full_name }
            })
            this.availableStates = statesOptions
            this.statesOptions = statesOptions
          })
        })
      },
      
      fetchCities() {
        let params = {
          classification: this.classification,
          category: this.category,
          categories: this.selectedCategories,
          sub_categories: this.selectedSubCategories,
          state: this.selectedState,
        }
        fetch(Routes.partner_cities_json_path(), {
          method: 'POST',
          body: JSON.stringify(params),
          headers: headers,
          cache: 'no-cache',
        }).then((response) => {
          response.json().then((data) => {
            let citiesOptions = data.map((city) => {
              return { value: city.name, text: city.name }
            })
            this.availableCities = citiesOptions
            this.citiesOptions = citiesOptions
          })
        })
      },
      
      fetchPartnerPage(number) {
        this.completePartnersLoaded = false
        if (number == 1) this.firstPartnersLoaded = false

        let params = {
          classification: this.classification,
          category: this.category,
          categories: this.selectedCategories,
          sub_categories: this.selectedSubCategories,
          city: this.selectedCity,
          state: this.selectedState,
          name: this.nameSearchInput,
          page: number,
        }

        fetch(Routes.partners_json_path(), {
          method: 'POST',
          body: JSON.stringify(params),
          headers: headers,
          cache: 'no-cache',
        }).then((response) => {
          response.json().then((data) => {
            if (data.page == 1) {
              this.partners = []
              this.shownPagesCount = 1
            }

            this.partners = this.partners.concat(data.partners)
            this.firstPartnersLoaded = true
            this.maxShownPagesCount = data.total_pages
            this.totalPartnerCount = data.total_count
            this.completePartnersLoaded = true
          })
        })
        .catch((error) => {
          this.firstPartnersLoaded = true
          this.completePartnersLoaded = true
          this.maxShownPagesCount = 0
          this.totalPartnerCount = 0
        });
      },
      
      applyCategoriesFilter() {
        this.showCategoriesPopover = false
        if (this.selectedCategories.length > 0) {
          this.filteringByCategory = true
        } else {
          this.filteringByCategory = false
        }
        this.updateCategoriesButtonText()
        this.toggleMainModalArea()
        this.resetSubCategories()
      },
      
      applySubCategoriesFilter() {
        this.showSubCategoriesPopover = false
        if (this.selectedSubCategories.length > 0) {
          this.filteringBySubCategory = true
        } else {
          this.filteringBySubCategory = false
        }
        this.updateSubCategoriesButtonText()
        this.toggleMainModalArea()
        this.fetchPartnerPage(1)
      },

      applyStateFilter() {
        if (this.selectedState) {
          this.filteringByState = true
        } else {
          this.filteringByState = false
        }
        this.showStatesPopover = false
        this.updateStatesButtonText()
        this.toggleMainModalArea()
        this.resetCity()
      },

      applyCityFilter() {
        if (this.selectedCity) {
          this.filteringByCity = true
        } else {
          this.filteringByCity = false
        }
        this.showCitiesPopover = false
        this.updateCitiesButtonText()
        this.toggleMainModalArea()
        this.fetchPartnerPage(1)
      },

      applyNameFilter() {
        if (this.nameSearchInput) {
          this.filteringByName = true
        } else {
          this.filteringByName = false
        }
        this.fetchPartnerPage(1)
      },

      filterCategoriesOptions() {
        let filteredCategoriesOptions = this.availableCategories.filter(
          (category) => findBInA(category, this.categorySearchInput)
        )
        this.categoriesOptions = filteredCategoriesOptions
      },

      filterSubCategoriesOptions() {
        let filteredSubCategoriesOptions = this.availableSubCategories.filter(
          (subCategory) =>
            findBInA(`${subCategory.name}`, this.subCategorySearchInput)
        )
        this.subCategoriesOptions = filteredSubCategoriesOptions
      },

      filterStatesOptions() {
        let filteredStatesOptions = this.availableStates.filter((state) =>
          findBInA(`${state.text}`, this.stateSearchInput)
        )
        this.statesOptions = filteredStatesOptions
      },

      filterCitiesOptions() {
        let filteredCitiesOptions = this.availableCities.filter((city) =>
          findBInA(`${city.text}`, this.citySearchInput)
        )
        this.citiesOptions = filteredCitiesOptions
      },

      resetCategories() {
        this.selectedCategories = []
        this.categorySearchInput = ''
        this.filteringByCategory = false
        this.showCategoriesPopover = false
        this.updateCategoriesButtonText()
        this.toggleMainModalArea()
        this.filterCategoriesOptions()
        this.resetSubCategories()
      },

      resetSubCategories() {
        this.subCategoriesOptions = []
        this.selectedSubCategories = []
        this.subCategorySearchInput = ''
        this.filteringBySubCategory = false
        this.showSubCategoriesPopover = false
        this.updateSubCategoriesButtonText()
        this.toggleMainModalArea()
        this.fetchSubCategories()
        this.fetchPartnerPage(1)
      },

      resetState() {
        this.selectedState = ''
        this.stateSearchInput = ''
        this.filteringByState = false
        this.showStatesPopover = false
        this.updateStatesButtonText()
        this.toggleMainModalArea()
        this.fetchStates()
        this.resetCity()
      },

      resetCity() {
        this.selectedCity = ''
        this.citySearchInput = ''
        this.filteringByCity = false
        this.showCitiesPopover = false
        this.updateCitiesButtonText()
        this.toggleMainModalArea()
        this.fetchCities()
        this.fetchPartnerPage(1)
      },

      resetName() {
        this.nameSearchInput = ''
        this.applyNameFilter()
      },

      resetAllFilters() {
        this.resetCategories()
        this.resetState()
        this.resetName()
      },

      updateCategoriesButtonText() {
        if (this.filteringByCategory) {
          let [firstCategory] = this.selectedCategories
          let shouldTruncateCategoryName = firstCategory.length > 15
          if (shouldTruncateCategoryName) {
            firstCategory = `${firstCategory.substring(0, 10)}...`
          }
          if (this.selectedCategories.length > 1) {
            let counter = this.selectedCategories.length - 1
            if (shouldTruncateCategoryName) {
              this.categoriesButtonText = `${firstCategory}${tenWhiteSpaces}+${counter}`
            } else {
              this.categoriesButtonText = `${firstCategory}...${tenWhiteSpaces}+${counter}`
            }
          } else {
            this.categoriesButtonText = firstCategory
          }
        } else {
          this.categoriesButtonText = 'Selecionar categorias'
        }
      },

      updateSubCategoriesButtonText() {
        if (this.filteringBySubCategory) {
          let [firstSubCategoryId] = this.selectedSubCategories
          let [firstSubCategory] = this.availableSubCategories.filter((sc) => {
            return sc.id == firstSubCategoryId
          })
          let firstSubCategoryName = firstSubCategory.name
          let shouldTruncateSubCategoryName = firstSubCategoryName.length > 15
          if (shouldTruncateSubCategoryName) {
            firstSubCategoryName = `${firstSubCategoryName.substring(0, 10)}...`
          }
          if (this.selectedSubCategories.length > 1) {
            let counter = this.selectedSubCategories.length - 1
            if (shouldTruncateSubCategoryName) {
              this.subCategoriesButtonText = `${firstSubCategoryName}${tenWhiteSpaces}+${counter}`
            } else {
              this.subCategoriesButtonText = `${firstSubCategoryName}...${tenWhiteSpaces}+${counter}`
            }
          } else {
            this.subCategoriesButtonText = firstSubCategoryName
          }
        } else {
          this.subCategoriesButtonText = 'Selecionar especialidades'
        }
      },

      updateStatesButtonText() {
        if (this.filteringByState) {
          this.statesButtonText = this.selectedState
          this.mobileStatesButtonText = this.selectedState
        } else {
          this.statesButtonText = 'Estado'
          this.mobileStatesButtonText = 'Selecionar estado'
        }
      },

      updateCitiesButtonText() {
        if (this.filteringByCity) {
          this.citiesButtonText = this.selectedCity
          this.mobileCitiesButtonText = this.selectedCity
        } else {
          this.citiesButtonText = 'Cidade'
          this.mobileCitiesButtonText = 'Selecionar cidade'
        }
      },

      toggleMainModalArea() {
        this.showMainModalArea = true
        this.showCategoriesModalArea = false
        this.showSubCategoriesModalArea = false
        this.showStatesModalArea = false
        this.showCitiesModalArea = false
      },

      toggleCategoriesModalArea() {
        this.showMainModalArea = false
        this.showCategoriesModalArea = true
      },

      toggleSubCategoriesModalArea() {
        this.showMainModalArea = false
        this.showSubCategoriesModalArea = true
      },

      toggleStatesModalArea() {
        this.showMainModalArea = false
        this.showStatesModalArea = true
      },

      toggleCitiesModalArea() {
        this.showMainModalArea = false
        this.showCitiesModalArea = true
      },

      maskPhone(phone) {
        return maskPhoneText(phone)
      },

      seeMorePages() {
        if (this.shownPagesCount < this.maxShownPagesCount) {
          this.shownPagesCount += 1
          this.fetchPartnerPage(this.shownPagesCount)
        }
      },

      mountDescription(partner) {
        const descriptions = [
          partner.first_classification_name,
          partner.first_category_name,
          partner.first_sub_category_name,
        ];

        return descriptions.filter(desc => !!desc).join(' - ');
      },

      removeUrlPrefix(url) {
        if (!url) return url;

        return url.replace('http://', '')
          .replace('https://', '');
      },
    },
  })

  app.initFilterWithSession()
}

window.pages = window.pages || {}
window.pages.site = window.pages.site || {}
window.pages.site.partners = {
  maskPhones,
  initFaq,
  initNewFilter,
  initInfiniteScroll,
  scrollToTop,
}
