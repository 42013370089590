import Vue from 'vue/dist/vue.esm';
import Base64 from 'base-64';

export function initNewCustomerPage(gatewayPublicKey, gatewayPublicApi) {
  if (!document.getElementById('new-customer-sales-dashboard')) return

  const email = document.getElementById('txt_email').value || null
  const phone = document.getElementById('txt_phone').value || null
  const paymentMethod = document.getElementById('payment_method').value || 'link_by_email'
  const planId = document.getElementById('plan_id').value || null
  let plans = JSON.parse(document.getElementById('plans').value || '[]')
  plans = plans.map(plan => {
    if (planId && plan.id == planId) {
      return { ...plan, selected: true }
    } else {
      return plan
    }
  })

  const app = new Vue({
    el: '#new-customer-sales-dashboard',
    data: {
      plans: plans,
      selectedPaymentMethod: paymentMethod,
      email: email,
      phone: phone,
      planId: planId,
      cardNumber: '',
      holderName: '',
      cardCvv: '',
      cardExpiration: '',
      loading: '',
      holderNameErrors: [],
      cardCvvErrors: [],
      cardExpirationErrors: [],
      cardNumberErrors: [],
    },
    computed: {},
    methods: {
      init() {
        this.selectFirstPlan()
      },

      selectPlan(selectedPlan) {
        this.planId = selectedPlan.id
        this.plans = this.plans.map(plan => {
          if (selectedPlan.id === plan.id) {
            return { ...plan, selected: true }
          }
          return { ...plan, selected: false }
        })
      },

      selectFirstPlan() {
        if (this.plans.length > 1 && this.planId == null) {
          const plan = plans[0]
          plan.selected = true
          this.planId = plan.id
        }
      },

      handleSubmit() {
        document.getElementById('customer-form').submit();
        return;
      }
    }
  })

  app.init()
}
