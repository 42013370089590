import Vue from 'vue/dist/vue.esm';

export function initConfirmSubscriptionRenewal(customer, currentPaymentMethod, gatewaySubs) {
  if (!document.getElementById('new-customer-sales-dashboard')) return

  const paymentMethod = gatewaySubs === "true" ? getValue('payment_method') :  currentPaymentMethod;
  const planId = getValue('plan_id');
  const plans = getPlans();
  const name = customer.name;
  const cpf = customer.cpf;
  const gender = customer.gender;
  const birthDate = customer.birthDate;
  const email = customer.email;
  const phone = customer.phone;
  const additionalInfo = customer.additionalInfo;
  const zipCode = customer.zipCode;
  const street = customer.street;
  const number = customer.number;
  const complement = customer.complement;
  const district = customer.district;
  const city = customer.city;
  const state = customer.state;

  function getPlans() {
    return JSON.parse(getValue('plans') || '[]').map(formatPlan);
  }

  function formatPlan(plan) {
    if (planId && plan.id == planId) {
      return { ...plan, selected: true };
    } else {
      return plan;
    }
  }

  function getValue(id) {
    return document.getElementById(id).value || null;
  }

  const app = new Vue({
    el: '#new-customer-sales-dashboard',
    data: {
      plans: plans,
      selectedPaymentMethod: paymentMethod,
      email: email,
      phone: phone,
      planId: planId,
      name: name,
      cpf: cpf,
      gender: gender,
      birthDate: birthDate,
      additionalInfo: additionalInfo,
      zipCode: zipCode,
      street: street,
      number: number,
      complement: complement,
      district: district,
      city: city,
      state: state,
      loading: false
    },
    computed: {},
    methods: {
      selectPlan(selectedPlan) {
        this.planId = selectedPlan.id
        this.plans = this.plans.map(plan => {
          if (selectedPlan.id === plan.id) {
            return { ...plan, selected: true }
          }
          return { ...plan, selected: false }
        })
      },

      selectFirstPlan() {
        if (this.plans.length > 1 && this.planId == null) {
          const plan = plans[0]
          plan.selected = true
          this.planId = plan.id
        }
      },

      getSelectedPlan() {
        if (!this.planId) return null;
        return this.plans.find(p => p.id == this.planId);
      },

      nextStep() {
        document.getElementById('renewal-form').submit();
      },
    }
  })

  app.selectFirstPlan()
}
