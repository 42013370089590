import {
  formMasks,
  maskPhones,
  searchByEmail,
  initZipCodeAutocomplete,
} from '../shared/utils'

window.pages = window.pages || {}
window.pages.companies = {
  formMasks,
  maskPhones,
  searchByEmail,
  initZipCodeAutocomplete,
}
