import {
  translateStatus,
  reverseTranslatedStatus,
  goToByScroll,
  redirectPage,
  limit,
  formatPhone,
  formatDate
} from '../../shared/utils';
import Vue from 'vue/dist/vue.esm';
import Rails from '@rails/ujs';

export const initCustomersSearch = () => {
  if (!document.getElementById('customers-list-sales-dashboard')) return

  const app = new Vue({
    el: '#customers-list-sales-dashboard',
    data: {
      customers: [],
      search: '',
      current_page: 1,
      total_count: 0,
      total_filtered_count: 0,
      total_pages: 0,
      per_page: 20,
      pages: [],
      loading: false,
      infoMessage: '',
      debounceTimer: null,
      status: ['Todos', 'Pendente', 'Ativo', 'Cancelado', 'Expirado', 'Aguardando aprovação'],
      selectedStatus: '',
      voucherSearch: '',
      vouchers: [],
      activeVoucher: false,
      customerPath: Routes.sales_dashboard_customer_path,
      confirmCustomerPath: Routes.sales_dashboard_show_confirm_customer_data_path,
      findVoucherPath: Routes.vouchers_find_voucher_json_path,
      useVoucherPath: Routes.vouchers_use_voucher_json_path,
    },
    computed: {},
    methods: {
      init() {
        this.loadCustomers();
      },

      loadCustomers() {
        this.loading = true
        this.infoMessage = 'Carregando...'

        const params = {
          page: this.current_page,
          search: this.search,
          per_page: this.per_page,
          draft_only: this.selectedStatus === 'Aguardando aprovação',
          status: `${this.selectedStatus === 'Todos' || this.selectedStatus === 'Aguardando aprovação'
            ? ''
            : reverseTranslatedStatus(this.selectedStatus)
            }`
        }

        fetch(Routes.sales_dashboard_customers_json_path(params), {
          cache: 'no-cache'
        })
          .then(response => response.json())
          .then(data => {
            this.loading = false
            this.infoMessage = ''
            this.customers = data.customers.map(this.formatUser)
            this.current_page = data.page
            this.total_count = data.total_count
            this.total_filtered_count = data.total_filtered_count
            this.total_pages = data.total_pages
            this.per_page = data.per_page
            this.pages = this.mountArrayPages(this.total_pages)

            if (!this.customers.length) {
              this.infoMessage = 'Nenhum usuário encontrado.'
            }
          })
          .catch(_error => {
            this.infoMessage =
              'Erro ao listar usuários. Por favor tente novamente!'
          })
      },

      mountArrayPages(totalPage) {
        const pages = []
        for (let i = 1; i <= totalPage; i++) {
          pages.push(i)
        }
        return pages
      },

      showPage(page) {
        this.current_page = page
        this.loadCustomers()
      },

      onPerPageSelect(event) {
        this.per_page = event.target.value
        this.showPage(1)
      },

      onSearch() {
        this.infoMessage = 'Carregando...'
        this.current_page = 1
        this.customers = []

        clearTimeout(this.debounceTimer)
        this.debounceTimer = setTimeout(() => {
          this.debounceTimer = null
          this.loadCustomers()
        }, 1000)
      },

      mountSubscriptionStatusClass(subscriptionStatus) {
        let subscriptionStatusClass = {}

        switch (subscriptionStatus) {
          case 'active':
            subscriptionStatusClass['active'] = true
            return subscriptionStatusClass
          case 'pending_checkout':
            subscriptionStatusClass['pending'] = true
            return subscriptionStatusClass
          case 'canceled':
            subscriptionStatusClass['canceled'] = true
            return subscriptionStatusClass
          case 'expired':
            subscriptionStatusClass['expired'] = true
            return subscriptionStatusClass
          case 'pending_on_protheus':
            subscriptionStatusClass['pending'] = true
            return subscriptionStatusClass
          case 'on_canceling':
            subscriptionStatusClass['pending'] = true
            return subscriptionStatusClass
          default:
            subscriptionStatusClass['default'] = true
            return subscriptionStatusClass
        }
      },

      formatUser(user) {
        const companyName = user.company ? user.company.name : ''
        const companyId = user.company ? user.company.id : ''
        const isDraft = !!user.is_draft
        const subscriptionStatus = user.subscription ? user.subscription.status : ''
        const subscriptionStatusTranslated = translateStatus(subscriptionStatus)
        const href = isDraft ? this.confirmCustomerPath(user.id) : this.customerPath(user.id)

        return {
          id: user.id,
          name: user.name,
          cpf: user.cpf,
          email: user.email,
          phone: formatPhone(user.phone),
          subscriptionStatusClass: this.mountSubscriptionStatusClass(isDraft ? '' : subscriptionStatus),
          companyName,
          companyId,
          subscriptionStatus,
          dependantsCount: user.dependants.length || 0,
          subscriptionStatusTranslated,
          isDraft,
          href
        }
      },

      openFindVouchersModal() {
        this.voucherSearch = '';
        this.vouchers = [];
        document.getElementById('find-vouchers-modal').style.display = "flex";

        const openModal = document.getElementById('find-vouchers-modal');
        window.addEventListener('click', event => {
          if (event.target == openModal) {
            this.closeFindVouchersModal();
          }
        });
      },

      closeFindVouchersModal() {
        document.getElementById('find-vouchers-modal').style.display = "none";
      },

      loadVouchers() {
        this.loading = true;
        this.activeVoucher = false;
        this.vouchers = [];
        fetch(this.findVoucherPath({ token: this.voucherSearch }), { cache: 'no-cache' })
          .then(response => response.json())
          .then(data => {
            this.loading = false;
            if (!!data.voucher) {
              this.activeVoucher = data.voucher.status === 'active';
              this.vouchers.push(data.voucher);
            }
          })
          .catch(_error => {
            this.loading = false;
          });
      },

      useVoucher() {
        if (!this.activeVoucher) return;

        const params = {
          method: 'PATCH',
          cache: 'no-cache',
          body: '',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': Rails.csrfToken(),
          },
        };

        this.loading = true;
        fetch(this.useVoucherPath({ token: this.voucherSearch }), params)
          .then(response => response.json())
          .then(_data => {
            this.loading = false;
            this.loadVouchers();
          })
          .catch(_error => {
            this.loading = false;
          });
      },

      redirectPage,
      limit,
      formatDate,
    }
  })

  app.init()
}

export const initTabAnchors = () => {
  $('.tab.general-info').off()
  $('.tab.personal-data').off()
  $('.tab.address').off()
  $('.tab.dependants').off()
  $('.tab.payment-method').off()

  $('.tab.general-info').click(e => {
    $('.tab').removeClass('active')
    $('.tab.general-info').addClass('active')
    $(window).scrollTop(0)
  })

  $('.tab.personal-data').click(e => {
    $('.tab').removeClass('active')
    $('.tab.personal-data').addClass('active')
    goToByScroll('#p-data', 130)
  })

  $('.tab.address').click(e => {
    $('.tab').removeClass('active')
    $('.tab.address').addClass('active')
    goToByScroll('#addrs', 130)
  })

  $('.tab.dependants').click(e => {
    $('.tab').removeClass('active')
    $('.tab.dependants').addClass('active')
    goToByScroll('#depslist', 130)
  })

  $('.tab.payment-method').click(e => {
    $('.tab').removeClass('active')
    $('.tab.payment-method').addClass('active')
    goToByScroll('#pay-method', 130)
  })
}
