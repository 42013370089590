import { initSearch, formMasks } from '../shared/utils'

const initShowSellerProfileAttributes = () => {
  $('#admin_seller').ready(() => {
    let checked = $('#admin_seller')[0].checked
    if (checked) {
      $('.seller_profile_attributes').removeClass('d-none')
    } 
  })

  $('#admin_seller').change(() => {
    let checked = $('#admin_seller')[0].checked
    if (checked) {
      $('.seller_profile_attributes').removeClass('d-none')
    } else {
      $('.seller_profile_attributes').addClass('d-none')
    }
  })
}

const initShowRegionName = () => {
  $('#select_role').ready(() => {
    let value = $('#select_role').val()
    if (value == 'regional' || value == 'local') {
      $('.region_name_attribute').removeClass('d-none')
    } 
  })

  $('#select_role').change(() => {
    let el = document.getElementById('region_name')
    let value = $('#select_role').val()

    if (value == 'regional' || value == 'local') {
      $('.region_name_attribute').removeClass('d-none')
    } else {
      $('.region_name_attribute').addClass('d-none')
      el.value = ""
    }
  })
}

window.pages = window.pages || {}
window.pages.admins = {
  initSearch: initSearch,
  formMasks,
  initShowSellerProfileAttributes,
  initShowRegionName
}
