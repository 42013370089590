import {
  initSearch,
  limit,
  formatDate,
} from '../../shared/utils'
import Vue from 'vue/dist/vue.esm'

const initLgpdReportSearch = () => {
  if (!document.getElementById('lgpd-report-container')) return

  const app = new Vue({
    el: '#lgpd-report-container',
    data: {
      users: [],
      search: '',
      birthDate: '',
      currentPage: 1,
      totalCount: 0,
      totalPages: 0,
      pages: [],
      msgInfoListUsers: '',
      debounceTimer: null,
      userLgpdPath: Routes.admin_dashboard_show_user_path,
    },
    computed: {},
    methods: {
      loadUsers() {
        this.msgInfoListUsers = 'Carregando...'

        const params = {
          page: this.currentPage,
          search: this.search,
          birth_date: this.birthDate
        }
        fetch(Routes.admin_dashboard_lgpd_report_users_json_path(params), {
            cache: 'no-cache'
          })
          .then(response => response.json())
          .then(data => {
            this.msgInfoListUsers = ''
            this.users = data.users.map(this.formatUser)
            this.currentPage = data.page
            this.totalCount = data.total_count
            this.totalPages = data.total_pages
            this.pages = this.mountArrayPages(this.totalPages)

            if (!this.users.length) {
              this.msgInfoListUsers = 'Nenhum usuário encontrado.'
            }
          })
          .catch(_error => {
            this.msgInfoListUsers =
              'Erro ao listar usuários. Por favor tente novamente!'
          })
      },

      mountArrayPages(totalPage) {
        const pages = []
        for (let i = 1; i <= totalPage; i++) {
          pages.push(i)
        }
        return pages
      },

      showPage(page) {
        this.currentPage = page
        this.loadUsers()
      },

      onSearch() {
        this.msgInfoListUsers = 'Carregando...'
        this.currentPage = 1
        this.users = []

        clearTimeout(this.debounceTimer)
        this.debounceTimer = setTimeout(() => {
          this.debounceTimer = null
          this.loadUsers()
        }, 1000)
      },

      translatedSubscriptionStatus(status) {
        switch (status) {
          case 'active':
            return 'ativo'
          case 'pending_checkout':
            return 'pendente'
          case 'canceled':
            return 'cancelado'
          case 'expired':
            return 'expirado'
          case 'pending_on_protheus':
            return 'pendente no Protheus'  
          default:
            return status
        }
      },

      mountBadgeObject(subscriptionStatus) {
        let badgeObject = {}
        badgeObject['badge'] = true

        switch (subscriptionStatus) {
          case 'active':
            badgeObject[`${'badge-success'}`] = true
            return badgeObject
          case 'pending_checkout':
            badgeObject[`${'badge-warning'}`] = true
            return badgeObject
          case 'canceled':
            badgeObject[`${'badge-danger'}`] = true
            return badgeObject
          case 'expired':
            badgeObject[`${'badge-secondary'}`] = true
            return badgeObject
          case 'pending_on_protheus':
            badgeObject[`${'badge-warning'}`] = true
            return badgeObject  
          default:
            badgeObject[`${'badge-light'}`] = true
            return badgeObject
        }
      },

      formatUser(user) {
        const subscriptionStatus = user.subscription
          ? user.subscription.status
          : ''
        const subscriptionStatusTranslated = this.translatedSubscriptionStatus(
          subscriptionStatus
        )

        return {
          id: user.id,
          name: user.name,
          cpf: user.cpf,
          birthDate: formatDate(user.birth_date),
          email: user.email,
          phone: user.phone,
          dependants: user.dependants_count,
          badgeObject: this.mountBadgeObject(subscriptionStatus),
          href: this.userLgpdPath(user),
          subscriptionStatus,
          subscriptionStatusTranslated
        }
      },

      limit,
    }
  })

  app.loadUsers()
}

window.pages = window.pages || {}
window.pages.admin_dashboard = window.pages.admin_dashboard || {}
window.pages.admin_dashboard.lgpdReport = {
  initSearch,
  limit,
  initLgpdReportSearch,
}
